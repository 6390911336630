// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import { Payee } from "../../../components/molecules";
import moment from "moment";

const createColumns = ({ onEdit, onDelete, onSort }) => {
  return [
    {
      title: "User Detail",
      // dataIndex: "firstName",
      // sorter: true,
      render: (text, item) => {
        const username = item.user_details?.username;
        // const lastName = item.user_details?.lastName || "";
        const email = item.user_details?.email;
        const profilePictureUrl = item.user_details?.profile_pic;
        return (
          <Payee email={email} name={username} profilePicture={profilePictureUrl} />
        );
      },
    },
    {
      title: "Type",
      render: (text, item) => {
        let Type = item?.booking_type
        if (item?.booking_type === "event") Type = "Activity"
        if (item?.booking_type === "timeSlot") Type = "Calendar Activitiy"
        if (item?.booking_type === "package") Type = "Package"
        return Type
      },
    },
    {
      title: "Name",
      render: (text, item) => {
        let name = ""
        if (item.slot_details) name = item?.slot_details?.title
        if (item.package_details) name = item?.package_details?.package_name
        if (item.event_details) name = item?.event_details?.name
        return name
      },
    },
    {
      title: "Price",
      render: (text, item) => {
        return item?.cost_price
      },
    },
    {
      title: "Transaction id",
      render: (text, item) => {
        return item?.transaction_id
      },
    },
    {
      title: "From",
      render: (text, item) => {
        let userObject = {}
        if (item.slot_details) userObject = item?.slot_details.user_id
        if (item.package_details) userObject = item?.package_details.user_id
        if (item.event_details) userObject = item?.event_details.user_id
        return <Payee email={userObject?.email} name={userObject?.username} profilePicture={userObject?.profile_pic} />

      },
    },
    {
      title: "Create at",
      render: (text, item) => {
        return moment(item.createdAt).format("DD MMM YYYY")
      },
    },
    {
      title: "Complete",
      render: (text, item) => {
        const status = item.payment_status === "succeeded"
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} />;
      },
    },
  ];
};

export { createColumns };