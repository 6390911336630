import React, { useState } from "react";

// Components
import Checkbox from "../../../../components/atoms/checkBox";
import Form from "../../../../components/atoms/form";
import Input from "../../../../components/atoms/input";
import Radio from "../../../../components/atoms/radio";
import Button from "../../../../components/atoms/button";
import { toast } from "../../../../components/atoms/toaster";
import Image from "../../../../components/atoms/image";
import DefaultImage from "../../../../assets/images/user.png";
import { Input as TextArea, Select } from 'antd';

// Styles
import styles from "./sendNotificationForm.module.scss";
import notificationService from "../../../../services/notificationService";

function SendNotificationForm({ allUsers = [], allSports = [] }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [All, setAll] = useState(false);
  const [Type, setType] = useState("user");

  const defaultValues = {
    users: [],
    sports: [],
    title: "",
    message: "",
    imageUrl: null
  }

  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 16,
    },
  };


  const onFinish = (values) => {
    let UsersId = []
    let SportsId = []

    if (values?.allusers) {
      allUsers.forEach((data) => {
        UsersId.push(data._id)
      })
    }
    else if (values?.users?.length > 0) {
      values.users.forEach((id) => {
        let newid = id.substring(0, id.indexOf("@"))
        UsersId.push(newid)
      })
    } else if (values?.sports?.length > 0) {
      values.sports.forEach((id) => {
        let newid = id.substring(0, id.indexOf("@"))
        SportsId.push(newid)
      })
    }

    values.users = UsersId
    values.sports = SportsId

    setLoading(true);
    notificationService
      .sendNotification(values)
      .then((response) => {
        if (response.success) {
          toast.success("Update Successful!!");
          form.setFieldsValue(defaultValues);
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

  };

  return (
    <div>
      <Form
        {...layout}
        initialValues={defaultValues}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >

        <Form.Item>
          <Radio
            defaultValue="user"
            onChange={(e) => setType(e.target.value)}
            items={[
              {
                name: "Users",
                value: "user",
              },
              {
                name: "Sports",
                value: "sport",
              },
            ]}
          />
        </Form.Item>

        {Type === "user" ? <>
          {All ? null :
            <Form.Item
              label="Select users"
              name="users"
              rules={[{ required: true, message: "Please select users!" }]}
            >
              <Select
                size={"large"}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select user"
                // defaultValue={['china']}
                optionLabelProp="label"
                className={styles.select}
              >
                {allUsers.length > 0 ? allUsers.map(({ _id, firstName, lastName, profile_pic, username }) => {
                  return (
                    <Select.Option
                      key={_id}
                      value={`${_id}@ ${firstName} ${lastName} ${username}`}
                      label={`${username}`}
                    >
                      <div className="demo-option-label-item">
                        <Image
                          width={15}
                          height={15}
                          src={profile_pic}
                          fallback={DefaultImage}
                        />
                        {" "}
                        {` ${firstName} ${lastName} - ${username}`}
                      </div>
                    </Select.Option>
                  )
                }) : null}
              </Select>
            </Form.Item>
          }

          <Form.Item
            // label="All Users"
            name="allusers"
            valuePropName="checked"
          >
            <Checkbox onChange={(v) => setAll(v.target.checked)}>Select all {allUsers.length} users</Checkbox>
          </Form.Item>
        </>
          : <>
            <Form.Item
              label="Select Sports"
              name="sports"
              rules={[{ required: true, message: "Please select sports!" }]}
            >
              <Select
                size={"large"}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select sport"
                optionLabelProp="label"
                className={styles.select}
              >
                {allSports.length > 0 ? allSports.map(({ _id, name, usedCount }) => {
                  return (
                    <Select.Option
                      key={_id}
                      value={`${_id}@ ${name}`}
                      label={`${name}`}
                    >
                      <div className="demo-option-label-item">
                        {/* <Image
                          width={15}
                          height={15}
                          src={profile_pic}
                          fallback={DefaultImage}
                        />
                        {" "} */}
                        {name} -  ({usedCount})
                      </div>
                    </Select.Option>
                  )
                }) : null}
              </Select>
            </Form.Item>
          </>
        }

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input title!" }]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          label="Image Url"
          name="imageUrl"
        >
          <Input placeholder="Enter url" />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Please input message!" }]}
        >
          <TextArea.TextArea placeholder="Enter message" showCount maxLength={100} />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
          <Button
            label={"Send"}
            type="primary"
            htmlType="submit"
            loading={loading}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

SendNotificationForm.propTypes = {};

SendNotificationForm.defaultProps = {};

export default SendNotificationForm;
