// Lodash
import _get from "lodash/get";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";

const getDataFromResponse = (response) =>
  _get(response, "data.data", EMPTY_OBJECT);

export { getDataFromResponse };
