import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const promotionSlice = createSlice({
  name: "promotion",
  initialState: INITIAL_STATE,
  reducers: {
    addPromotions: (state, action = {}) => {
      const { payload: promotion = [] } = action;
      state.items = promotion;
    },
  },
});

const { addPromotions } = promotionSlice.actions;
const promotionReducer = promotionSlice.reducer;

export { addPromotions, promotionReducer };
export default promotionSlice;
