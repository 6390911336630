// Lodash
import _noop from "lodash/noop";

const DEFAULT_PAGINATION_PROPS = {
  showSizeChanger: true,
  showQuickJumper: true,
  onChange: _noop
};

export { DEFAULT_PAGINATION_PROPS };
