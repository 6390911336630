import { configureStore } from "@reduxjs/toolkit";

// Reducers
import { rolesReducer } from "../pages/roles/redux/rolesSlice";
import { sportsReducer } from "../pages/sports/redux/sportsSlice";
import { companiesReducer } from "../pages/companies/redux/companiesSlice";
import { promotionReducer } from "../pages/promotion/redux/promotionSlice";
import { venuesReducer } from "../pages/venues/redux/venuesSlice";
import { usersReducer } from "../pages/users/redux/usersSlice";
import { freelancersReducer } from "../pages/freelancers/redux/freelancersSlice";
import { loginReducer } from "../pages/login/redux/loginSlice";
import { subAdminsReducer } from "../pages/subAdmins/redux/subAdminsSlice";
import { businessAccountsReducer } from "../pages/businessAccounts/redux/businessAccountsSlice";
import { paymentsReducer } from "./slices/paymentsSlice";
import { transactionsReducer } from "./slices/transactionsSlice";
import { salesReducer } from "./slices/salesSlice";
import { activityReducer } from "../pages/activities/redux/activitySlice";

const store = configureStore({
  reducer: {
    roles: rolesReducer,
    sports: sportsReducer,
    promotion: promotionReducer,
    companies: companiesReducer,
    users: usersReducer,
    freelancers: freelancersReducer,
    venues: venuesReducer,
    login: loginReducer,
    subAdmins: subAdminsReducer,
    businessAccounts: businessAccountsReducer,
    payments: paymentsReducer,
    transactions: transactionsReducer,
    sales: salesReducer,
    activity: activityReducer,
  },
});

export default store;
