import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Label, Title } from "../../../../components/atoms";
import {
  MoneyCollectOutlined,
  GroupOutlined,
  UserOutlined,
} from "../../../../components/atoms/icon";

// Services
import { salesService } from "../../../../services";

// Redux
import { setSalesSummary } from "../../../../redux/slices/salesSlice";

// Readers
import { salesSummaryReader } from "../../../../readers";

// Styles
import styles from "./salesSummary.module.scss";

function SalesSummary() {
  const dispatch = useDispatch();

  const salesSummary = useSelector((state) => state?.sales?.summary);

  useEffect(() => {
    salesService
      .fetchSalesSummary()
      .then((salesSummaryResponse) => {
        dispatch(setSalesSummary(salesSummaryResponse));
      })
      .finally(() => { });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.detail}>
        <UserOutlined className={`${styles.icon} ${styles.iconPink}`} />
        <Title>{salesSummaryReader.businessUsers(salesSummary) || "0"}</Title>
        <Label className={styles.label}>Business Partners</Label>
      </div>
      <div className={styles.detail}>
        <GroupOutlined className={`${styles.icon} ${styles.iconRed}`} />
        <Title>{salesSummaryReader.packages(salesSummary) || "0"}</Title>
        <Label className={styles.label}>Packages</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconDarkGreen}`} />
        <Title>{salesSummaryReader.packageBooking(salesSummary) || "0"}</Title>
        <Label className={styles.label}>Packages Bookings</Label>
      </div>
      <div className={styles.detail}>
        <GroupOutlined className={`${styles.icon} ${styles.iconRed}`} />
        <Title>{salesSummaryReader.activities(salesSummary) || "0"}</Title>
        <Label className={styles.label}>Activities</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconDarkGreen}`} />
        <Title>{salesSummaryReader.eventBooking(salesSummary) || "0"}</Title>
        <Label className={styles.label}>Activities Bookings</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconBlue}`} />
        <Title>
          {salesSummaryReader.grossSale(salesSummary)?.toFixed(2) || "0"}
        </Title>
        <Label className={styles.label}>Gross Sales</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconYellow}`} />
        <Title>
          {salesSummaryReader.dudiCommission(salesSummary)?.toFixed(2) || "0"}
        </Title>
        <Label className={styles.label}>Dudi Commission</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconGreen}`} />
        <Title>
          {salesSummaryReader.netProfit(salesSummary)?.toFixed(2) || "0"}
        </Title>
        <Label className={styles.label}>Net Profit</Label>
      </div>
    </div>
  );
}

export default SalesSummary;
