// Lodash
import _property from "lodash/property";

const id = _property("_id");
const activityName = _property("name");
const address = _property("address.complete_address");
const latitude = _property("address.latitude");
const longitude = _property("address.longitude");
const eventDate = _property("address.custom_event_date");
const startTime = _property("address.custom_start_time");
const endTime = _property("address.custom_end_time");
const userId = _property("user_id");
const sports_id = _property("sports_id");
const description = _property("description");
const no_of_players = _property("no_of_players");
const no_of_team = _property("no_of_team");
const fees = _property("fees");
const gender = _property("gender");
const status = _property("status");
const is_active = _property("is_active");
const is_complete = _property("is_complete");
const event_time_slot = _property("event_time_slot");
const createdAt = _property("createdAt");
const updatedAt = _property("updatedAt");

export default {
  id,
  address,
  sports_id,
  no_of_players,
  eventDate,
  startTime,
  endTime,
  description,
  userId,
  activityName,
  no_of_team,
  fees,
  gender,
  createdAt,
  updatedAt,
  status,
  is_complete,
  event_time_slot,
  latitude,
  longitude,
  is_active,
};
