import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";

const fetchSales = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sales`,
    query: payload,
  });

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch sales summary");
    });
};

const fetchUnsettledSales = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    settled: false,
    search_text: searchText,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sales`,
    query: payload,
  });

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch sales summary");
    });
};

const fetchSalesSummary = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sales/overview`,
  });

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch sales summary");
    });
};

export default {
  fetchSales,
  fetchUnsettledSales,
  fetchSalesSummary,
};
