import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import Routes from "./routes";
import { ToastContainer } from "./components/atoms/toaster";

// Store
import { addUser } from "./pages/login/redux/loginSlice";

// Services
import adminService from "./services/subAdminsService";

// Styles
import "./App.css";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    adminService
      .fetchMyDetails()
      .then((userDetails) => {
        dispatch(addUser(userDetails));
      })
      .catch((error) => {
        console.log("Error fetching my details", error);
      });
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
