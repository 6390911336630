// Lodash
import _reduce from "lodash/reduce";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

// Readers
import permissionReader from "../../../readers/permission";

const formatPermission = (formattedPersmissions, permission) => {
  const type = permissionReader.type(permission);
  const rights = permissionReader.rights(permission);

  return {
    ...formattedPersmissions,
    [type]: rights,
  };
};

const formatPermissionsResponse = (permissions) =>
  _reduce(permissions, formatPermission, EMPTY_ARRAY);

export { formatPermissionsResponse };
