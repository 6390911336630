// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";

// Readers
import companyReader from "../../../readers/company";

// Styles
import styles from "../companies.module.scss";

const createColumns = ({ onEdit, onDelete, onSort, deleteLoading }) => {
  return [
    {
      title: "Name",
      sorter: true,
      render: (text, companyDetails) => {
        return companyReader.name(companyDetails);
      },
    },
    {
      title: "Added By",
      render: (text, companyDetails) => {
        return (
          companyReader.firstName(companyDetails) +
          " " +
          companyReader.lastName(companyDetails)
        );
      },
    },
    {
      title: "Use",
      dataIndex: 'usedCount',
      sorter: true,
      render: (text, companyDetails) => {
        const count = companyReader.used(companyDetails) || 0
        return (
          <Button
            disabled={count.length > 0 ? false : true}
            type={BUTTON_TYPES.DEFAULT}
          >
            {count.length}
          </Button >
        );
      },
    },
    {
      title: "Status",
      render: (text, companyDetails) => {
        const status = companyReader.isActive(companyDetails)
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} />;
      },
    },
    {
      title: "Actions",
      render: (text, companyDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(companyDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            <Button
              loading={deleteLoading ? true : false}
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onDelete(companyDetails);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              {deleteLoading ? null : <DeleteOutlined />}
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
