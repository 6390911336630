import React, { useState } from "react";
import PropTypes from "prop-types";
import randomWords from "random-words";

// Lodash
import _noop from "lodash/noop";

// Components
import { Modal, Input, Label } from "../../atoms";

// Styles
import styles from "./confirmationDialog.module.scss";

function ConfirmationDialog({
  visible,
  onOk,
  okButtonText,
  onCancel,
  confirmationText,
  confirmationTitle,
}) {
  const randomGeneratedWords = randomWords({ exactly: 1, maxLength: 6 });
  const [randomWord] = useState(randomGeneratedWords[0]);

  const [userInputText, setUserInputText] = useState();

  const handleUserInputTextChange = (event) => {
    setUserInputText(event.target.value);
  };

  return (
    <Modal
      title={confirmationTitle}
      okText={okButtonText}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      centered
      okButtonProps={{
        disabled: userInputText !== randomWord,
      }}
    >
      <div className={styles.confirmationText}>{confirmationText}</div>
      <Label className={styles.confirmationLabel}>
        Please match the below text to confirm your action
      </Label>
      <div className={styles.confirmationDetails}>
        <div className={styles.randomWord}>{randomWord}</div>
        <Input
          value={userInputText}
          onChange={handleUserInputTextChange}
          className={styles.userInputText}
        />
      </div>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  okButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  confirmationText: PropTypes.string,
  confirmationTitle: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  visible: false,
  onOk: _noop,
  okButtonText: "Confirm",
  onCancel: _noop,
  confirmationText: "Are you sure ?",
  confirmationTitle: "Confirm your action",
};

export default ConfirmationDialog;
