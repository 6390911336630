// Lodash
import _property from "lodash/property";

const id = _property("id");
const name = _property("name");
const isActive = _property("is_active");
const phone = _property("phone");
const email = _property("email");
const address = _property("address");
const no_of_employes = _property("no_of_employes");
const createdAt = _property("createdAt");
const used = _property("used");
const updatedAt = _property("updatedAt");
const firstName = _property("created_by.first_name");
const lastName = _property("created_by.last_name");

export default {
  id,
  name,
  isActive,
  phone,
  email,
  address,
  no_of_employes,
  used,
  createdAt,
  updatedAt,
  firstName,
  lastName,
};
