import moment from "moment";

import { Payee } from "../../../components/molecules";

// Readers
import { transactionReader, packageReader } from "../../../readers";


const createColumns = () => {
  return [
    {
      title: "Transaction ID",
      render: (_, transaction) => transactionReader.transactionId(transaction),
    },
    {
      title: "Booking Type",
      render: (_, transaction) => {
        let name = ""
        if (transaction?.package_details?._id) name = "Package"
        if (transaction?.event_details?._id) name = "Activity"
        if (transaction?.slot_details?._id) name = "Calendar Activity"
        return name
      },
    },
    {
      title: "Name",
      render: (_, transaction) => {
        let name = ""
        if (transaction?.package_details?._id) name = transaction?.package_details?.package_name
        if (transaction?.event_details?._id) name = transaction?.event_details?.name
        if (transaction?.slot_details?._id) name = transaction?.slot_details?.title
        return name
      },
    },
    {
      title: "Purchased By",
      render: (_, transaction) => {
        const userDetails =
          transactionReader.userDetails(transaction) || {};
        const { email, profile_pic, first_name, last_name, username } = userDetails;
        return <Payee email={email} name={username} profilePicture={profile_pic} />;
      },
    },
    {
      title: "Purchase Date",
      render: (_, transaction) => {
        const transactionDate = transactionReader.createdAt(transaction);
        const dateMoment = new moment(transactionDate);
        return dateMoment.format("LLL");
      },
    },
  ];
};

export { createColumns };
