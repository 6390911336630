// Lodash
import _property from "lodash/property";

const id = _property("_id");
const name = _property("name");
const businessUser = _property("business_id");
const address = _property("address");
const latitude = _property("latitude");
const longitude = _property("longitude");
const averageCost = _property("average_cost");
const openingTime = _property("opening_time");
const closingTime = _property("closing_time");
const playersOccupy = _property("players_occupy");
const sportsPlayed = _property("sports_played");
const isActive = _property("is_active");
const maxPitches = _property("max_pitches");
const is_freelancer_venue = _property("is_freelancer_venue");

export default {
  id,
  name,
  businessUser,
  address,
  latitude,
  longitude,
  averageCost,
  openingTime,
  closingTime,
  isActive,
  playersOccupy,
  sportsPlayed,
  maxPitches,
  is_freelancer_venue
};
