import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

// Lodash
import _noop from "lodash/noop";

// Redux
import { addRole } from "../../redux/rolesSlice";

// Components
import Form from "../../../../components/atoms/form";
import Input from "../../../../components/atoms/input";
import Drawer from "../../../../components/atoms/drawer";
import Button from "../../../../components/atoms/button";
import Permissions from "../permissions";
import { toast } from "../../../../components/atoms/toaster";

// Constants
import { EMPTY_ARRAY } from "../../../../constants";

// Services
import rolesService from "../../../../services/rolesService";

// Helpers
import { createRolesAndPermissionsPayload } from "./helpers/createRolePayload";

// Styles
import styles from "./createRoleForm.module.scss";

function CreateRoleForm({ visible, onClose, isEditMode, permissionTypes }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCreateRole = (values) => {
    setLoading(true);
    const rolesPayload = createRolesAndPermissionsPayload(
      values,
      permissionTypes
    );
    rolesService
      .createRole(rolesPayload)
      .then((newRole) => {
        form.resetFields();
        setLoading(false);
        dispatch(addRole(newRole));
        toast.success("New Role created successfully");
      })
      .catch((error) => {
        setLoading(false);
        console.log("Failed to create role", error);
        toast.error("Failed to create Role");
      });
  };

  const title = isEditMode ? "Edit Role" : "Create Role";
  return (
    <Drawer title={title} open={visible} onClose={onClose} width={400}>
      <div className={styles.container}>
        <Form onFinish={handleCreateRole} layout="vertical" form={form}>
          <Form.Item
            label="Role Name"
            name="role"
            rules={[
              {
                required: true,
                message: "Please input Role name!",
              },
            ]}
          >
            <Input placeholder="Role Name" />
          </Form.Item>

          <Form.Item label="Permissions:" name="permissions">
            <Permissions />
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.createButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateRoleForm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  isEditMode: PropTypes.bool,
  permissionTypes: PropTypes.array,
};

CreateRoleForm.defaultProps = {
  visible: false,
  onClose: _noop,
  isEditMode: false,
  permissionTypes: EMPTY_ARRAY,
};

export default CreateRoleForm;
