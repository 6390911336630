import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
// import { getDataFromResponse } from "../utils";


const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchPackages = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageLimit = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageLimit,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "descend" ? "desc" : order === "ascend" ? "asc" : "",
    status: "all"
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/packages/all_list`,
    query: payload,
  });
  return axios
    .get(url, headers)
    .then((response) => response)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};


export default {
  fetchPackages,
};
