import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import Drawer from "../../../../components/atoms/drawer";
import Divider from "../../../../components/atoms/divider";
import { Title, Label } from "../../../../components/atoms/typography";
import Status, { STATUS_TYPES } from "../../../../components/molecules/status";
import GoogleMaps from "../../../../components/molecules/googleMaps/GoogleMaps";
// Constants
import { EMPTY_OBJECT } from "../../../../constants";

// Readers
import venueReader from "../../../../readers/venue";

// Styles
import styles from "./venueDetails.module.scss";

function VenueDetails({ visible, onClose, venueDetails }) {
  const name = venueReader.name(venueDetails);
  const businessUser = venueReader.businessUser(venueDetails);
  const address = venueReader.address(venueDetails);
  const averageCost = venueReader.averageCost(venueDetails);
  const lat = +venueReader.latitude(venueDetails);
  const lng = +venueReader.longitude(venueDetails);
  const openingTime = venueReader.openingTime(venueDetails);
  const closingTime = venueReader.closingTime(venueDetails);
  const playersOccupy = venueReader.playersOccupy(venueDetails);
  const sportsPlayed = venueReader.sportsPlayed(venueDetails);
  const status = venueReader.isActive(venueDetails)
    ? STATUS_TYPES.ACTIVE
    : STATUS_TYPES.INACTIVE;
  let user = null;
  let userDetail = null;
  // adding created by info
  if (businessUser) {
    user = businessUser.user_id;
  }
  if (user) {
    userDetail = (
      <div className={styles.detail}>
        <Title className={styles.detailTitle}>Business User</Title>
        <Label>{user.firstName + " " + user.lastName}</Label>
      </div>
    )
  }
  return (
    <Drawer
      title="Venue Details"
      open={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>
        <div className={styles.profileImage}>
          <GoogleMaps latitude={lat} longitude={lng} type="view" />
        </div>
        <div className={styles.profileName}>
          <Title>{name}</Title> <Status status={status} />
        </div>
        <Divider>Details</Divider>
        <div className={styles.profileDetails}>
          {userDetail}
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Address</Title>
            <Label>{address}</Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Average Cost</Title>
            <Label>{averageCost}</Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Opening Time</Title>
            <Label>
              {openingTime && moment(openingTime).format("hh:mm a")}
            </Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Closing Time</Title>
            <Label>
              {closingTime && moment(closingTime).format("hh:mm a")}
            </Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Occupancy</Title>
            <Label>{playersOccupy}</Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Sports Played</Title>
            <ul>
              {sportsPlayed?.map((sport) => (
                <li>
                  <Label>{sport.name}</Label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

VenueDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  venueDetails: PropTypes.object,
};

VenueDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  venueDetails: EMPTY_OBJECT,
};

export default VenueDetails;
