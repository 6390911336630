import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import SendNotificationForm from "./components/sendNotificationForm";

import { Row, Col } from "antd";

// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from "../../constants";

// // Styles
// import styles from "./notification.module.scss";

import { addSports } from "../sports/redux/sportsSlice";

// Service
import userService from "../../services/userService";
import sportService from "../../services/sportsService";


function Notification() {
  const dispatch = useDispatch();

  const [allUsers, setallUsers] = useState(EMPTY_OBJECT);
  const sports = useSelector((state) => state?.sports?.items);
  // const allSports = useSelector((state) => state?.sports?.all);

  useEffect(() => {
    userService
      .fetchAllUsers()
      .then((response) => {
        setallUsers(response);
      })
      .catch((error) => {
        console.log("Error fetching roles", error);
        setallUsers(EMPTY_ARRAY);
      });

    if (sports.length < 100) {
      sportService
        .fetchSports({
          pageNumber: 1,
          pageSize: 250,
          searchText: "",
          sortField: "usedCount",
          order: "ascend",
        })
        .then((response) => {
          dispatch(addSports(response.docs));
        })
    }
  }, []);

  return (
    <>
      <div>
        <PageHeader title="Notification" />
        <Row>
          <Col span={16}>
            <div className="card">
              <SendNotificationForm allUsers={allUsers} allSports={sports} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Notification;
