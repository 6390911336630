import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  loading: true,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: INITIAL_STATE,
  reducers: {
    setTransactions: (state, action = EMPTY_OBJECT) => {
      const { payload: transactions } = action;
      state.items = transactions;
    },
    setLoading: (state, action = EMPTY_OBJECT) => {
      const { payload: loading } = action;
      state.loading = loading;
    },
  },
});

const { setTransactions, setLoading } = transactionsSlice.actions;
const transactionsReducer = transactionsSlice.reducer;

export { setTransactions, setLoading, transactionsReducer };
export default transactionsSlice;
