import axios from "axios";

// Constants
import { BASE_URL } from "../constants/services";

// Utils
import { getDataFromResponse } from "../utils";

const login = ({ userEmail, userPassword }) => {
  const payload = {
    email: userEmail,
    password: userPassword,
  };
  return axios
    .post(`${BASE_URL}/admin/auth`, payload)
    .then((response) => getDataFromResponse(response));
};

export default {
  login,
};
