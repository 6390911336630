// Lodash
import _noop from "lodash/noop";

// Constants
import { PAGE_TITLE } from "../constants";

const createPageHeaderProps = () => ({
  title: PAGE_TITLE,
  subTitle: "Only unsettled sale transactions are visible here"
});

export { createPageHeaderProps };
