// Lodash
import _property from "lodash/property";

const bookingType = _property("booking_type");
const businessAccountDetails = _property("business_details");
const costPrice = _property("cost_price");
const createdAt = _property("createdAt");
const dudiCommission = _property("dudi_commission");
const id = _property("id");
const netAmount = _property("net_profit");
const packageDetails = _property("package_details");
const eventDetails = _property("event_details");
const paymentStatus = _property("payment_status");
const sellingPrice = _property("selling_price");
const settled = _property("settled");
const settlementDetails = _property("settlement_details");
const transactionId = _property("transaction_id");
const type = _property("type")
const updatedAt = _property("updatedAt");
const userDetails = _property("user_details");

export default {
  bookingType,
  businessAccountDetails,
  costPrice,
  createdAt,
  dudiCommission,
  id,
  netAmount,
  packageDetails,
  eventDetails,
  paymentStatus,
  sellingPrice,
  settled,
  settlementDetails,
  transactionId,
  type,
  updatedAt,
  userDetails,
};
