import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from 'antd';

// Lodash
import _noop from "lodash/noop";

// Components
import Drawer from "../../../../components/atoms/drawer";
import Form from "../../../../components/atoms/form";
import Input from "../../../../components/atoms/input";
import Button from "../../../../components/atoms/button";
import { toast } from "../../../../components/atoms/toaster";

// Reader
import sportReader from "../../../../readers/sport";

// Styles
import styles from "./createSportForm.module.scss";
import sportsService from "../../../../services/sportsService";

const Option = { Select };

function CreateSportForm({
  visible,
  onClose,
  sportDetails,
  isEditMode,
  onReload,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const sportID = sportReader.id(sportDetails);
  const sportName = sportReader.name(sportDetails);
  const status = sportReader.isActive(sportDetails);
  let title = "Create New Sport";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    name: sportName || "",
    is_active: status || !!status,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    setLoading(true);
    if (isEditMode) {
      onClose();
      sportsService
        .updateSport(sportID, values)
        .then((response) => {
          if (response.success) {
            onReload();
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      sportsService
        .createSport(values)
        .then((response) => {
          if (response.success) {
            toast.success("New Sport Added Successfully!!");
            form.resetFields();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (isEditMode) {
    title = "Edit Sport - " + defaultValues.name;
  }

  return (
    <Drawer title={title} open={visible} onClose={onClose}>
      <div className={styles.container}>
        <Form
          initialValues={defaultValues}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input sport name!" }]}
          >
            <Input placeholder="Enter sport name" />
          </Form.Item>
          <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="select status" className={styles.select}>
              <Option value={true} key="active">
                Active
              </Option>
              <Option value={false} key="inActive">
                InActive
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateSportForm.propTypes = {
  visible: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateSportForm.defaultProps = {
  visible: false,
  isEditMode: false,
  onClose: _noop,
};

export default CreateSportForm;
