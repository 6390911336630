// Lodash
import _property from "lodash/property";

const id = _property("id");
const transactionsCount = _property("transactions_count");
const grossSale = _property("gross_sale");
const dudiCommission = _property("dudi_commission");
const netPayment = _property("net_payment");
const transactions = _property("transactions");
const paymentDate = _property("createdAt");
const paymentNote = _property("notes");
const paidBy = _property("paid_by");
const revertedBy = _property("reverted_by");
const revertedNote = _property("revert_note");
const revertedDate = _property("reverted_date");
const status = _property("status");
const businessUser = _property("business_id.user_id");

export default {
  id,
  transactionsCount,
  grossSale,
  dudiCommission,
  netPayment,
  transactions,
  paymentDate,
  paymentNote,
  paidBy,
  revertedBy,
  status,
  revertedNote,
  revertedDate,
  businessUser
};
