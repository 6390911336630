import React, { useState, useEffect } from "react";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Tabs from "../../components/atoms/tabs";
import Image from "../../components/atoms/image";
import Divider from "../../components/atoms/divider";
import { Title, Label } from "../../components/atoms/typography";
import EditProfileForm from "./components/editProfileForm";
import ForgotPasswordForm from "./components/forgotPasswordForm";

import { Row, Col } from "antd";

// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./profile.module.scss";

// Images
import DefaultImage from "../../assets/images/user.png";
import userService from "../../services/userService";
import adminReader from "../../readers/admin";

const { TabPane } = Tabs;

function Profile() {
  const [selectedProfileDetails, setSelectedProfileDetails] =
    useState(EMPTY_OBJECT);

  useEffect(() => {
    userService
      .fetchMyProfile()
      .then((response) => {
        setSelectedProfileDetails(response);
      })
      .catch((error) => {
        console.log("Error fetching roles", error);
        setSelectedProfileDetails(EMPTY_ARRAY);
      });
  }, []);

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader title="Profile" />
        <Row>
          <Col span={16}>
            <div className={styles.pageContent}>
              <div className={styles.rolesSection}>
                <Tabs tabPosition={"left"}>
                  <TabPane tab="Edit Profile" key="1">
                    <EditProfileForm profileDetails={selectedProfileDetails} />
                  </TabPane>
                  <TabPane tab="Change Password" key="2">
                    <ForgotPasswordForm />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col span={1} className={styles.divider}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={7}>
            <div className={styles.profileImageContainer}>
              <div className={styles.profileImage}>
                <Image
                  width={160}
                  height={160}
                  src={""}
                  fallback={adminReader.profileImageUrl(selectedProfileDetails) || DefaultImage}
                />
              </div>
              <div className={styles.profileName}>
                <Title>
                  {adminReader.firstName(selectedProfileDetails) +
                    " " +
                    adminReader.lastName(selectedProfileDetails)}
                </Title>
                <Label>{adminReader.email(selectedProfileDetails)}</Label>
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.pageContentWrapper}></div>
      </div>
    </>
  );
}

export default Profile;
