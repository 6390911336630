const SMALL = "small";
const MEDIUM = "middle";
const LARGE = "large";

const SIZES = {
  SMALL,
  MEDIUM,
  LARGE,
};

export default SIZES;
