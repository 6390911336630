import moment from "moment";

// Components
import { Payee } from "../../../../../components/molecules";

// Readers
import { transactionReader, packageReader } from "../../../../../readers";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";

const createColumns = () => {
  return [
    {
      title: "Transaction ID",
      render: (_, transaction) => transactionReader.transactionId(transaction),
    },
    {
      title: "Type",
      render: (_, transaction) => {
        return transactionReader.bookingType(transaction);
      },
    },
    {
      title: "Name",
      render: (_, transaction) => {
        const packageDetails = transactionReader.packageDetails(transaction);
        const eventDetails = transactionReader.eventDetails(transaction);
        if (packageDetails) {
          return packageReader.packageName(packageDetails);
        } else {
          return eventDetails?.name;
        }
      },
    },
    {
      title: "Purchased By",
      render: (_, transaction) => {
        const userDetails = transactionReader.userDetails(transaction) || EMPTY_OBJECT;
        const { email, profile_pic, first_name, last_name, username } =
          userDetails;
        const name = `${username}`;
        return <Payee email={email} name={name} profilePicture={profile_pic} />;
      },
    },
    {
      title: "Purchase Date",
      render: (_, transaction) => {
        const transactionDate = transactionReader.createdAt(transaction);
        const dateMoment = new moment(transactionDate);
        return dateMoment.format("LLL");
      },
    },
    {
      title: "Sale Amount",
      render: (_, transaction) => transactionReader.sellingPrice(transaction),
    },
    {
      title: "Dudi Commission",
      render: (_, transaction) => transactionReader.dudiCommission(transaction),
    },
    {
      title: "Net Amount",
      render: (_, transaction) => transactionReader.netAmount(transaction),
    },
  ];
};

export { createColumns };
