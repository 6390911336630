import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Components
import CreateVenueForm from "../createVenueForm/CreateVenueForm";
import { toast } from "../../../../components/atoms/toaster";
import { getID } from "../../../../utils/globle";

// Services
import venuesService from "../../../../services/venuesService";

// Reader
import venueReader from "../../../../readers/venue";

// Styles
import { EMPTY_OBJECT } from "../../../../constants";

function VenueFormWrapper({
  editMode,
  visible,
  onClose,
  venueDetails,
  sportsList,
  usersList,
  freelancers,
  onReload,
}) {
  const [loading, setLoading] = useState(false);
  const venueID = venueReader.id(venueDetails);
  const name = venueReader.name(venueDetails);
  const businessUser = venueReader.businessUser(venueDetails);
  let is_freelancer_venue = venueReader.is_freelancer_venue(venueDetails) || false
  const address = venueReader.address(venueDetails);
  const latitude = venueReader.latitude(venueDetails);
  const longitude = venueReader.longitude(venueDetails);
  const averageCost = venueReader.averageCost(venueDetails);
  const openingTime = venueReader.openingTime(venueDetails);
  const closingTime = venueReader.closingTime(venueDetails);
  const playersOccupy = venueReader.playersOccupy(venueDetails);
  const sportsPlayed = venueReader.sportsPlayed(venueDetails);
  const status = venueReader.isActive(venueDetails);
  const maxPitches = venueReader.maxPitches(venueDetails);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let title = "Create New Venue";
  const defaultValues = {
    name: null,
    business_id: null,
    latitude: 25.1044748,
    longitude: 55.2372069,
    address: null,
    average_cost: 0,
    opening_time: null,
    closing_time: null,
    is_active: null,
    players_occupy: null,
    sports_played: [],
    max_pitches: null,
    is_freelancer_venue: false
  };

  if (editMode) {
    title = `Edit Venue - ${name}`;
    defaultValues.business_id = businessUser._id;
    is_freelancer_venue = venueReader.is_freelancer_venue(venueDetails) || false
    defaultValues.name = name;
    defaultValues.latitude = +latitude;
    defaultValues.longitude = +longitude;
    defaultValues.address = address;
    defaultValues.average_cost = averageCost;
    defaultValues.opening_time = moment(openingTime, "hh:mm a");
    defaultValues.closing_time = moment(closingTime, "hh:mm a");
    defaultValues.is_active = status;
    defaultValues.players_occupy = playersOccupy;
    defaultValues.max_pitches = maxPitches;
    defaultValues.sports_played = sportsPlayed.map((val) => `${val._id}@${val.name}`);
    defaultValues.is_freelancer_venue = venueReader.is_freelancer_venue(venueDetails) || false
  }

  const onFinish = (values, form) => {
    setLoading(true);
    values.sports_played = getID(values.sports_played)
    const finalValues = { ...values };

    finalValues.address = values.location.address;
    finalValues.latitude = values.location.coordinates.lat || latitude;
    finalValues.longitude = values.location.coordinates.lng || longitude;
    finalValues.location.coordinates = [
      values.location.coordinates.lat || latitude,
      values.location.coordinates.lng || longitude,
    ];

    if (editMode) {
      venuesService
        .updateVenue(venueID, finalValues)
        .then((response) => {
          if (response.success) {
            toast.success("Update Successful!!");
            onClose();
            onReload();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      venuesService
        .createVenue(finalValues)
        .then((response) => {
          if (response.success) {
            toast.success("New Venue Added Successfully!!");
            form.resetFields();
            onClose();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <CreateVenueForm
      title={title}
      formValues={defaultValues}
      visible={visible}
      onClose={onClose}
      venueDetails={venueDetails}
      sportsList={sportsList}
      onFinish={onFinish}
      loading={loading}
      usersList={is_freelancer_venue ? freelancers : usersList}
      is_freelancer_venue={is_freelancer_venue}
    />
  );
}

VenueFormWrapper.propTypes = {
  editMode: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  venueDetails: PropTypes.object,
  sportsList: PropTypes.array,
};

VenueFormWrapper.defaultProps = {
  venueDetails: EMPTY_OBJECT,
  editMode: false,
};

export default VenueFormWrapper;
