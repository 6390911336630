import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";
import _map from "lodash/map";

// Components
import Drawer from "../../../../components/atoms/drawer";
import Divider from "../../../../components/atoms/divider";
import { Title, Label } from "../../../../components/atoms/typography";
import Status, { STATUS_TYPES } from "../../../../components/molecules/status";
import { Tag } from 'antd';

// Constants
import { EMPTY_OBJECT } from "../../../../constants";

// Readers
import companyReader from "../../../../readers/company";

// Styles
import styles from "./companyDetails.module.scss";

// Images
import DefaultCompanyImage from "../../../../assets/images/sport.png";

function CompanyDetails({ visible, onClose, companyDetails }) {
  const companyName = companyReader.name(companyDetails);
  const companyPhone = companyReader.phone(companyDetails);
  const companyEmail = companyReader.email(companyDetails);
  const companyAddress = companyReader.address(companyDetails);
  const companyEmploys = companyReader.no_of_employes(companyDetails);
  const companyUsed = companyReader.used(companyDetails) || 0;
  const addedBy = companyReader.firstName(companyDetails) + " " + companyReader.lastName(companyDetails);

  const status = companyReader.isActive(companyDetails)
    ? STATUS_TYPES.ACTIVE
    : STATUS_TYPES.INACTIVE;
  return (
    <Drawer
      title="Company Details"
      open={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>
        <div className={styles.profileName}>
          <Title>{companyName}</Title> <Status status={status} />
        </div>
        <Divider>Details</Divider>
        <div className={styles.profileDetails}>
          {companyEmail ? <div className={styles.detail}>
            <Title className={styles.detailTitle}>Email</Title>
            <Label>{companyEmail}</Label>
          </div> : null}
          {companyPhone ? <div className={styles.detail}>
            <Title className={styles.detailTitle}>Phone</Title>
            <Label>{companyPhone}</Label>
          </div> : null}
          {companyAddress ? <div className={styles.detail}>
            <Title className={styles.detailTitle}>Address</Title>
            <Label>{companyAddress}</Label>
          </div> : null}
          {companyEmploys ? <div className={styles.detail}>
            <Title className={styles.detailTitle}>No Of Employes</Title>
            <Label>{companyEmploys}</Label>
          </div> : null}
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Added By</Title>
            <Label>{addedBy}</Label>
          </div>
          <div className={styles.detail}>
            <Divider orientation="left"><Title className="detailTitle">Selected Employees ({companyUsed?.length})</Title></Divider>
            {_map(companyUsed, (user) =>
              <Tag className="mt-1" color="green">{user.username}</Tag>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

CompanyDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  companyDetails: PropTypes.object,
};

CompanyDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  companyDetails: EMPTY_OBJECT,
};

export default CompanyDetails;
