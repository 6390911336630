import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Lodash
import _map from "lodash/map";
import _capitalize from "lodash/capitalize";

// Constants
import { EMPTY_ARRAY } from "../../constants";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import CreateRoleForm from "./components/createRoleForm";
import Tabs from "../../components/atoms/tabs";
import Permissions from "./components/permissions";
import { toast } from "../../components/atoms/toaster";

// Redux
import { setRoles, setPermissionTypes, updatePermissions } from "./redux/rolesSlice";

// Services
import rolesService from "../../services/rolesService";

// Readers
import listResponseReader from "../../readers/listResponse";
import roleReader from "../../readers/role";

// Helpers
import { formatPermissionsResponse } from "./helpers/permissionFormatting";
import { createRolesAndPermissionsPayload } from "./components/createRoleForm/helpers/createRolePayload";

// Styles
import styles from "./roles.module.scss";

const { TabPane } = Tabs;

function Roles() {
  const dispatch = useDispatch();
  const [createRoleFormVisible, setCreateRoleFormVisibility] = useState(false);
  const roles = useSelector((state) => state?.roles?.items);
  const permissionTypes = useSelector((state) => state?.roles?.permissionTypes);

  const handleOpenCreateRoleForm = () => {
    setCreateRoleFormVisibility(true);
  };

  const handleCloseCreateRoleForm = () => {
    setCreateRoleFormVisibility(false);
  };

  useEffect(() => {
    const rolesPromise = rolesService.fetchRoles();
    const permissionTypesPromise = rolesService.fetchPermissions();

    Promise.all([rolesPromise, permissionTypesPromise])
      .then(([rolesResponse, permissionTypes]) => {
        const roles = listResponseReader.results(rolesResponse);
        dispatch(setRoles(roles));
        dispatch(setPermissionTypes(permissionTypes));
      })
      .catch((error) => {
        console.log("Error fetching roles list", error);
      });
  }, []);

  const handleUpdatePermissions = (roleDetails) => (permissions) => {
    const id = roleReader.id(roleDetails);
    const roleName = _capitalize(roleReader.role(roleDetails));
    const payload = createRolesAndPermissionsPayload(
      { role: roleName, permissions },
      permissionTypes
    );
    rolesService
      .updateRole(id, payload)
      .then((response) => {
        dispatch(updatePermissions(response))
        toast.success("Permission updated succesfully");
      })
      .catch((error) => {
        console.log("Failed to update permissions", error);
        toast.error("Permission update failed");
      });
  };

  const renderRole = (roleDetails) => {
    const id = roleReader.id(roleDetails);
    const roleName = _capitalize(roleReader.role(roleDetails));
    const permissions = roleReader.permissions(roleDetails);
    const formattedPermissions = formatPermissionsResponse(permissions);
    return (
      <TabPane tab={roleName} key={id}>
        <Permissions
          value={formattedPermissions}
          onChange={handleUpdatePermissions(roleDetails)}
        />
      </TabPane>
    );
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Roles"
          statistics={[
            {
              title: "Total Roles",
              value: roles?.length,
            },
          ]}
          actions={[
            {
              title: "+ New Role",
              handler: handleOpenCreateRoleForm,
            },
          ]}
        />
        <div className={styles.pageContent}>
          <div className={styles.rolesSection}>
            <Tabs tabPosition={"left"}>{_map(roles, renderRole)}</Tabs>
          </div>
        </div>
      </div>
      <CreateRoleForm
        visible={createRoleFormVisible}
        onClose={handleCloseCreateRoleForm}
        permissionTypes={permissionTypes}
      />
    </>
  );
}

export default Roles;
