const SUCCESS = "success";
const DEFAULT = "default";
const PROCESSING = "processing";

const TAG_TYPES = {
  SUCCESS,
  DEFAULT,
  PROCESSING,
};

export default TAG_TYPES;