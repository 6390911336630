import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Table } from "../../../../components/organisms";
import { PageHeader } from "../../../../components/molecules";

// Helpers
import { createColumns, createPageHeaderProps } from "./helpers";

// Services
import { paymentsService } from "../../../../services";

// Readers
import { paymentReader } from "../../../../readers";

// Redux Actions
import {
  setPayments,
  setLoading,
} from "../../../../redux/slices/paymentsSlice";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Styles
import styles from "./paymentsHistory.module.scss";

function PaymentsHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paginationDetails, setPaginationDetails] = useState(
    DEFAULT_PAGINATION_DETAILS
  );

  const payments = useSelector((state) => state?.payments?.items);
  const loading = useSelector((state) => state?.payments?.loading);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    dispatch(setLoading(true));
    paymentsService
      .fetchPayments({ pageNumber, pageSize })
      .then((paymentDetails = EMPTY_OBJECT) => {
        const payments = paymentDetails.docs;
        dispatch(setPayments(payments));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [paginationDetails]);

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleRowClick = (payment) => {
    const paymentId = paymentReader.id(payment);
    navigate(`/payments/${paymentId}`);
  };

  const handleNavigateToCreatePaymentForm = () => {
    navigate("/payments/unsettled");
  };

  const columns = createColumns();
  const pageHeaderProps = createPageHeaderProps({
    actionHandler: handleNavigateToCreatePaymentForm,
    actionDisabled: false,
  });

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <div className={styles.payments}>
        <Table
          columns={columns}
          data={payments}
          loading={loading}
          showSearch={false}
          showReload={false}
          onPaginationChange={handlePaginationChange}
          onRowClick={handleRowClick}
        />
      </div>
    </>
  );
}

export default PaymentsHistory;
