import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from 'antd';
import _ from "lodash";

// Components
// import { confirm } from "../../components/atoms/modal";
// import { ExclamationCircleOutlined } from "../../components/atoms/icon";
import ActivityDetails from "./activityDetails";
import PageHeader from "../../components/molecules/pageHeader";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import activityService from "../../services/activityService";

// Redux
import { addActivities } from "./redux/activitySlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./activity.module.scss";

function Activity() {
  const [totalActivities, setTotalActivities] = useState(0);
  const [selectedActivityDetails, setSelectedActivityDetails] = useState({});

  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });

  const [futureActivityOnly, setAdminPromtionOnly] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);


  const [activityDetailsVisible, setActivityDetailsVisibility] = useState(false);
  const dispatch = useDispatch();
  const activities = useSelector((state) => state?.activity?.items);


  const getActivitiesData = (pageNumber, pageSize, sortField, order, future = futureActivityOnly) => {
    activityService
      .fetchActivities({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
        future,
      })
      .then((response) => {
        const activities = listResponseReader.results(response);
        const totalactivities = listResponseReader.totalResults(response);
        setTotalActivities(totalactivities);
        dispatch(addActivities(activities));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeListToUser = () => {
    setLoading(true);
    setAdminPromtionOnly(!futureActivityOnly)
    const { pageNumber, pageSize } = paginationDetails;
    getActivitiesData(pageNumber, pageSize, sortField, order, !futureActivityOnly);
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getActivitiesData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);


  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleCloseActivityDetails = () => {
    isEditMode && setEditMode(false);
    setActivityDetailsVisibility(false);
    setSelectedActivityDetails({});
  };

  const handleShowActivityDetails = (activityDetails) => {
    setSelectedActivityDetails(activityDetails);
    setActivityDetailsVisibility(true);
  };

  const handleOnInActive = (activity_id, is_active) => {
    // if (!is_active) {
    //   confirm({
    //     title: "Are you sure you want to deactivate this activity?",
    //     icon: <ExclamationCircleOutlined />,
    //     // content: `All sessions will be closed for this user`,
    //     onOk() {
    //       activeUserAPI()
    //     },
    //     onCancel() { },
    //   });
    // }
    // else 
    activeUserAPI()

    function activeUserAPI() {
      let newActivity = [...activities]
      var newArr = _.map(newActivity, function (event) {
        return event._id === activity_id ? { ...event, is_active, } : event;
      })

      activityService
        .updateInActiveStatus({ activity_id, is_active })
        .then((response) => {
          dispatch(addActivities(newArr));
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const columns = createColumns({
    onInActive: handleOnInActive,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)

    getActivitiesData(
      pagination.current,
      DEFAULT_PAGE_SIZE,
      field,
      order
    );
  }

  return (
    <>
      <div className="container">
        <PageHeader
          title="Activities"
          statistics={[
            {
              title: "Total Activities",
              value: totalActivities,
            },
          ]}
        // actions={[
        //   {
        //     title: showForm ? "Close form" : "+ New Activity",
        //     handler: showForm ? handleCloseCreateActivityForm : handleOpenCreateActivityForm,
        //   }
        // ]}
        />
        <div className="card">
          <div className={styles.futureActivityOnly}>
            <div className={styles.SwitchText}>Show future activity</div>
            <Switch
              checked={futureActivityOnly}
              onClick={(bolean, event) => {
                changeListToUser(event);
              }}
            />
          </div>
          <Table
            data={activities}
            columns={columns}
            searchProps={{
              placeholder: "Search Activities",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalActivities,
            }}
            onChange={onChange}
            onRowClick={handleShowActivityDetails}
            loading={loading}
            onReload={handleReload}
          />
        </div>
      </div>
      <ActivityDetails
        activityDetails={selectedActivityDetails}
        visible={activityDetailsVisible}
        onClose={handleCloseActivityDetails}
      />
    </>
  );
}

export default Activity;
