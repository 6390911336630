import React from "react";
import { Outlet } from "react-router-dom";

// Styles
import styles from "./payments.module.scss";

function Payments() {
  return (
    <div className={styles.pageContainer}>
      <Outlet />
    </div>
  );
}

export default Payments;
