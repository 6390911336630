// Lodash
import _property from "lodash/property";

const id = _property("id");
const paymentDetails = _property("payment");
const status = _property("status");
const transaction = _property("transaction");
const packageDetails = _property("package_details");
const eventDetails = _property("event_details");

export default {
  id,
  paymentDetails,
  status,
  transaction,
  packageDetails,
  eventDetails
};
