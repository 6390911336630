import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const businessAccountSlice = createSlice({
  name: "businessAccounts",
  initialState: INITIAL_STATE,
  reducers: {
    addAccounts: (state, action = {}) => {
      const { payload: users = [] } = action;
      state.items = users;
    },
  },
});

const { addAccounts } = businessAccountSlice.actions;
const businessAccountsReducer = businessAccountSlice.reducer;

export { addAccounts, businessAccountsReducer };
export default businessAccountSlice;
