import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Lodash
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { CheckableTag } from "../../../../components/atoms/tag";

// Constants
import { EMPTY_OBJECT, PERMISSIONS } from "../../../../constants";

// Styles
import styles from "./permissions.module.scss";

function Permissions({ value: selectedPermissions, onChange }) {
  const handleUpdatePermissions = (moduleType, permission, isAdded) => {
    const { id } = permission;
    let newPermissions = selectedPermissions;
    const modulePermissions = selectedPermissions[moduleType] || [];
    if (isAdded && !_includes(modulePermissions, id)) {
      newPermissions = {
        ...selectedPermissions,
        [moduleType]: [...modulePermissions, id],
      };
    } else if (!isAdded && _includes(modulePermissions, id)) {
      const filteredPermissions = _filter(
        modulePermissions,
        (currentPermission) => currentPermission !== id
      );
      newPermissions = {
        ...selectedPermissions,
        [moduleType]: filteredPermissions,
      };
    }
    onChange(newPermissions);
  };

  const renderPermission = (moduleType) => (permission) => {
    const { id, name } = permission;
    const modulePermissions = selectedPermissions[moduleType];
    const isPermissionSelected = _includes(modulePermissions, id);
    const tagClassName = cx(styles.tag, {
      [styles.active]: isPermissionSelected,
    });
    return (
      <CheckableTag
        key={id}
        className={tagClassName}
        checked={isPermissionSelected}
        onChange={(checked) => {
          handleUpdatePermissions(moduleType, permission, checked);
        }}
      >
        {name}
      </CheckableTag>
    );
  };

  const renderModulePermissions = ({ id, name, permissions }) => {
    return (
      <div className={styles.module} key={id}>
        <div className={styles.moduleName}>{name}</div>
        <div className={styles.permissions}>
          {_map(permissions, renderPermission(id))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {_map(PERMISSIONS, renderModulePermissions)}
    </div>
  );
}

Permissions.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

Permissions.defaultProps = {
  value: EMPTY_OBJECT,
  onChange: _noop,
};
export default Permissions;
