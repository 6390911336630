import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import SportDetails from "./components/sportDetails";
import PageHeader from "../../components/molecules/pageHeader";
import CreateSportForm from "./components/createSportForm";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import sportsService from "../../services/sportsService";

// Redux
import { addSports } from "./redux/sportsSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./sports.module.scss";

function Sports() {
  const [totalSports, setTotalSports] = useState(0);
  const [selectedSportDetails, setSelectedSportDetails] =
    useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });

  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);

  const [createSportFormVisible, setCreateSportFormVisibility] =
    useState(false);

  const [sportDetailsVisible, setSportDetailsVisibility] = useState(false);
  const dispatch = useDispatch();
  const sports = useSelector((state) => state?.sports?.items);

  const getSportsData = (pageNumber, pageSize, sortField, order) => {
    sportsService
      .fetchSports({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const sports = listResponseReader.results(response);
        const totalSports = listResponseReader.totalResults(response);
        setTotalSports(totalSports);
        dispatch(addSports(sports));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addSports(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getSportsData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);

  const handleOpenCreateSportForm = (sportDetails) => {
    setEditMode(false);
    setCreateSportFormVisibility(true);
    setSelectedSportDetails(sportDetails);
  };

  const handleOpenEditSportForm = (sportDetails) => {
    setEditMode(true);
    setCreateSportFormVisibility(true);
    setSelectedSportDetails(sportDetails);
  };

  const handleCloseCreateSportForm = () => {
    setCreateSportFormVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleCloseSportDetails = () => {
    isEditMode && setEditMode(false);
    setSportDetailsVisibility(false);
    setSelectedSportDetails(EMPTY_OBJECT);
  };

  const handleShowSportDetails = (sportDetails) => {
    setSelectedSportDetails(sportDetails);
    setSportDetailsVisibility(true);
  };

  const handleDeleteSport = (sport) => {
    console.log("Deleting sport", sport);
  };

  const columns = createColumns({
    onEdit: handleOpenEditSportForm,
    onDelete: handleDeleteSport,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)
    getSportsData(
      pagination.current,
      DEFAULT_PAGE_SIZE,
      field,
      order
    );
  }

  return (
    <>
      <div className="container">
        <PageHeader
          title="Sports"
          statistics={[
            {
              title: "Total Sports",
              value: totalSports,
            },
          ]}
          actions={[
            {
              title: "+ New Sport",
              handler: handleOpenCreateSportForm,
            },
          ]}
        />
        <div className="card">
          <Table
            data={sports}
            columns={columns}
            searchProps={{
              placeholder: "Search Sports",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalSports,
            }}
            onChange={onChange}
            onRowClick={handleShowSportDetails}
            loading={loading}
            onReload={handleReload}
          />
        </div>
      </div>
      <CreateSportForm
        visible={createSportFormVisible}
        onClose={handleCloseCreateSportForm}
        sportDetails={selectedSportDetails}
        isEditMode={isEditMode}
        onReload={handleReload}
      />
      <SportDetails
        sportDetails={selectedSportDetails}
        visible={sportDetailsVisible}
        onClose={handleCloseSportDetails}
      />
    </>
  );
}

export default Sports;
