// Lodash
import _property from "lodash/property";

const createdAt = _property("createdAt");
const firstName = _property("created_by.first_name");
const lastName = _property("created_by.last_name");
const id = _property("_id");
const isActive = _property("is_active");
const name = _property("name");
const updatedAt = _property("updatedAt");
const userType = _property("user_type");
const profileImageUrl = _property("profile_pic");
const used = _property("used");

export default {
  createdAt,
  firstName,
  lastName,
  id,
  isActive,
  name,
  updatedAt,
  userType,
  profileImageUrl,
  used,
};
