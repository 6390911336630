// Lodash
import _property from "lodash/property";

const id = _property("_id");
const type = _property("type");

export default {
  id,
  type,
};
