import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const freelancersSlice = createSlice({
  name: "freelancers",
  initialState: INITIAL_STATE,
  reducers: {
    addFreelancers: (state, action = {}) => {
      const { payload: freelancers = [] } = action;
      state.items = freelancers;
    },
  },
});

const { addFreelancers } = freelancersSlice.actions;
const freelancersReducer = freelancersSlice.reducer;

export { addFreelancers, freelancersReducer };
export default freelancersSlice;
