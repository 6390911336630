import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";
import { decryptData } from "../utils/globle";

const fetchSubAdmins = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "descend" ? "desc" : order === "ascend" ? "asc" : "",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const createSubAdmin = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin`,
  });
  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updateSubAdmin = (id, payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/${id}`,
  });
  return axios
    .put(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchMyDetails = () => {
  return axios
    .get(`${BASE_URL}/admin/my_details`, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

const fetchConfigurations = () => {
  return axios
    .post(`${BASE_URL}/config`, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => decryptData(getDataFromResponse(response)));
};

const UpdateConfigurations = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/config/update`,
  });
  return axios
    .put(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => decryptData(getDataFromResponse(response)))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

export default {
  fetchSubAdmins,
  createSubAdmin,
  updateSubAdmin,
  fetchMyDetails,
  fetchConfigurations,
  UpdateConfigurations,
};
