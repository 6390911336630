import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from 'antd';

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import Drawer from "../../../../components/atoms/drawer";
import Form from "../../../../components/atoms/form";
import { Input, Password } from "../../../../components/atoms/input";
import Button from "../../../../components/atoms/button";
import { toast } from "../../../../components/atoms/toaster";
import AsyncSelect from "../../../../components/molecules/asyncSelect";

// Reader
import { roleReader, subAdminReader } from "../../../../readers";

// Styles
import styles from "./createSubAdminForm.module.scss";

// Services
import subadminService from "../../../../services/subAdminsService";
import rolesService from "../../../../services/rolesService";

const Option = { Select };

const handleCreateRoleOptions = (roles) =>
  _map(roles, (role) => ({
    value: roleReader.id(role),
    label: roleReader.role(role),
    role,
  }));

function CreateSubAdminForm({
  visible,
  onClose,
  subAdminDetails,
  isEditMode,
  onReload,
  roleOptions,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const subAdminID = subAdminReader.id(subAdminDetails);
  const firstName = subAdminReader.firstName(subAdminDetails);
  const lastName = subAdminReader.lastName(subAdminDetails);
  const email = subAdminReader.email(subAdminDetails);
  const status = subAdminReader.isActive(subAdminDetails);
  const role = subAdminReader.roleId(subAdminDetails);

  let title = "Create New Sub-Admin";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    password: null,
    is_active: status || null,
    role: role || null,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    const finalValues = { ...values };
    setLoading(true);
    if (isEditMode) {
      subadminService
        .updateSubAdmin(subAdminID, finalValues)
        .then((response) => {
          if (response?.success) {
            onReload();
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    } else {
      subadminService
        .createSubAdmin(finalValues)
        .then((response) => {
          if (response.success) {
            toast.success("New Subadmin Added Successfully!!");
            form.resetFields();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  if (isEditMode) {
    title = "Edit SubAdmin";
  }

  return (
    <Drawer title={title} open={visible} onClose={onClose}>
      <div className={styles.container}>
        <Form
          form={form}
          initialValues={defaultValues}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please input freelancer first name!",
              },
            ]}
          >
            <Input placeholder="Enter freelancer first name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please input freelancer last name!",
              },
            ]}
          >
            <Input placeholder="Enter freelancer last name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="Enter e-mail" />
          </Form.Item>
          {!isEditMode && (
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Password placeholder="Enter password" className={styles.input} />
            </Form.Item>
          )}
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: false,
                message: "Please select user!",
              },
            ]}
          >
            <AsyncSelect
              type="role"
              asyncService={rolesService.fetchRoles}
              defaultOptions={roleOptions}
              className={styles.select}
              createOptions={handleCreateRoleOptions}
            ></AsyncSelect>
          </Form.Item>
          <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="select status" className={styles.select}>
              <Option value={true}>Active</Option>
              <Option value={false}>InActive</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateSubAdminForm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateSubAdminForm.defaultProps = {
  visible: false,
  onClose: _noop,
};

export default CreateSubAdminForm;
