import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { confirm } from "../../../components/atoms/modal";
import Drawer from "../../../components/atoms/drawer";
import Divider from "../../../components/atoms/divider";
import { ExclamationCircleOutlined } from "../../../components/atoms/icon";
import { Title, Label } from "../../../components/atoms/typography";
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import { Tag, Button } from 'antd';

// Services
import activityService from "../../../services/activityService";

// Redux
import { removeActivity } from "../redux/activitySlice";

// Constants
import { EMPTY_OBJECT } from "../../../constants";

// Styles
import styles from "./activityDetails.module.scss";

function ActivityDetails({ visible, onClose, activityDetails }) {

  const {
    description,
    no_of_players,
    no_of_team,
    fees,
    gender,
    status,
    sports_id,
    address = {},
    name,
    invitations,
    user_id,
    _id
    // is_active,
    // event_time_slot,
    // is_complete,
    // is_business,
  } = activityDetails


  const {
    latitude,
    longitude,
    complete_address,
    custom_event_date,
    custom_start_time,
    custom_end_time,
  } = address

  let eventDate = custom_event_date
  let statusTime = STATUS_TYPES.INACTIVE
  let customText = "Past"
  let check = moment().isSameOrBefore(eventDate) || moment(eventDate).format("DD MM YYYY") == moment().format("DD MM YYYY")
  if (check) {
    statusTime = STATUS_TYPES.ACTIVE
    customText = "Upcoming"
  }

  let disabled = false

  if (invitations?.length > 0) {
    let checkI = invitations.find(item => item.status === "accepted")

    if (checkI && fees > 0) {
      disabled = true
    }
  }


  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const activities = useSelector((state) => state?.activity?.items);

  const handleDelete = () => {
    setloading(true)
    confirm({
      title: "Are you sure you want to delete this activity?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        activityService
          .deleteActivity(_id)
          .then((response) => {
            if (response.success)
              dispatch(removeActivity({ activityId: _id, activities }));
            onClose()
            setloading(false)
          })
          .catch((error) => {
            console.log(error, 'error');
            setloading(false)
          })
      },
      onCancel() {
        setloading(false)
      },
    });
  }
  return (
    <Drawer
      title={name}
      open={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>

        <div className="column">
          <div className="detailBox">
            <Title className="detailTitle">Activity Status</Title>
            <Label>{status} <Status customText={customText} status={statusTime} /></Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">Price</Title>
            <Label>{JSON.stringify(fees)}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">No of players</Title>
            <Label>{no_of_players}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">No of team</Title>
            <Label>{no_of_team}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">gender</Title>
            <Label>{gender}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">Sport</Title>
            <Label>{sports_id?.name}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">Date & Time</Title>
            <Label>{moment(eventDate).format("DD Mo YYYY")}</Label><br />
            <Label>{custom_start_time + " to " + custom_end_time}</Label>
          </div>

          {complete_address ? <div className="detailBox">
            <Title className="detailTitle">Address</Title>
            <Label>{complete_address}</Label>
          </div>
            : null}

          {user_id ?
            <div className="detailBox">
              <Title className="detailTitle">Created By</Title>
              <Label>{user_id.username}</Label>
            </div>
            : null}

          {invitations?.length > 0 ?
            <div className="detailBox">
              <Divider orientation="left"><Title className="detailTitle">Invitations ({invitations?.length})</Title></Divider>
              {_map(invitations, (user) =>
                <Tag
                  color={
                    user.status === "declined" ? "red"
                      : user.status === "accepted" ? "green"
                        : "orange"
                  }
                >
                  {user?.recevier_id?.username} - {user.status}
                </Tag>
              )}
            </div>
            : null}

          {description ? <div className="detailBox">
            <Divider orientation="left"><Title className="detailTitle">Description</Title></Divider>
            <Label>{description}</Label>
          </div>
            : null}

        </div>
        <Divider />

        <Button
          danger
          type="primary"
          onClick={handleDelete}
          loading={loading}
          disabled={disabled}
        >
          Delete Activity
        </Button>
      </div>
    </Drawer>
  );
}

ActivityDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  activityDetails: PropTypes.object,
};

ActivityDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  activityDetails: EMPTY_OBJECT,
};

export default ActivityDetails;
