import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// Components
import CardBody from "./components/PostsCardBody"
import PageHeader from "../../components/molecules/pageHeader";
import Loader from "../../components/molecules/loader";
import Image from "../../components/atoms/image";
import {
  HeartOutlined,
  CommentOutlined,
} from "../../components/atoms";

import { Row, Col, Card, Button, Modal } from "antd";
import postsService from "../../services/postsService";

// Constants
import { EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./posts.module.scss";

// Images
import NoImage from "../../assets/images/noImage.jpeg";

const { Meta } = Card;
const { confirm } = Modal;


function Posts() {
  const { userid } = useParams();

  const [allPosts, setAllPosts] = useState(EMPTY_ARRAY);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setpageLimit] = useState(20);
  const [pageNumber, setpageNumber] = useState(1);
  const [nextPage, setnextPage] = useState(2);
  const [hasNextPage, sethasNextPage] = useState(false);
  const [totalDocs, settotalDocs] = useState(0);
  const [deleteLoading, setdeleteLoading] = useState(true);
  // const [ShowMore, setShowMore] = useState(false);


  useEffect(() => {
    console.log(userid, 'userid')
    setAllPosts(EMPTY_ARRAY)
    getPostsData(pageLimit, pageNumber)
  }, []);


  const onNextPage = () => {
    getPostsData(pageLimit, nextPage)
  };

  const getPostsData = (pageLimit, pageNumber, sortField, order) => {
    postsService
      .fetchPosts({
        pageNumber,
        pageLimit,
        searchText,
        sortField,
        order,
        userid
      })
      .then((response) => {
        // console.log(response, 'response')
        setAllPosts([...allPosts, ...response.docs,]);
        setnextPage(response.nextPage)
        sethasNextPage(response.hasNextPage)
        settotalDocs(response.totalDocs)
      })
      .catch((error) => {
        console.log(error, 'error')
        setAllPosts(EMPTY_ARRAY);
        sethasNextPage(false)
      })
  }

  const deletePost = (post, index) => {
    setdeleteLoading(
      { [post._id]: true }
    )

    confirm({
      title: 'Are you sure delete this post?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        postsService
          .deletePost(post?._id)
          .then((response) => {
            if (response.success) {
              let temp = [...allPosts]
              temp[index].status = "deleted"
              setAllPosts(temp)
              toast.dark("Deleted Successful!!");
            } else {
              toast.warning("Something went wrong while updating!");
            }
            setdeleteLoading({ [post._id]: false })
          })
          .catch((error) => {
            setdeleteLoading({ [post._id]: false })
            console.log(error);
          })
      },
      onCancel() {
        setdeleteLoading({ [post._id]: false })
      },
    });

  };



  return (
    <div className={styles.pageContainer}>
      <PageHeader title={`${totalDocs} Posts`} />

      <Row>
        {allPosts.map((item, index) => (
          <Col key={item._id} xs={24} sm={12} md={12} lg={8} xl={6}>
            <div className={styles.card}>

              <div className={
                item.role === "user"
                  ? styles.userRole
                  : styles.freelancerRole
              }>
                {item.role}
              </div>

              <Card
                hoverable
                cover={
                  item?.file && <Image
                    width={'100%'}
                    height={250}
                    src={item?.file}
                    style={{ objectFit: 'cover' }}
                  />
                }
                actions={[
                  deleteLoading[item._id]
                    ? <Loader />
                    : <DeleteOutlined key="ellipsis" onClick={() => deletePost(item, index)} />
                  ,
                ]}
              >
                <CardBody
                  likes={item.likes}
                  comments={item.comments}
                  status={item.status}
                  by={item.by.username}
                  showMore={item.showMore}
                  description={item.description}
                />
              </Card>
            </div>
          </Col>
        ))}
      </Row>

      <center>
        {hasNextPage &&
          <Button
            type="primary"
            onClick={() => onNextPage()}
            className={styles.showMoreButton}
          >
            Load More
          </Button>
        }
      </center>

    </div>
  );
}

export default Posts;
