import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import SubAdminDetails from "./components/subAdminDetails";
import PageHeader from "../../components/molecules/pageHeader";
import CreateSubAdminForm from "./components/createSubAdminForm";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import subAdminsService from "../../services/subAdminsService";
import rolesService from "../../services/rolesService";

// Redux
import { addSubAdmins } from "./redux/subAdminsSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./subAdmins.module.scss";

function SubAdmins() {
  const [loading, setLoading] = useState(true);
  const [roleOptions, setRoleOptions] = useState(EMPTY_ARRAY);

  const [totalSubAdmins, setTotalSubAdmins] = useState(0);
  const [selectedSubAdminDetails, setSelectedSubAdminDetails] =
    useState(EMPTY_OBJECT);

  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);

  const [createSubAdminFormVisible, setCreateSubAdminFormVisibility] =
    useState(false);

  const [subadminDetailsVisible, setSubAdminDetailsVisibility] =
    useState(false);
  const dispatch = useDispatch();
  const subAdmins = useSelector((state) => state?.subAdmins?.items);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    setLoading(true);
    subAdminsService
      .fetchSubAdmins({
        pageNumber,
        pageSize,
        searchText,
      })
      .then((response) => {
        const subAdmins = listResponseReader.results(response);
        const totalSubAdmins = listResponseReader.totalResults(response);
        setTotalSubAdmins(totalSubAdmins);
        dispatch(addSubAdmins(subAdmins));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addSubAdmins(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationDetails]);

  useEffect(() => {
    rolesService
      .fetchRoles()
      .then((response) => {
        const roles = listResponseReader.results(response);
        setRoleOptions(roles);
      })
      .catch((error) => {
        console.log("Error fetching roles", error);
        setRoleOptions(EMPTY_ARRAY);
      });
  }, []);

  const handleOpenCreateSubAdminForm = (subAdminDetails) => {
    setEditMode(false);
    setCreateSubAdminFormVisibility(true);
    setSelectedSubAdminDetails(subAdminDetails);
  };

  const handleCloseCreateSubAdminForm = () => {
    setCreateSubAdminFormVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleCloseSubAdminDetails = () => {
    isEditMode && setEditMode(false);
    setSubAdminDetailsVisibility(false);
    setSelectedSubAdminDetails(EMPTY_OBJECT);
  };

  const handleShowSubAdminDetails = (subAdminDetails) => {
    setSelectedSubAdminDetails(subAdminDetails);
    setSubAdminDetailsVisibility(true);
  };

  const handleDeleteSubAdmin = (subadmin) => {
    console.log("Deleting subadmin", subadmin);
  };

  const handleOpenEditSubAdminForm = (adminDetail) => {
    setEditMode(true);
    setSelectedSubAdminDetails(adminDetail);
    setCreateSubAdminFormVisibility(true);
  };

  const columns = createColumns({
    onEdit: handleOpenEditSubAdminForm,
    onDelete: handleDeleteSubAdmin,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="SubAdmins"
          statistics={[
            {
              title: "Total SubAdmins",
              value: totalSubAdmins,
            },
          ]}
          actions={[
            {
              title: "+ New SubAdmin",
              handler: handleOpenCreateSubAdminForm,
            },
          ]}
        />
        <div className={styles.pageContent}>
          <Table
            data={subAdmins}
            columns={columns}
            searchProps={{
              placeholder: "Search SubAdmins",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalSubAdmins,
            }}
            loading={loading}
            onRowClick={handleShowSubAdminDetails}
            onReload={handleReload}
          />
        </div>
      </div>
      <CreateSubAdminForm
        visible={createSubAdminFormVisible}
        onClose={handleCloseCreateSubAdminForm}
        subAdminDetails={selectedSubAdminDetails}
        isEditMode={isEditMode}
        roleOptions={roleOptions}
        onReload={handleReload}
      />
      <SubAdminDetails
        subAdminDetails={selectedSubAdminDetails}
        visible={subadminDetailsVisible}
        onClose={handleCloseSubAdminDetails}
      />
    </>
  );
}

export default SubAdmins;
