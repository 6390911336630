import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const activitySlice = createSlice({
  name: "activity",
  initialState: INITIAL_STATE,
  reducers: {
    addActivities: (state, action = {}) => {
      const { payload: activity = [] } = action;
      state.items = activity;
    },
    removeActivity: (state, action = {}) => {
      const { activityId, activities } = action.payload;
      let newActivity = activities.filter(activity => activity._id !== activityId)
      state.items = newActivity;
    },
  },
});

const { addActivities, removeActivity } = activitySlice.actions;
const activityReducer = activitySlice.reducer;

export { addActivities, removeActivity, activityReducer };
export default activitySlice;
