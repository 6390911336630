import React, { useState, useEffect } from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// Components
import {
  HeartOutlined,
  CommentOutlined,
} from "../../../components/atoms";

import { Card, Modal } from "antd";

// Styles
import styles from "../posts.module.scss";


const { Meta } = Card;
const { confirm } = Modal;

export default function PostsCardBody({
  likes,
  comments,
  status,
  by,
  description
}) {

  const [ShowMore, setShowMore] = useState(false);

  const descriptionText = (text = "") => {
    if (text?.length > 30) {
      if (ShowMore) return (<div>
        {text}
        <strong><span onClick={() => setShowMore(false)}>{` (show less)`}</span></strong>
      </div>
      )
      else return (
        <div>
          {ShowMore
            ? text
            : text?.substring(0, 30)}
          <strong><span onClick={() => setShowMore(true)}>{` (show more)`}</span></strong>
        </div>
      )
    }
    else return text
  }
  return (
    <div>
      {likes.length > 0 ?
        <div className={styles.likes}>
          <HeartOutlined />{" "}
          {likes.length}
        </div> : null}
      {comments.length > 0 ?
        <div className={styles.comments}>
          <CommentOutlined />{" "}
          {comments.length}
        </div> : null}

      {status === "reported" ?
        <div className={styles.statusReported}>
          Reported
        </div>
        : status === "deleted" ?
          <div className={styles.statusDeleted}>
            Deleted
          </div>
          : null}
      <Meta title={by.username} description={descriptionText(description)} />
    </div>
  );
}
