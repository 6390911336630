import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const venuesSlice = createSlice({
  name: "venues",
  initialState: INITIAL_STATE,
  reducers: {
    addVenues: (state, action = {}) => {
      const { payload: venues = EMPTY_ARRAY } = action;
      state.items = venues;
    },
  },
});

const { addVenues } = venuesSlice.actions;
const venuesReducer = venuesSlice.reducer;

export { addVenues, venuesReducer };
export default venuesSlice;
