// Lodash
import _property from "lodash/property";

const id = _property("id");
const permissions = _property("permissions");
const role = _property("role");

export default {
  id,
  permissions,
  role,
};
