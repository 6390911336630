import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState: INITIAL_STATE,
  reducers: {
    addCompanies: (state, action = {}) => {
      const { payload: companies = [] } = action;
      state.items = companies;
    },
  },
});

const { addCompanies } = companiesSlice.actions;
const companiesReducer = companiesSlice.reducer;

export { addCompanies, companiesReducer };
export default companiesSlice;
