// Lodash
import _noop from "lodash/noop";

// Constants
import { PAGE_TITLE } from "../constants";
import { EMPTY_OBJECT } from "../../../constants";

const createPageHeaderProps = ({
  actionHandler = _noop,
  actionDisabled = false,
} = EMPTY_OBJECT) => {
  return {
    title: PAGE_TITLE,
    actions: [
      {
        title: "Make a Payment",
        handler: actionHandler,
        disabled: actionDisabled,
      },
    ],
  };
};

export { createPageHeaderProps };
