import React, { useState, useEffect } from "react";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Image from "../../components/atoms/image";
import {
  FireOutlined,
  AppstoreOutlined,
  DollarOutlined,
} from "../../components/atoms";

import { Row, Col, Card, Button } from "antd";

// Constants
import { Business_URL } from "../../constants/services";
import { EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./packages.module.scss";

// Images
import DefaultImage from "../../assets/images/user.png";
import packagesService from "../../services/packagesService";
const { Meta } = Card;

function Packages() {

  const [allPackages, setAllPackages] = useState(EMPTY_ARRAY);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setpageLimit] = useState(20);
  const [pageNumber, setpageNumber] = useState(1);
  const [nextPage, setnextPage] = useState(2);
  const [totalDoc, settotalDoc] = useState(0);
  const [hasNextPage, sethasNextPage] = useState(false);


  useEffect(() => {
    setAllPackages(EMPTY_ARRAY)
    getPackagesData(pageLimit, pageNumber)
  }, []);


  const onNextPage = () => {
    getPackagesData(pageLimit, nextPage)
    setnextPage(nextPage + 1)
  };

  const getPackagesData = (pageLimit, pageNumber, sortField, order) => {
    packagesService
      .fetchPackages({
        pageNumber,
        pageLimit,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        let packagelist = response.data.data
        settotalDoc(response.data.total)
        setAllPackages([...allPackages, ...packagelist]);
        if (packagelist.length === 20) sethasNextPage(true)
        else sethasNextPage(false)
        // setnextPage(response.nextPage)
      })
      .catch((error) => {
        console.log(error, 'error')
        setAllPackages(EMPTY_ARRAY);
        sethasNextPage(false)
      })

  }

  const openPackage = ({ _id }) => {
    window.open(`${Business_URL}/deals?id=${_id}`, "_blank");
  }

  return (
    <div className={styles.pageContainer}>
      <PageHeader title={`${totalDoc} Packages`} />

      <Row>
        {allPackages.map(item => (
          <Col key={item._id} xs={24} sm={12} md={12} lg={8} xl={6}>
            <div className={styles.card}>

              {item.hot_deal ?
                <div className={styles.freelancerRole}>
                  <FireOutlined />
                </div>
                : null}

              <Card
                hoverable
                onClick={() => { openPackage(item) }}
                cover={
                  <Image
                    width={'100%'}
                    height={250}
                    src={item?.photos ? item?.photos[0] : null}
                    fallback={DefaultImage}
                    style={{ objectFit: 'cover' }}
                  />
                }
              >
                {!item.is_active ?
                  <div className={styles.is_active_false}>
                    In active
                  </div>
                  : null}
                <div className={styles.quantity}>
                  <AppstoreOutlined />{" "}
                  {item.quantity}
                </div>
                <div className={styles.price}>
                  <DollarOutlined />
                  {" "}
                  {item.price}
                </div>
                <Meta title={item.package_name} description={
                  item?.description ?
                    item?.description?.length > 29
                      ? item?.description?.slice(0, 30) + "..."
                      : item?.description
                    : null
                } />
              </Card>
            </div>
          </Col>
        ))}
      </Row>

      <center>
        {hasNextPage &&
          <Button
            type="primary"
            onClick={() => onNextPage()}
            className={styles.showMoreButton}
          >
            Load More
          </Button>
        }
      </center>

    </div>
  );
}

export default Packages;
