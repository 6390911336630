// Lodash
import _reduce from "lodash/reduce";

// Readers
import { transactionReader } from "../../../../../readers";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../../constants";

const updatePaymentStatistics = (statistics = EMPTY_OBJECT, transaction) => {
  const { count, gross, commission, net } = statistics;
  return {
    count: count + 1,
    gross: gross + transactionReader.sellingPrice(transaction),
    commission: commission + transactionReader.dudiCommission(transaction),
    net: net + transactionReader.netAmount(transaction),
  };
};

const computePaymentStatistics = (transactions = EMPTY_ARRAY) => {
  const initialStatistics = {
    count: 0,
    gross: 0,
    commission: 0,
    net: 0,
  };

  return _reduce(transactions, updatePaymentStatistics, initialStatistics);
};

export { computePaymentStatistics };
