// Lodash
import _property from "lodash/property";

const type = _property("permission.type");
const rights = _property("rights");

export default {
  type,
  rights,
};
