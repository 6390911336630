const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const DELETED = "DELETED";
const REVERTED = "REVERTED";
const PAID = "PAID";

export default {
  ACTIVE,
  INACTIVE,
  DELETED,
  REVERTED,
  PAID,
};
