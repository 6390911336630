import React from "react";
import PropTypes from "prop-types";
import { ReloadOutlined } from "@ant-design/icons";

// Lodash
import _noop from "lodash/noop";

// Components
import SearchBox from "../../../../molecules/searchBox";
import Button, { BUTTON_TYPES } from "../../../../atoms/button";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants/base.constants";

// Styles
import styles from "./tableHeader.module.scss";

function TableHeader({
  onSearch,
  searchProps,
  showSearch,
  showReload,
  onReload,
  filters,
}) {
  return (
    <div className={styles.container}>
      {filters}
      {showSearch && <SearchBox onSearch={onSearch} {...searchProps} />}
      {showReload && (
        <Button onClick={onReload} type={BUTTON_TYPES.TEXT}>
          <ReloadOutlined />
        </Button>
      )}
    </div>
  );
}

TableHeader.propTypes = {
  onSearch: PropTypes.func,
  searchProps: PropTypes.object,
  showSearch: PropTypes.bool,
  showReload: PropTypes.bool,
  onReload: PropTypes.func,
  filters: PropTypes.element,
};

TableHeader.defaultProps = {
  onSearch: _noop,
  searchProps: EMPTY_OBJECT,
  showSearch: true,
  showReload: true,
  onReload: _noop,
};
export default TableHeader;
