import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Lodash
import _isEmpty from "lodash/isEmpty";

// Components
import { Label, Title, Spin } from "../../../../components/atoms";
import {
  ConfirmationDialog,
  PageHeader,
  Payee,
} from "../../../../components/molecules";
import PaymentTransactions from "../paymentTransactions";

// Helpers
import { createPageHeaderProps } from "./helpers/pageHeader";
import {
  getPaymentDate,
  getRevertedDate,
  getPaidByUserDetails,
  getRevertedByUserDetails,
} from "./helpers/paymentDetails";
import { isPaymentReverted } from "../../helpers/paymentStatus";

// Services
import { paymentsService } from "../../../../services";

// Readers
import { paymentReader } from "../../../../readers";

// Redux Actions
import {
  setSelectedPayment,
  setSelectedPaymentLoading,
} from "../../../../redux/slices/paymentsSlice";

// Styles
import styles from "./paymentDetails.module.scss";

function PaymentDetails() {
  const dispatch = useDispatch();
  const { paymentId } = useParams();

  const [isConfirmationBoxVisible, setConfirmationBoxVisibility] =
    useState(false);
  const [isPaymentRevertInProgress, setPaymentRevertState] = useState(false);

  const selectedPayment = useSelector(
    (state) => state?.payments?.selectedPayment
  );
  const { paymentDetails, loading } = selectedPayment;

  useEffect(() => {
    dispatch(setSelectedPaymentLoading(true));
    paymentsService
      .fetchPaymentDetails(paymentId)
      .then((paymentDetails) => {
        dispatch(setSelectedPayment(paymentDetails));
      })
      .finally(() => {
        setSelectedPaymentLoading(false);
      });
  }, [paymentId]);

  const handleCloseConfirmationBox = () => {
    setConfirmationBoxVisibility(false);
  };

  const handleRevertPayment = () => {
    setPaymentRevertState(true);
    paymentsService
      .revertPayment(paymentId)
      .then(() => {
        toast.success("Payment succesfully reverted");
      })
      .finally(() => {
        setPaymentRevertState(false);
      });
  };

  const handleShowConfirmationBox = () => {
    setConfirmationBoxVisibility(true);
  };

  const pageHeaderProps = createPageHeaderProps({
    actionHandler: handleShowConfirmationBox,
    actionDisabled: isPaymentReverted(paymentDetails),
    actionLoading: isPaymentRevertInProgress,
    paymentId,
  });

  const paidByUserDetails = getPaidByUserDetails(paymentDetails);
  const revertedByUserDetails = getRevertedByUserDetails(paymentDetails);

  return (
    <>
      <div className={styles.container}>
        <PageHeader {...pageHeaderProps} />
        <div className={styles.heading}>
          {loading && <Spin className={styles.detailLoader} />}
          {!loading && (
            <>
              <div className={styles.paymentDetails}>
                <div className={styles.detail}>
                  <Title>
                    {paymentReader.transactionsCount(paymentDetails)}
                  </Title>
                  <Label>Transactions Count</Label>
                </div>
                <div className={styles.detail}>
                  <Title>{paymentReader.grossSale(paymentDetails)}</Title>
                  <Label>Gross Sale</Label>
                </div>
                <div className={styles.detail}>
                  <Title>{paymentReader.dudiCommission(paymentDetails)}</Title>
                  <Label>Dudi Commission</Label>
                </div>
                <div className={styles.detail}>
                  <Title>{paymentReader.netPayment(paymentDetails)}</Title>
                  <Label>Net Amount Paid</Label>
                </div>
              </div>
              <div className={styles.paymentDetails}>
                <div className={styles.detail}>
                  <Payee {...paidByUserDetails} />
                  <Label>Paid By</Label>
                </div>
                <div className={styles.detail}>
                  <Title className={styles.date}>
                    {getPaymentDate(paymentDetails)}
                  </Title>
                  <Label>Payment Date</Label>
                </div>
                <div className={styles.detail}>
                  {!_isEmpty(revertedByUserDetails) && (
                    <Payee {...revertedByUserDetails} />
                  )}
                  {_isEmpty(revertedByUserDetails) && (
                    <Title className={styles.date}>-</Title>
                  )}
                  <Label>Reverted By</Label>
                </div>
                <div className={styles.detail}>
                  <Title className={styles.date}>
                    {getRevertedDate(paymentDetails)}
                  </Title>
                  <Label>Reverted Date</Label>
                </div>
              </div>

              <div className={styles.note}>
                <Title className={styles.noteTitle}>Note:</Title>
                <Label className={styles.noteContent}>
                  {paymentReader.paymentNote(paymentDetails)}
                </Label>
              </div>
            </>
          )}
        </div>

        <PaymentTransactions paymentId={paymentId} />
      </div>
      {isConfirmationBoxVisible && (
        <ConfirmationDialog
          visible={isConfirmationBoxVisible}
          onOk={handleRevertPayment}
          onCancel={handleCloseConfirmationBox}
          confirmationText={
            "Are you sure you want to revert payment ? This action is irreversible and might affect your books."
          }
          confirmationTitle={"Confirm Payment Reversal"}
        />
      )}
    </>
  );
}

export default PaymentDetails;
