import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Table } from "../../../../components/organisms";
import { PageHeader } from "../../../../components/molecules";
import PartnerPaymentSummary from "../partnerPaymentSummary";
import { Title } from "../../../../components/atoms";

// Helpers
import { createColumns, createPageHeaderProps } from "./helpers";

// Services
import { salesService, paymentsService } from "../../../../services";

// Readers
import { partnerSalesReader } from "../../../../readers";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Redux Actions
import { setSales, setLoading } from "../../../../redux/slices/salesSlice";

// Styles
import styles from "./unsettledSales.module.scss";

function SalesSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [isPaymentSummaryModalVisible, setPaymentSummaryModalVisibility] =
    useState(false);
  const [selectedPartnerSales, setSelectedPartnerSales] =
    useState(EMPTY_OBJECT);
  const [totalSales, setTotalSales] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState(
    DEFAULT_PAGINATION_DETAILS
  );
  const [searchText, setSearchText] = useState();
  const sales = useSelector((state) => state?.sales?.items);
  const loading = useSelector((state) => state?.sales?.loading);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    dispatch(setLoading(true));
    salesService
      .fetchUnsettledSales({ pageNumber, pageSize, searchText })
      .then((salesDetails = EMPTY_OBJECT) => {
        const sales = salesDetails?.docs || EMPTY_ARRAY;
        setTotalSales(salesDetails?.totalDocs);
        dispatch(setSales(sales));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [paginationDetails]);

  const handleClosePaymentSummary = () => {
    setPaymentSummaryModalVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleProcessPayment = ({ note }) => {
    setPaymentProcessing(true);
    paymentsService
      .processPaymentByUser({
        partnerId: partnerSalesReader.userId(selectedPartnerSales),
        note,
        count: partnerSalesReader.bookings(selectedPartnerSales),
        gross: partnerSalesReader.grossSale(selectedPartnerSales),
        commission: partnerSalesReader.dudiCommission(selectedPartnerSales),
        net: partnerSalesReader.netProfit(selectedPartnerSales),
      })
      .then(() => {
        setPaymentSummaryModalVisibility(false);
        setSelectedPartnerSales(EMPTY_OBJECT);
        setPaginationDetails({ ...DEFAULT_PAGINATION_DETAILS });
      })
      .finally(() => {
        setPaymentProcessing(false);
      });
  };

  const handleReloadSales = () => {
    setPaginationDetails({
      ...paginationDetails,
    });
  };

  const handlePreviewPaymentSummary = (partnerSales) => {
    setPaymentSummaryModalVisibility(true);
    setSelectedPartnerSales(partnerSales);
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      ...DEFAULT_PAGINATION_DETAILS,
    });
  };

  const handleViewAllBookings = (partnerSales) => {
    const userId = partnerSalesReader.userId(partnerSales);
    navigate(`partner/${userId}`);
  };

  const columns = createColumns({
    onMakePayment: handlePreviewPaymentSummary,
    onViewAllBookings: handleViewAllBookings,
  });
  const pageHeaderProps = createPageHeaderProps();

  if (sales.length === 0) {
    return (
      <>
        <PageHeader {...pageHeaderProps} />
        <div className={styles.noresults}>
          <Title className={styles.message}>
            Congratulations!! You don't owe anything at the moment.
          </Title>
        </div>
      </>
    );
  }

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <div className={styles.sales}>
        <Table
          columns={columns}
          data={sales}
          loading={loading}
          showSearch
          showReload
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalSales,
          }}
          onPaginationChange={handlePaginationChange}
          onReload={handleReloadSales}
          onSearch={handleSearch}
        />
      </div>
      {isPaymentSummaryModalVisible && (
        <PartnerPaymentSummary
          visible={isPaymentSummaryModalVisible}
          sales={selectedPartnerSales}
          onOk={handleProcessPayment}
          onCancel={handleClosePaymentSummary}
          paymentProcessing={isPaymentProcessing}
        />
      )}
    </>
  );
}

export default SalesSummary;
