// Constants
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../../constants";

const DEFAULT_PAGINATION_DETAILS = {
  pageNumber: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
};

export { DEFAULT_PAGINATION_DETAILS };
