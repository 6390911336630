import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Lodash
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";

// Components
import { Transactions, PaymentSummary } from "..";
import { PageHeader } from "../../../../components/molecules";

// Readers
import { transactionReader } from "../../../../readers";

// Services
import { paymentsService } from "../../../../services";

// Helpers
import { getTransactionIds, createPageHeaderProps } from "./helpers";

// Constants
import { EMPTY_ARRAY } from "../../../../constants";

// Styles
import styles from "./partnerTransactions.module.scss";

// TODO: Add route util
function PartnerTransactions() {
  const { partnerId } = useParams();

  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [isPaymentSummaryModalVisible, setPaymentSummaryModalVisibility] =
    useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState(EMPTY_ARRAY);

  const handleClosePaymentSummary = () => {
    setPaymentSummaryModalVisibility(false);
  };

  const handleProcessPayment = ({ note, count, gross, commission, net }) => {
    const selectedTransactionIds = getTransactionIds(selectedTransactions);
    setPaymentProcessing(true);
    paymentsService
      .createPayment({
        transactionIds: selectedTransactionIds,
        note,
        count,
        gross,
        commission,
        net,
        partnerId,
      })
      .then(() => {
        setPaymentSummaryModalVisibility(false);
        setSelectedTransactions(EMPTY_ARRAY);
      })
      .finally(() => {
        setPaymentProcessing(false);
      });
  };

  const handleShowPaymentSummary = () => {
    setPaymentSummaryModalVisibility(true);
  };

  const handleRemoveTransaction = (transaction) => {
    const filteredTransactions = _filter(
      selectedTransactions,
      (selectedTransaction) =>
        transactionReader.id(selectedTransaction) !==
        transactionReader.id(transaction)
    );
    setSelectedTransactions(filteredTransactions);
  };

  const handleRemoveAllTransactions = () => {
    setSelectedTransactions(EMPTY_ARRAY);
  };

  const handleSelectTransaction = (transactions) => {
    setSelectedTransactions([...selectedTransactions, ...transactions]);
  };

  const isReviewPaymentButtonDisabled = _isEmpty(selectedTransactions);

  const pageHeaderProps = createPageHeaderProps({
    actionHandler: handleShowPaymentSummary,
    actionDisabled: isReviewPaymentButtonDisabled,
    actionLoading: isPaymentProcessing,
    partnerId,
  });

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <div className={styles.content}>
        <Transactions
          onSelect={handleSelectTransaction}
          onRemove={handleRemoveTransaction}
          onRemoveAll={handleRemoveAllTransactions}
          paymentProcessing={isPaymentProcessing}
          selectedTransactions={selectedTransactions}
          partnerId={partnerId}
        />
      </div>
      {isPaymentSummaryModalVisible && (
        <PaymentSummary
          visible={isPaymentSummaryModalVisible}
          transactions={selectedTransactions}
          onOk={handleProcessPayment}
          onCancel={handleClosePaymentSummary}
          paymentProcessing={isPaymentProcessing}
        />
      )}
    </>
  );
}

export default PartnerTransactions;
