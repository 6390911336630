import moment from "moment";

// Helpers
import { isPaymentReverted } from "../../../helpers/paymentStatus";

// Readers
import { paymentReader } from "../../../../../readers";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";

const getPaymentDate = (payment) => {
  const paymentDate = paymentReader.paymentDate(payment);
  const dateMoment = new moment(paymentDate);
  return dateMoment.format("LLL");
};

const getRevertedDate = (payment) => {
  if (!isPaymentReverted(payment)) {
    return "-";
  }

  const revertedDate = paymentReader.revertedDate(payment);
  const dateMoment = new moment(revertedDate);
  return dateMoment.format("LLL");
};

const getPaidByUserDetails = (payment) => {
  const paidBy = paymentReader.paidBy(payment) || EMPTY_OBJECT;
  const { first_name, last_name, email, profile_pic } = paidBy;

  return {
    name: `${first_name} ${last_name}`,
    email,
    profilePicture: profile_pic,
  };
};

const getRevertedByUserDetails = (payment) => {
  if (!isPaymentReverted(payment)) {
    return EMPTY_OBJECT;
  }
  const revertedBy = paymentReader.revertedBy(payment) || EMPTY_OBJECT;
  const { first_name, last_name, email, profile_pic } = revertedBy;

  return {
    name: `${first_name} ${last_name}`,
    email,
    profilePicture: profile_pic,
  };
};

export {
  getPaymentDate,
  getRevertedDate,
  getPaidByUserDetails,
  getRevertedByUserDetails,
};
