import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import Table from "../table";

// Helpers
import { addKeysToData, getSelectedRowKeys } from "./helpers";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

function CheckBoxTable({ data, key, onSelect, onSelectAll, selectedRows, ...restProps }) {
  const dataWithKeys = addKeysToData(data, key);
  const selectedRowKeys = getSelectedRowKeys(selectedRows, key);
  const rowSelectionProps = {
    type: "checkbox",
    onSelect,
    onSelectAll,
    selectedRowKeys
  };
  return (
    <Table
      data={dataWithKeys}
      rowSelectionProps={rowSelectionProps}
      {...restProps}
    />
  );
}

CheckBoxTable.propTypes = {
  data: PropTypes.array,
  key: PropTypes.string,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

CheckBoxTable.defaultProps = {
  data: EMPTY_ARRAY,
  key: "id",
  onSelect: _noop,
  onSelectAll: _noop,
};

export default CheckBoxTable;
