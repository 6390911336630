import moment from "moment";

// Components
import { Payee } from "../../../../../components/molecules";
import Status, {
  STATUS_TYPES,
} from "../../../../../components/molecules/status";

// Readers
import { paymentReader, businessAccountReader } from "../../../../../readers";

// Helpers
import { isPaymentReverted } from "../../../helpers/paymentStatus";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";

const createColumns = () => {
  return [
    {
      title: "Business / Freelancer",
      render: (_, paymentDetails) => {
        const business = paymentReader.businessUser(paymentDetails);
        const name = businessAccountReader.firstName(business) + " " + businessAccountReader.lastName(business);
        const email = businessAccountReader.email(business);
        const profilePictureUrl =
          businessAccountReader.profileImageUrl(business);
        return (
          <Payee email={email} name={name} profilePicture={profilePictureUrl} />
        );
      },
    },
    {
      title: "Transactions",
      render: (_, paymentDetails) => {
        return paymentReader.transactionsCount(paymentDetails);
      },
    },
    {
      title: "Gross Sale Amount",
      render: (_, paymentDetails) => {
        return paymentReader.grossSale(paymentDetails);
      },
    },
    {
      title: "Dudi Commision",
      render: (_, paymentDetails) => {
        return paymentReader.dudiCommission(paymentDetails);
      },
    },
    {
      title: "Net Amount Paid",
      render: (_, paymentDetails) => {
        return paymentReader.netPayment(paymentDetails);
      },
    },
    {
      title: "Payment Date / Reverted Date",
      render: (_, paymentDetails) => {
        const date = isPaymentReverted(paymentDetails)
          ? paymentReader.revertedDate(paymentDetails)
          : paymentReader.paymentDate(paymentDetails);
        const dateMoment = new moment(date);
        return dateMoment.format("LLL");
      },
    },
    {
      title: "Paid By / Reverted By",
      render: (_, paymentDetails) => {
        const user = isPaymentReverted(paymentDetails)
          ? paymentReader.revertedBy(paymentDetails)
          : paymentReader.paidBy(paymentDetails);
        const { email, profile_pic, first_name, last_name } =
          user || EMPTY_OBJECT;
        const name = `${first_name} ${last_name}`;
        return <Payee email={email} name={name} profilePicture={profile_pic} />;
      },
    },
    {
      title: "Status",
      render: (_, paymentDetails) => {
        const statusType = isPaymentReverted(paymentDetails)
          ? STATUS_TYPES.REVERTED
          : STATUS_TYPES.PAID;
        return <Status status={statusType} />;
      },
    },
  ];
};

export { createColumns };
