// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";
import { Switch } from 'antd';
import moment from "moment";
import { Tag } from 'antd';
import _map from "lodash/map";

// Styles
import styles from "../promotion.module.scss";

const createColumns = ({ onEdit, onDelete, onInActive, deleteLoading, showUseUser }) => {
  return [
    {
      title: "Code",
      dataIndex: 'code',
      sorter: true,
      render: (text, { code }) => {
        return code
      },
    },
    {
      title: "Value",
      sorter: true,
      dataIndex: 'value',
      render: (text, { value, valueType }) => {
        return `${value} ${valueType}`
      },
    },
    // {
    //   title: "Type",
    //   sorter: true,
    //   render: (text, { valueType }) => {
    //     return valueType
    //   },
    // },
    {
      title: "booking type",
      // sorter: true,
      className: "capitalize",
      render: (text, { bookingType }) => {
        return bookingType.length === 3 ?
          <Tag color="green">All</Tag>
          : _map(bookingType, (user) =>
            <Tag className="capitalize" color="magenta">{user}</Tag>
          )
      },
    },
    {
      title: "Used",
      sorter: true,
      dataIndex: 'used',
      render: (text, { used, _id }) => {
        return <Button
          disabled={used ? false : true}
          type={BUTTON_TYPES.DEFAULT}
          onClick={(event) => {
            event.stopPropagation();
            showUseUser(_id);
          }}
        >
          {used}
        </Button>
      },
    },
    {
      title: "Limit",
      sorter: true,
      dataIndex: 'limit',
      render: (text, { limit, limitation }) => {
        return limit
          ? <Tag className="capitalize" color="blue">{limit}</Tag>
          : <Tag className="capitalize" color="red">Unlimited</Tag>
      },
    },
    {
      title: "Multiple",
      sorter: true,
      dataIndex: 'userMultipleUsage',
      render: (text, { userMultipleUsage }) => {
        const status = userMultipleUsage
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} iconOnly={true} />;
      },
    },
    {
      title: "Added by",
      render: (text, { createdBy }) => {
        if (createdBy) return (createdBy.username);
        else return ("DUDI")
      },
    },
    {
      title: "Expire on",
      render: (text, { expiredAt }) => {
        return (
          moment(expiredAt).format("DD MMM YY, HH:mm")
        )
      },
    },
    {
      title: "Status",
      render: (text, { _id, is_active }) => {
        return <Switch
          checked={is_active}
          onClick={(bolean, event) => {
            event.stopPropagation();
            onInActive(_id, !is_active);
          }}
        />;
      },
    },
    {
      title: "Actions",
      render: (text, promotionDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              disabled={promotionDetails.used ? true : false}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(promotionDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            <Button
              loading={deleteLoading ? true : false}
              type={BUTTON_TYPES.LINK}
              disabled={promotionDetails.used ? true : false}
              onClick={(event) => {
                event.stopPropagation();
                onDelete(promotionDetails);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              {deleteLoading ? null : <DeleteOutlined />}
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
