import React, { useState } from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";
import _isEmpty from "lodash/isEmpty";

// Components
import { Input } from "antd";
import { Label } from "../../atoms/typography";

// Styles
import styles from "./searchBox.module.scss";

const { Search } = Input;

function SearchBox({
  defaultValue,
  placeholder,
  loading,
  enterButton,
  onSearch,
  label,
}) {
  const [searchText, setSearchText] = useState(defaultValue);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  return (
    <div className={styles.container}>
      {!_isEmpty(label) && <Label>{label}: </Label>}
      <Search
        value={searchText}
        onChange={handleSearchTextChange}
        onSearch={onSearch}
        placeholder={placeholder}
        loading={loading}
        enterButton={enterButton}
        className={styles.searchBox}
      />
    </div>
  );
}

SearchBox.propTypes = {
  defaultValue: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  enterButton: PropTypes.bool,
  label: PropTypes.string,
};

SearchBox.defaultProps = {
  defaultValue: "",
  onSearch: _noop,
  placeholder: "Search...",
  loading: false,
  enterButton: true,
  label: "",
};

export default SearchBox;
