import React from "react";
import PropTypes from "prop-types";
import { Input as AntdInput, InputNumber } from "antd";

// Constants
import SIZES from "./constants/input.sizes";
import TYPES from "./constants/input.types";

const { Password, TextArea } = AntdInput;

const Input = (props) => {
  const { type, label, className, size, placeholder, subText, ...restProps } = props;
  return (
    <div className={`inputContainer ${className}`}>
      {label ? <div className="label">{label}</div> : null}
      <AntdInput
        {...restProps}
        type={type}
        size={size}
        placeholder={placeholder}
        className="inputBox"
      />
      {subText
        ? <div className="subInputText">{subText}</div>
        : null}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

Input.defaultProps = {
  type: TYPES.TEXT,
  size: SIZES.MEDIUM,
  placeholder: "",
  label: "",
};

export default Input;

export { Input, Password, InputNumber, TextArea };
