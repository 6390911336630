import moment from "moment";

// Components
import { Payee } from "../../../components/molecules";
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import { Tag } from 'antd';
import { Switch } from 'antd';

// Readers
import { activityReader } from "../../../readers";

const createColumns = ({ onInActive }) => {
  return [
    {
      title: "User Detail",
      dataIndex: 'firstName',
      sorter: true,
      render: (text, { user_id }) => {
        const firstName = user_id.firstName;
        const lastName = user_id?.lastName || "";
        const email = user_id.email;
        const profilePictureUrl = user_id.profile_pic;
        return (
          <Payee email={email} name={firstName + " " + lastName} profilePicture={profilePictureUrl} />
        );
      },
    },
    {
      title: "Name",
      dataIndex: 'name',
      sorter: true,
      width: "160px",
      render: (_, activityDetails) => {
        return activityReader.activityName(activityDetails);
      },
    },
    {
      title: "Address",
      render: (_, activityDetails) => {
        return activityReader.address(activityDetails);
      },
    },
    {
      title: "Join",
      render: (_, { invitations }) => {
        return invitations.length || 0;
      },
    },
    {
      title: "Price",
      dataIndex: 'fees',
      sorter: true,
      render: (_, activityDetails) => {
        return activityDetails.fees > 0
          ? activityDetails.fees
          : <Tag className="capitalize" color="green">Free</Tag>
      },
    },
    {
      title: "Players",
      dataIndex: 'no_of_players',
      sorter: true,
      render: (_, activityDetails) => {
        return activityReader.no_of_players(activityDetails);
      },
    },
    {
      title: "Status",
      render: (_, activityDetails) => {
        return activityReader.status(activityDetails);
      },
    },
    {
      title: "Event Date",
      dataIndex: 'eventDate',
      sorter: true,
      render: (_, activityDetails) => {
        let eventDate = activityReader.eventDate(activityDetails);
        eventDate = moment(eventDate).format("MMM Do YY");
        return eventDate
      },
    },
    {
      title: "Event Status",
      width: "100px",
      render: (_, activityDetails) => {
        let status = STATUS_TYPES.INACTIVE
        let customText = "Past"
        let eventDate = activityReader.eventDate(activityDetails);
        let check = moment().isSameOrBefore(eventDate) || moment(eventDate).format("DD MM YYYY") == moment().format("DD MM YYYY")
        if (check) {
          status = STATUS_TYPES.ACTIVE
          customText = "Upcoming"
        }
        return <Status customText={customText} status={status} />;
      },
    },
    {
      title: "Active",
      render: (text, userDetails) => {
        const status = activityReader.is_active(userDetails)
        const activity_id = activityReader.id(userDetails)
        // const isDelete = activityReader.isDelete(userDetails)
        return <Switch
          // disabled={isDelete}
          checked={status}
          onClick={(bolean, event) => {
            event.stopPropagation();
            onInActive(activity_id, !status);
          }}
        />
      },
    },
  ];
};

export { createColumns };
