import React from "react";
import cx from "classnames";

const Heading = (props) => {
    const { className, title, sub } = props;
    const titleClassName = cx("headingContainer", className);
    return (
        <div className={titleClassName}>
            <div className="headingText">{title}</div>
            {sub ? <div className="subText">{sub}</div> : null}
        </div>
    );
};

export default Heading;
