import React from "react";
import { Routes, Route } from "react-router-dom";

// Components
import SecureRoutes from "./SecureRoutes";
import LoginPage from "../pages/login";

// Constants
import { MODULES } from "../constants/modules";
import ROUTES from "../constants/routes";

function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES[MODULES.LOGIN.id]} element={<LoginPage />} />
      <Route path="*" element={<SecureRoutes />} />
    </Routes>
  );
}

export default AppRoutes;
