import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

// Images
import DefaultUserImage from "../../../../assets/images/user.png";
import appleLogo from "../../../../assets/images/apple.png";
import googleLogo from "../../../../assets/images/google.png";
import facebookLogo from "../../../../assets/images/facebook.png";
import passwordLogo from "../../../../assets/images/password.png";
import Male from "../../../../assets/images/male.png";
import Female from "../../../../assets/images/female.png";

// Lodash
import _noop from "lodash/noop";
import _map from "lodash/map";

// Components
import { confirm } from "../../../../components/atoms/modal";
import Image from "../../../../components/atoms/image";
import Drawer from "../../../../components/atoms/drawer";
import Divider from "../../../../components/atoms/divider";
import { Title, Label } from "../../../../components/atoms/typography";
import { ExclamationCircleOutlined } from "../../../../components/atoms/icon";
import Status, { STATUS_TYPES } from "../../../../components/molecules/status";
import { Tag, Button } from 'antd';

// Services
import userService from "../../../../services/userService";

// Redux
import { removeUser } from "../../redux/usersSlice";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";

// Readers
import userReader from "../../../../readers/user";

// Styles
import styles from "./userDetails.module.scss";

function UserDetails({ visible, onClose, userDetails, updateUserDetails }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users?.items);

  useEffect(() => {
    setFullLoading(true)
  }, [onClose])

  const [loading, setloading] = useState(false);
  const [fullLoading, setFullLoading] = useState(true);

  const profileImageUrl = userReader.profileImageUrl(userDetails);
  const firstName = userReader.firstName(userDetails);
  const lastName = userReader.lastName(userDetails) || "";
  const userName = userReader.userName(userDetails);
  const userEmail = userReader.email(userDetails);
  const gender = userReader.gender(userDetails) || "Not Available";
  const dateOfBirth = userReader.dateOfBirth(userDetails);
  const contactNumber = userReader.contactNumber(userDetails) || "Not Available";
  const appVersionAndroid = userReader.appVersionAndroid(userDetails);
  const appVersionIOS = userReader.appVersionIOS(userDetails);
  const favorite_sports = userReader.favorite_sports(userDetails) || [];
  const firstVersion = userReader.firstVersion(userDetails);
  const role = userReader.role(userDetails);
  const comapnyName = userReader.comapnyName(userDetails);
  const comapnyId = userReader.comapnyId(userDetails);
  const bussinessAccount = userReader.bussiness_id(userDetails);
  const activityPercentage = userReader.activityPercentage(userDetails);
  const packagePercentage = userReader.packagePercentage(userDetails);
  const slotPercentage = userReader.slotPercentage(userDetails);
  const friends = userReader.friends(userDetails) || [];
  const blocked = userReader.blocked(userDetails) || [];
  const status = userReader.isActive(userDetails)
    ? STATUS_TYPES.ACTIVE
    : STATUS_TYPES.INACTIVE;

  const handleGetProfile = () => {
    userService
      .fetchUsersProfile(userDetails._id)
      .then((response) => {
        console.log(response, 'response')
        if (response) updateUserDetails(response)
        setFullLoading(false)
      })
      .catch((error) => {
        console.log(error, 'error');
        setFullLoading(false)
      })
  }

  const handleDelete = () => {
    setloading(true)
    confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      content: bussinessAccount
        ? `It will also delete Posts, Friend Requests, Activities, Activity Requests, Pitches, Calendars and Packages.`
        : `It will also delete Posts, Activities, Friend Requests, Activity Requests and Packages.`,
      onOk() {
        userService
          .deleteUser(userDetails._id)
          .then((response) => {
            if (response.success)
              dispatch(removeUser({ userId: userDetails._id, users }));
            onClose()
            setloading(false)
          })
          .catch((error) => {
            console.log(error, 'error');
            setloading(false)
          })
      },
      onCancel() {
        setloading(false)
      },
    });
  }

  return (
    <Drawer
      title="User Details"
      open={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>
        <div className={styles.loginMethod}>
          <Image
            preview={false}
            width={25}
            height={25}
            src={
              userDetails.google_id ? googleLogo :
                userDetails.apple_id ? appleLogo :
                  userDetails.facebook_id ? facebookLogo
                    : passwordLogo}
          />
        </div>
        <div className={styles.genderType}>
          <Image
            preview={false}
            width={25}
            height={25}
            src={
              gender == "Female" ? Female :
                gender == "Male" ? Male
                  : ""}
          />
        </div>
        <div className={styles.profileImage}>
          <Image
            width={160}
            height={160}
            src={profileImageUrl}
            fallback={DefaultUserImage}
          />
        </div>
        <div className={styles.profileName}>
          <Title>{firstName} {lastName}</Title>
          <Label className={styles.username}>{userName}</Label>
          <Label><Status status={status} /></Label>
        </div>
        <Divider>Details</Divider>
        <div className={styles.profileDetails}>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Email</Title>
            <Label>{userEmail}</Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Date of Birth</Title>
            <Label>{
              dateOfBirth
                ? moment(dateOfBirth).format("DD MMMM YYYY")
                : "Not Available"
            }
            </Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Contact</Title>
            <Label>{contactNumber}</Label>
          </div>

          {/* <div className={styles.detail}>
            <Title className={styles.detailTitle}>Role</Title>
            <Label>{role}</Label>
          </div> */}

          {favorite_sports?.length > 0 && favorite_sports[0]?.sport_id?.name ?
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>Favorite sports</Title>
              {_map(favorite_sports, (sport) =>
                <Tag className="mt-1" color="volcano">{sport?.sport_id?.name}</Tag>
              )}
            </div> : null}

          {comapnyId !== "628cb68485e08250d48eb912" && comapnyName ?
            < div className={styles.detail}>
              <Title className={styles.detailTitle}>Comapny Name</Title>
              <Label>{comapnyName}</Label>
            </div> : null}

          {friends?.length ?
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>Friends</Title>
              <Label>{friends.length}</Label>
            </div>
            : null}

          {blocked?.length ?
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>Block Users</Title>
              <Label>{blocked.length}</Label>
            </div>
            : null}

          {bussinessAccount ? <>
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>Activity Percentage</Title>
              <Label>
                {activityPercentage ? `${activityPercentage} %` : "Not Available"}
              </Label>
            </div>
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>Package Percentage</Title>
              <Label>
                {packagePercentage ? `${packagePercentage} %` : "Not Available"}
              </Label>
            </div>
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>Calendar Activity Percentage</Title>
              <Label>
                {slotPercentage ? `${slotPercentage} %` : "Not Available"}
              </Label>
            </div>
          </> : null}

          {appVersionAndroid || appVersionIOS ?
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>App Version</Title>
              {appVersionAndroid ? <><Label>Android: {appVersionAndroid}</Label><br></br></> : null}
              {appVersionIOS ? <Label>IOS: {appVersionIOS}</Label> : null}
            </div>
            : null}

          {firstVersion ?
            <div className={styles.detail}>
              <Title className={styles.detailTitle}>First App Version</Title>
              <Label>{firstVersion}</Label>
            </div>
            : null}
        </div>

        {fullLoading &&
          <div className="mt-2">
            <Button
              ghost
              onClick={handleGetProfile}
              loading={!fullLoading}
            >
              View full detail
            </Button>
          </div>}

        <Divider />

        <Button
          danger
          type="primary"
          onClick={handleDelete}
          loading={loading}
        // disabled={disabled}
        >
          Delete Profile
        </Button>
      </div>
    </Drawer >
  );
}

UserDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  userDetails: PropTypes.object,
};

UserDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  userDetails: EMPTY_OBJECT,
};

export default UserDetails;
