// Lodash
import _noop from "lodash/noop";

// Constants
import { PAGE_TITLE } from "../constants";
import { EMPTY_OBJECT } from "../../../../../constants";

const createPageHeaderProps = ({
  actionHandler = _noop,
  actionDisabled = false,
  actionLoading = false,
  partnerId
} = EMPTY_OBJECT) => {
  return {
    title: `${PAGE_TITLE} | ${partnerId}`,
    actions: [
      {
        title: "Review Payment",
        handler: actionHandler,
        disabled: actionDisabled,
        loading: actionLoading
      },
    ],
  };
};

export { createPageHeaderProps };
