import React, { useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

// Components
import { Input } from "../../atoms/input";
import GoogleMaps from "../googleMaps/GoogleMaps";

// Styles
import styles from "./googlePlacesAutoComplete.module.scss";

function GooglePlacesAutoComplete({
  lat,
  lng,
  place = "",
  onChange,
  type = "",
}) {
  const [address, setAddress] = React.useState(place);
  const [coordinates, setCoordinates] = React.useState({
    lat: lat,
    lng: lng,
  });

  useEffect(() => {
    setAddress(place);
    setCoordinates({ lat: lat, lng: lng });
  }, [lat, lng, place]);

  useEffect(() => {
    onChange({ address: address, coordinates: coordinates });
  }, [address, coordinates]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                id: "address-input",
                placeholder: "Type address",
              })}
            />

            <div className={styles.autocompleteDropdownContainer}>
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: "1rem",
                      fontWeight: "600",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: "1rem",
                    };

                const spread = {
                  ...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  }),
                };

                return (
                  <div {...spread} key={suggestion.id}>
                    <div>{suggestion.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <GoogleMaps latitude={coordinates.lat} longitude={coordinates.lng} />
    </div>
  );
}

export default GooglePlacesAutoComplete;
