// Constants
import { MODULES } from "../modules";
import { PERMISSIONS_TYPES } from "./permissionTypes";

const USERS_MODULE = {
  id: MODULES.USERS.id,
  name: MODULES.USERS.name,
  permissions: [
    PERMISSIONS_TYPES.CREATE,
    PERMISSIONS_TYPES.EDIT,
    PERMISSIONS_TYPES.VIEW,
    PERMISSIONS_TYPES.DELETE,
  ],
};

const VENUES_MODULE = {
  id: MODULES.VENUES.id,
  name: MODULES.VENUES.name,
  permissions: [
    PERMISSIONS_TYPES.CREATE,
    PERMISSIONS_TYPES.EDIT,
    PERMISSIONS_TYPES.VIEW,
    PERMISSIONS_TYPES.DELETE,
  ],
};

const SPORTS_MODULE = {
  id: MODULES.SPORTS.id,
  name: MODULES.SPORTS.name,
  permissions: [
    PERMISSIONS_TYPES.CREATE,
    PERMISSIONS_TYPES.EDIT,
    PERMISSIONS_TYPES.VIEW,
    PERMISSIONS_TYPES.DELETE,
  ],
};

const ROLES_MODULE = {
  id: MODULES.ROLES.id,
  name: MODULES.ROLES.name,
  permissions: [
    PERMISSIONS_TYPES.CREATE,
    PERMISSIONS_TYPES.EDIT,
    PERMISSIONS_TYPES.VIEW,
    PERMISSIONS_TYPES.DELETE,
  ],
};

const FREELANCERS_MODULE = {
  id: MODULES.FREELANCERS.id,
  name: MODULES.FREELANCERS.name,
  permissions: [
    PERMISSIONS_TYPES.CREATE,
    PERMISSIONS_TYPES.EDIT,
    PERMISSIONS_TYPES.VIEW,
    PERMISSIONS_TYPES.DELETE,
  ],
};

const BUSINESS_ACCOUNTS_MODULE = {
  id: MODULES.BUSINESS_ACCOUNTS.id,
  name: MODULES.BUSINESS_ACCOUNTS.name,
  permissions: [
    PERMISSIONS_TYPES.CREATE,
    PERMISSIONS_TYPES.EDIT,
    PERMISSIONS_TYPES.VIEW,
    PERMISSIONS_TYPES.DELETE,
  ],
};

const PERMISSIONS = [
  USERS_MODULE,
  ROLES_MODULE,
  SPORTS_MODULE,
  VENUES_MODULE,
  FREELANCERS_MODULE,
  BUSINESS_ACCOUNTS_MODULE,
];

export { PERMISSIONS };
