// Lodash
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";

const addKeyToData = (key) => (rowData) => ({
  ...rowData,
  key: rowData[key],
});

const addKeysToData = (data, key) => _map(data, addKeyToData(key));

const getSelectedRowKeys = (selectedRows, key) => {
  if (_isEmpty(selectedRows)) {
    return null;
  }
  return _map(selectedRows, (row) => row?.[key]);
};

export { addKeysToData, getSelectedRowKeys };
