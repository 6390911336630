const DASHBOARD = {
  id: "dashboard",
  name: "Dashboard",
};

const ROLES = {
  id: "roles",
  name: "Roles",
};

const USERS = {
  id: "users",
  name: "Users",
};

const FREELANCERS = {
  id: "freelancers",
  name: "Freelancers",
};

const VENUES = {
  id: "venue",
  name: "Venues",
};

const SPORTS = {
  id: "sports",
  name: "Sports",
};

const ADMINS = {
  id: "admins",
  name: "Admins",
};

const LOGIN = {
  id: "login",
  name: "Login",
};

const PROFILE = {
  id: "profile",
  name: "Profile",
};

const BUSINESS_ACCOUNTS = {
  id: "business",
  name: "Business Accounts",
};

const PAYMENTS = {
  id: "payments",
  name: "Payments",
};

const PAYMENTS_LIST = {
  id: "paymentsList",
  name: "Payments List",
};

const NEW_PAYMENT = {
  id: "newPayment",
  name: "New Payment",
};

const SALES = {
  id: "sales",
  name: "Sales",
};

const NOTIFICATION = {
  id: "notification",
  name: "Notification",
};

const COMPANIES = {
  id: "companies",
  name: "Companies",
};

const PROMOTION = {
  id: "promotion",
  name: "Promotion",
};

const POSTS = {
  id: "posts",
  name: "Posts",
};

const PACKAGES = {
  id: "packages",
  name: "Packages",
};

const CHATS = {
  id: "chats",
  name: "Chats",
};

const TRANSACTION = {
  id: "transaction",
  name: "Transaction",
};

const ACTIVITIES = {
  id: "activities",
  name: "Activities",
};

const CONFIGS = {
  id: "configuration",
  name: "Configuration",
};

const MODULES = {
  POSTS,
  PACKAGES,
  ROLES,
  USERS,
  FREELANCERS,
  VENUES,
  SPORTS,
  DASHBOARD,
  ADMINS,
  LOGIN,
  PROFILE,
  BUSINESS_ACCOUNTS,
  PAYMENTS,
  PAYMENTS_LIST,
  NEW_PAYMENT,
  SALES,
  NOTIFICATION,
  COMPANIES,
  PROMOTION,
  CHATS,
  TRANSACTION,
  ACTIVITIES,
  CONFIGS,
};

export { MODULES };
