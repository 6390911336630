// Lodash
import _isNil from "lodash/isNil";

// Components
import { Payee } from "../../../../../components/molecules";
import { vat_calu, roundUp } from "../../../../../utils/globle";
import Status, {
  STATUS_TYPES,
} from "../../../../../components/molecules/status";

// Readers
import {
  businessAccountReader,
  partnerSalesReader,
} from "../../../../../readers";

const createColumns = () => {
  return [
    {
      title: "Business / Freelancer",
      render: (_, partnerSales) => {
        const user = partnerSalesReader.user(partnerSales);
        const firstName = businessAccountReader.firstName(user);
        const lastName = businessAccountReader.lastName(user);
        const email = businessAccountReader.email(user);
        const profilePictureUrl = businessAccountReader.profileImageUrl(user);
        return (
          <Payee email={email} name={firstName + " " + lastName} profilePicture={profilePictureUrl} />
        );
      },
    },
    {
      title: "Packages",
      render: (_, partnerSales) => {
        return partnerSalesReader.packages(partnerSales);
      },
    },
    {
      title: "Packages Booking",
      render: (_, partnerSales) => {
        return partnerSalesReader.package_booking(partnerSales);
      },
    },
    {
      title: "Activities",
      render: (_, partnerSales) => {
        return partnerSalesReader.activities(partnerSales);
      },
    },
    {
      title: "Activities Booking",
      render: (_, partnerSales) => {
        return partnerSalesReader.activity_booking(partnerSales);
      },
    },
    {
      title: "Gross Sales",
      render: (_, partnerSales) => {
        return partnerSalesReader.grossSale(partnerSales);
      },
    },
    {
      title: "Dudi Commision",
      render: (_, partnerSales) => {
        return partnerSalesReader.dudiCommission(partnerSales);
      },
    },
    {
      title: "Net Profit",
      render: (_, partnerSales) => {
        return partnerSalesReader.netProfit(partnerSales);
      },
    },
    {
      title: "VAT",
      render: (_, partnerSales) => {
        let vatPrice = vat_calu(partnerSales?.cost_price)
        vatPrice = roundUp(vatPrice)
        return vatPrice;
      },
    },
    {
      title: "Total",
      render: (_, partnerSales) => {
        let vatPrice = vat_calu(partnerSales?.cost_price)
        vatPrice = roundUp(vatPrice)
        let Total = Number(partnerSales?.cost_price) + Number(vatPrice)
        return Total
      },
    },
    {
      title: "Status",
      render: (_, partnerSales) => {
        const business = partnerSalesReader.user(partnerSales);
        const status = businessAccountReader.isActive(business);
        const statusType =
          _isNil(status) || status
            ? STATUS_TYPES.ACTIVE
            : STATUS_TYPES.INACTIVE;
        return <Status status={statusType} />;
      },
    },
  ];
};

export { createColumns };
