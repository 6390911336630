// Constants
import { MODULES } from "./modules";

// TODO: Need to finalise on the routes aproach of using modules
const ROUTES = {
  [MODULES.LOGIN.id]: "/login",
  [MODULES.USERS.id]: "/users",
  [MODULES.SPORTS.id]: "/sports",
  [MODULES.ADMINS.id]: "/adminmanagement/admins",
  [MODULES.VENUES.id]: "/business/venues",
  [MODULES.FREELANCERS.id]: "business/freelancers",
  [MODULES.ROLES.id]: "/adminmanagement/roles",
  [MODULES.DASHBOARD.id]: "/dashboard",
  [MODULES.PROFILE.id]: "/profile",
  [MODULES.BUSINESS_ACCOUNTS.id]: "/business/accounts",
  [MODULES.PAYMENTS.id]: "/payments",
  [MODULES.PAYMENTS_LIST.id]: "/payments/list",
  [MODULES.NEW_PAYMENT.id]: "/payments/new",
  [MODULES.SALES.id]: "/sales",
  [MODULES.NOTIFICATION.id]: "/notification",
  [MODULES.COMPANIES.id]: "/companies",
  [MODULES.TRANSACTION.id]: "/transaction",
  [MODULES.PROMOTION.id]: "/promotion",
  [MODULES.CHATS.id]: "/chats",
  [MODULES.POSTS.id]: "/posts",
  [MODULES.PACKAGES.id]: "/packages",
  [MODULES.ACTIVITIES.id]: "/activities",
  [MODULES.CONFIGS.id]: "/configuration",
};

export default ROUTES;
