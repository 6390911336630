import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

// Lodash
import _noop from "lodash/noop";

// Components
import Drawer from "../../../../components/atoms/drawer";
import Form from "../../../../components/atoms/form";
import Input from "../../../../components/atoms/input";
import Button from "../../../../components/atoms/button";
import { toast } from "../../../../components/atoms/toaster";

// Reader
import companyReader from "../../../../readers/company";

// Styles
import styles from "./createCompanyForm.module.scss";
import companiesService from "../../../../services/companiesService";

const Option = { Select };

function CreateCompanyForm({
  visible,
  onClose,
  companyDetails,
  isEditMode,
  onReload,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const companyID = companyReader.id(companyDetails);
  const companyName = companyReader.name(companyDetails);
  const companyPhone = companyReader.phone(companyDetails);
  const companyEmail = companyReader.email(companyDetails);
  const companyAddress = companyReader.address(companyDetails);
  const companyEmploys = companyReader.no_of_employes(companyDetails);

  const status = companyReader.isActive(companyDetails);
  let title = "Create New Company";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    name: companyName || "",
    phone: companyPhone || "",
    email: companyEmail || "",
    address: companyAddress || "",
    no_of_employes: companyEmploys || "",
    is_active: status || true,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    setLoading(true);
    if (isEditMode) {
      onClose();
      companiesService
        .updateCompany(companyID, values)
        .then((response) => {
          if (response.success) {
            onReload();
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      companiesService
        .createCompany(values)
        .then((response) => {
          if (response.success) {
            toast.success("New Company Added Successfully!!");
            form.resetFields();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (isEditMode) {
    title = "Edit Company - " + defaultValues.name;
  }

  return (
    <Drawer title={title} open={visible} onClose={onClose}>
      <div className={styles.container}>
        <Form
          initialValues={defaultValues}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input company name!" }]}
          >
            <Input placeholder="Enter company name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
          >
            <Input placeholder="Enter company email" />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
          >
            <Input placeholder="Enter company address" />
          </Form.Item>
          <Form.Item
            label="Phome"
            name="phone"
          >
            <Input placeholder="Enter company phone" />
          </Form.Item>
          <Form.Item
            label="Number of employes"
            name="no_of_employes"
          >
            <Input placeholder="Enter company number of employes" />
          </Form.Item>
          
          <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="select status" className={styles.select}>
              <Option value={true} key="active">
                Active
              </Option>
              <Option value={false} key="inActive">
                InActive
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateCompanyForm.propTypes = {
  visible: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateCompanyForm.defaultProps = {
  visible: false,
  isEditMode: false,
  onClose: _noop,
};

export default CreateCompanyForm;
