// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";

// Readers
import subAdminReader from "../../../readers/subAdmin";

// Styles
import styles from "../subAdmins.module.scss";

const createColumns = ({ onEdit, onDelete }) => {
  return [
    {
      title: "Name",
      render: (text, subAdminDetails) => {
        return (
          subAdminReader.firstName(subAdminDetails) +
          " " +
          subAdminReader.lastName(subAdminDetails)
        );
      },
    },
    {
      title: "Email",
      render: (text, subAdminDetails) => {
        return subAdminReader.email(subAdminDetails);
      },
    },
    {
      title: "Role",
      render: (text, subAdminDetails) => {
        return subAdminReader.role(subAdminDetails);
      },
    },
    {
      title: "Status",
      render: (text, subAdminDetails) => {
        const status = subAdminReader.isActive(subAdminDetails)
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} />;
      },
    },
    {
      title: "Actions",
      render: (text, subAdminDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(subAdminDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            <Button
              disabled
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onDelete(subAdminDetails);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
