import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  all: EMPTY_ARRAY,
};

const sportsSlice = createSlice({
  name: "sports",
  initialState: INITIAL_STATE,
  reducers: {
    addSports: (state, action = {}) => {
      const { payload: sports = [] } = action;
      sports.sort((a, b) => a.name.localeCompare(b.name))
      state.items = sports;
    },
    addAllSports: (state, action = {}) => {
      const { payload: sports = [] } = action;
      state.all = sports;
    },
  },
});

const { addSports, addAllSports } = sportsSlice.actions;
const sportsReducer = sportsSlice.reducer;

export { addSports, addAllSports, sportsReducer };
export default sportsSlice;
