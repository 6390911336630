import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";

const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchInboxs = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/chat/all`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchChat = (conversationId) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/chat/messages/${conversationId}`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};


export default {
  fetchInboxs,
  fetchChat
};
