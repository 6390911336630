// Lodash
import _isNil from "lodash/isNil";
import _noop from "lodash/noop";

// Components
import { Button, BUTTON_TYPES } from "../../../../../components/atoms";
import { Payee } from "../../../../../components/molecules";
import Status, {
  STATUS_TYPES,
} from "../../../../../components/molecules/status";

// Readers
import {
  businessAccountReader,
  partnerSalesReader,
} from "../../../../../readers";

// Styles
import styles from "../unsettledSales.module.scss";

const createColumns = ({
  onMakePayment = _noop,
  onViewAllBookings = _noop,
}) => {
  return [
    {
      title: "Business / Freelancer",
      render: (_, partnerSales) => {
        const business = partnerSalesReader.user(partnerSales);
        const firstName = businessAccountReader.firstName(business);
        const lastName = businessAccountReader.lastName(business);
        const email = businessAccountReader.email(business);
        const profilePictureUrl =
          businessAccountReader.profileImageUrl(business);
        return (
          <Payee email={email} name={firstName + " " + lastName} profilePicture={profilePictureUrl} />
        );
      },
    },
    {
      title: "Packages",
      render: (_, partnerSales) => {
        return partnerSalesReader.packages(partnerSales);
      },
    },
    {
      title: "Packages Booking",
      render: (_, partnerSales) => {
        return partnerSalesReader.package_booking(partnerSales);
      },
    },
    {
      title: "Activities",
      render: (_, partnerSales) => {
        return partnerSalesReader.activities(partnerSales);
      },
    },
    {
      title: "Activities Booking",
      render: (_, partnerSales) => {
        return partnerSalesReader.activity_booking(partnerSales);
      },
    },
    {
      title: "Gross Sales",
      render: (_, partnerSales) => {
        return partnerSalesReader.grossSale(partnerSales);
      },
    },
    {
      title: "Dudi Commision",
      render: (_, partnerSales) => {
        return partnerSalesReader.dudiCommission(partnerSales);
      },
    },
    {
      title: "Net Amount Payable",
      render: (_, partnerSales) => {
        return partnerSalesReader.netProfit(partnerSales);
      },
    },
    {
      title: "Status",
      render: (_, partnerSales) => {
        const business = partnerSalesReader.user(partnerSales);
        const status = businessAccountReader.isActive(business);
        const statusType =
          _isNil(status) || status
            ? STATUS_TYPES.ACTIVE
            : STATUS_TYPES.INACTIVE;
        return <Status status={statusType} />;
      },
    },
    {
      title: "Actions",
      render: (text, partnerSales) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onMakePayment(partnerSales);
              }}
              className={styles.icon}
            >
              Make Payment
            </Button>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onViewAllBookings(partnerSales);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              View All Bookings
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
