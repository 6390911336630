import React, { useEffect, useState } from "react";
// import ApexCharts from 'apexcharts'
import Chart from "react-apexcharts";
import moment from "moment";

import Loader from "../../components/molecules/loader";
import dashboardService from "../../services/dashboardService";
import postsService from "../../services/postsService";

import { Row, Col, Select } from "antd";

// Images
import NoImage from "../../assets/images/noImage.jpeg";
import DefaultImage from "../../assets/images/user.png";

// Icons
import {
  Activities,
  BusinessUsers,
  Companies,
  FreelancersUsers,
  Packages,
  Pitches,
  Posts,
  Promotions,
  Sports,
  Users,
} from "../../assets/icons";

// Styles
import styles from "./dashboard.module.scss";
const { Option } = Select;



// const polarArea = {
//   options: {
//     chart: {
//       type: 'polarArea',
//     },
//     stroke: {
//       colors: ['#fff']
//     },
//     fill: {
//       opacity: 0.8
//     },
//     responsive: [{
//       breakpoint: 480,
//       options: {
//         chart: {
//           width: 500
//         },
//         legend: {
//           position: 'bottom'
//         }
//       }
//     }],

//     legend: {
//       show: true,
//       formatter: function (seriesName, opts) {
//         // console.log(opts.seriesIndex, '222')
//         // console.log(userRecord, '222')
//         return ["August", " - ", opts.w.globals.series[opts.seriesIndex]]
//       }
//     },
//   },
// }

function Dashboard() {

  const [data, setData] = useState({});
  const [allPosts, setAllPosts] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [TotalUserArray, setTotalUserArray] = useState([]);
  const [TotalUserMonths, setTotalUserMonths] = useState([]);

  const [userRecordMonths, setUserRecordMonths] = useState([]);
  const [userRecordGraph, setUserRecordGraph] = useState(null);

  const [topRecordSports, setTopRecordSports] = useState(null);
  const [topRecordPackages, setTopRecordPackages] = useState(null);
  const [topRecordUsers, setTopRecordUsers] = useState(null);
  const [statisticLoading, setStatisticLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [topLoadingSport, setTopLoadingSport] = useState(true);
  const [topLoadingPackages, setTopLoadingPackages] = useState(true);
  const [topLoadingUser, setTopLoadingUser] = useState(true);
  const [month, setMonths] = useState(6);
  const [role, setRole] = useState('user');


  useEffect(() => {
    getStatistics()
    let data = { month, role }
    getUserRecords(data)
    getTopRecordSport()
    getTopRecordPackages()
    getTopRecordUser()
    getPostsData()
  }, []);

  const getStatistics = () => {
    dashboardService
      .fetchDetails()
      .then((response) => {
        let temp1 = [""]
        let temp = [0]
        let totalUser = response?.totalUser || []

        totalUser.forEach((item, index) => {
          temp.push(item.users + temp[index])
          let date = `${item._id.substring(0, 2)}-19-${item._id.substring(3, 7)}`
          temp1.push(date)
        })
        temp1 = temp1.sort(function (a, b) {
          return new Date(a) - new Date(b);
        });

        setTotalUserMonths(temp1)
        setTotalUserArray(temp)
        setData(response)
        setStatisticLoading(false)
      })
  }

  const getUserRecords = (item) => {
    setLoading(true)
    dashboardService
      .fetchSixMonthUsers(item)
      .then((response) => {
        setLoading(false)
        let temp1 = []
        response?.userData?.forEach((item) => {
          let date = `${item._id.substring(0, 2)}-1-2023`
          temp1.push(moment(date).format("MMM"))
        })
        //   let dateMonth = `${item._id.substring(0, 2)}`
        //   let dateYear = `${item._id.substring(3, 7)}`
        //   temp1.push(moment(`${dateMonth}-01-${dateYear}`).format("MMM YY"))
        // })
        setUserRecordMonths(temp1)
        setUserRecordGraph(response?.forGraph)
      })
  }

  const getTopRecordSport = () => {
    setTopLoadingSport(true)
    dashboardService
      .fetchTopRecord({ sports: true })
      .then((response) => {
        if (response?.sports)
          setTopRecordSports(response?.sports)
        setTopLoadingSport(false)
      })
  }

  const getTopRecordPackages = () => {
    setTopLoadingPackages(true)
    dashboardService
      .fetchTopRecord({ packages: true })
      .then((response) => {
        console.log(response?.packages)
        if (response?.packages)
          setTopRecordPackages(response?.packages)
        setTopLoadingPackages(false)
      })
  }

  const getTopRecordUser = () => {
    setTopLoadingUser(true)
    dashboardService
      .fetchTopRecord({ users: true })
      .then((response) => {
        if (response?.users)
          setTopRecordUsers(response?.users)
        setTopLoadingUser(false)

      })
  }

  const getPostsData = () => {
    postsService
      .fetchPosts({
        pageNumber: 1,
        pageLimit: 10,
      })
      .then((response) => {
        setAllPosts(response.docs);
        setPostLoading(false)
      })
  }

  const selectUser = (item) => {
    setRole(item)
    let data = { month, role: item }
    getUserRecords(data)
  }

  const selectMonth = (item) => {
    setMonths(item)
    let data = { month: item, role }
    getUserRecords(data)
  }

  const TotalUsersOption = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 200,
        // type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      title: {
        text: undefined,
        align: 'left',
      },
      grid: {
        // row: {
        //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //   opacity: 0.5
        // },
        show: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
          <div class="Graphtooltip"> 
            <span>${TotalUserMonths[dataPointIndex]
              ? moment(TotalUserMonths[dataPointIndex]).format("MMMM")
              : ""} ${series[seriesIndex][dataPointIndex]}</span> 
          </div>
          `
        },
      }

    }
  };
  const chartOptions = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 200,
        // type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      title: {
        text: undefined,
        align: 'left',
      },
      grid: {
        // row: {
        //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //   opacity: 0.5
        // },
        show: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: userRecordMonths,

        // show: false,
        // labels: {
        //   show: false
        // },
        axisBorder: {
          show: false
        },
        // axisTicks: {
        //   show: false
        // }
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
          <div class="Graphtooltip"> 
            <span>${userRecordMonths[dataPointIndex]}: ${series[seriesIndex][dataPointIndex]} Users</span> 
          </div>
          `
        },
      }

    }
  };

  let others = data?.newUsers - (data?.androidUser + data?.iosUser)
  return (
    <div className={styles.pageContainer}>
      {/* /////////////////////////////////////FIRST SECTION */}
      <Row gutter={20}>
        <Col span={7}>
          <div className={styles.card}>
            <div className={styles.p_3}>
              <div className="headingText">Total Users</div>
              <p>You have <strong>{data?.newUsers}</strong> new user today<br />
                Android: <strong>{data?.androidUser}</strong> IOS: <strong>{data?.iosUser}</strong>
                {others ?
                  <>
                    {" "}  Others:{" "}<strong>{others}</strong>
                  </>
                  : null}
              </p>
              <h3 className={styles.profitNumber}>
                {statisticLoading ? <Loader className="ASC mt-2" /> :
                  data?.users + data?.businessUsers + data?.freelancersUsers
                }
              </h3>
            </div>
            <div className={styles.chartBoxUser}>
              <Chart
                style={{ marginLeft: -12, marginBottom: -30, width: "100%" }}
                options={TotalUsersOption?.options}
                series={[{
                  data: TotalUserArray,
                }]}
                width="108%"
                height="170"
                type="area"
              />
            </div>
          </div>
        </Col>
        <Col span={17}>
          <div className="card">
            <div className="row JCB mb-3">
              <div className="headingText">Statistics</div>
              {/* <p className="subText">Updated {moment().startOf('').fromNow()}</p> */}
            </div>
            {statisticLoading ? <Loader className="ASC mt-2" /> : <>
              <div className="row mb-3">
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#F4A9002e" }}>
                      <img src={Users} className={styles.iconImage} alt="Users" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.users}</div>
                      <p className="label">Users</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#7267f02e" }}>
                      <img src={FreelancersUsers} className={styles.iconImage} alt="Freelancers Users" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.freelancersUsers}</div>
                      <p className="label">Freelancers Users</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#CB32342e" }}>
                      <img src={BusinessUsers} className={styles.iconImage} alt="Business Users" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.businessUsers}</div>
                      <p className="label">Business Users</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#DE4C8A2e" }}>
                      <img src={Sports} className={styles.iconImage} alt="Sports" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.sports}</div>
                      <p className="label">Sports</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#256D7B2e" }}>
                      <img src={Companies} className={styles.iconImage} alt="Companies" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.companies}</div>
                      <p className="label">Companies</p>
                    </div>
                  </div>
                </Col>
              </div>
              <div className="row mb-2">
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#00fff22e" }}>
                      <img src={Posts} className={styles.iconImage} alt="Posts" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.posts}</div>
                      <p className="label">Posts</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#0800ff2e" }}>
                      <img src={Packages} className={styles.iconImage} alt="Packages" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.packages}</div>
                      <p className="label">Packages</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#00ff372e" }}>
                      <img src={Activities} className={styles.iconImage} alt="Activities Users" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.activities}</div>
                      <p className="label">Activities</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#ff48002e" }}>
                      <img src={Pitches} className={styles.iconImage} alt="Pitches" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.pitches}</div>
                      <p className="label">Pitches</p>
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="row AIC">
                    <div className={styles.icon} style={{ backgroundColor: "#006eff2e" }}>
                      <img src={Promotions} className={styles.iconImage} alt="Promotions" />
                    </div>
                    <div>
                      <div className={styles.numbText}>{data?.promotions}</div>
                      <p className="label">Promotions</p>
                    </div>
                  </div>
                </Col>
              </div>
            </>}
          </div>
        </Col>
      </Row>
      {/* /////////////////////////////////////END FIRST SECTION */}

      {/* /////////////////////////////////////SECOND SECTION */}
      <Row gutter={20}>
        {/* <Col span={7}>
          <div className="row">
            <Col span={12}>
              <div className={styles.card}>
                <div className={styles.p_3}>
                  <div className="headingText">Transactions</div>
                  <h2 className={styles.numbText}>{data?.transaction}</h2>
                </div>
                <div className={styles.chartBox}>
                  <Chart
                    style={{ marginLeft: -12, marginBottom: -30 }}
                    options={chartOptions?.options}
                    series={[{
                      // name: "Packages",
                      data: data?.transactionsList,
                    }]}
                    type="area"
                    width="106%"
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.card}>
                <div className={styles.p_3}>
                  <div className="headingText">Net Profit</div>
                  <h2 className={styles.numbText}>AED {data?.netProfit?.toFixed(0)}</h2>
                </div>
                <div className={styles.chartBox}>
                  <Chart
                    style={{ marginLeft: -12, marginBottom: -30 }}
                    options={chartOptions?.options}
                    series={[{
                      // name: "Packages",
                      data: data?.netProfitList,
                    }]}
                    type="bar"
                    width="102%"
                  />
                </div>
              </div>
            </Col>
          </div>
          <Col span={24}>
            <div className="card">
              {data?.newTransaction > 0
                ? <div className="headingText">Congratulations 🎉</div>
                : <div className="headingText">You dont have new Transaction</div>
              }
              <p>You have <strong>{data?.newTransaction}</strong> new Transactions this month</p>
              <h3 className={styles.profitNumber}>AED {data?.newTransactionProfit?.toFixed(0)}</h3>
            </div>
          </Col>
        </Col> */}
        <Col span={16}>
          <div className="card">
            <div className="headingText">Registered Users MoM: last {`${month}`}m </div>
            <div className="row">
              <div className={styles.selectionBoxLeft}>
                <Chart
                  style={{ width: "80%" }}
                  options={chartOptions?.options}
                  series={[{
                    data: userRecordGraph,
                  }]}
                  width="108%"
                  height="170"
                  type="area"
                />
              </div>

              <div className={styles.divider} />

              <div className={styles.selectionBox}>
                <center>
                  <Select
                    defaultValue={6}
                    style={{ width: 150 }}
                    onChange={(e) => selectMonth(e)} >
                    <Option value={6}>last 6 Months</Option>
                    <Option value={12}>last 12 Months</Option>
                    <Option value={24}>last 24 Months</Option>
                  </Select>

                  <Select
                    defaultValue="Users"
                    style={{ width: 150, marginTop: 12 }}
                    onChange={(e) => selectUser(e)} >
                    <Option value="user">Users</Option>
                    <Option value="venue">Bussiness</Option>
                    <Option value="freelance">freelancer</Option>
                  </Select>
                </center>

                <div className={styles.text1}>Total User</div>

                {loading ? <Loader className="ASC mt-2" /> :
                  <div className={styles.text2}>
                    {userRecordGraph?.reduce((sum, x) => sum + x)}
                  </div>}
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="card">
            <div className="headingText">Lastest Posts</div>
            {postLoading ? <Loader className="ASC mt-2" /> :
              <div className={styles.postContainer}>
                {allPosts.map((item) => (
                  item?.file &&
                  <div className={styles.postImgSize}>
                    <div className={styles.postImgBox}>
                      <img src={item?.file || NoImage} className={styles.postImg} alt="post" />
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        </Col>
      </Row>
      {/* /////////////////////////////////////END SECOND SECTION */}

      {/* /////////////////////////////////////THIRD SECTION */}
      <Row gutter={20}>
        <Col span={8}>
          <div className="card">
            <div className="headingText">Top Sport</div>
            {topLoadingSport ? <Loader className="ASC mt-2" /> :
              topRecordSports?.map((item, index) => (
                <div className={`row AIC ${index % 2 !== 0 ? "tabBarWhite" : "tabBarGray"}`}>
                  {/* <Col span={3}>
                    <img src={DefaultImage} className={styles.topImg} />
                  </Col> */}
                  <Col span={20} className={styles.topLabel}>
                    {item.name}
                  </Col>
                  <Col span={4} className={styles.topCount}>
                    {item.used}
                  </Col>
                </div>
              ))}
          </div>
        </Col>

        <Col span={8}>
          <div className="card">
            <div className="headingText">Top Packages</div>
            {topLoadingPackages ? <Loader className="ASC mt-2" /> :
              topRecordPackages?.map((item, index) => (
                <div className={`row AIC ${index % 2 !== 0 ? "tabBarWhite" : "tabBarGray"}`}>
                  <Col span={3}>
                    <img src={item?.photos[0] || DefaultImage} className={styles.topImg} alt={item.package_name} />
                  </Col>
                  <Col span={18} className={styles.topLabel}>
                    {item.package_name}
                  </Col>
                  <Col span={3} className={styles.topCount}>
                    {item.used}
                  </Col>
                </div>
              ))}
          </div>
        </Col>
        <Col span={8}>
          <div className="card">
            <div className="headingText">Top Users</div>
            {topLoadingUser ? <Loader className="ASC mt-2" /> :
              <div className={styles.postContainer}>
                {!topRecordUsers ? null : topRecordUsers.map((item) => (
                  <div className={styles.postImgSize}>
                    <div className={styles.postImgBox}>
                      <img src={item.profile_pic || DefaultImage} className={styles.postImg} alt={item.username} />
                      <div className={styles.postText}>
                        {item.username}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>


          {/* <div className="card">
            <div className="headingText">Top users</div>
            {userLoadingUse ? <Loader className="ASC mt-2" /> :
              topRecord?.users?.map((item) => (
                <div className="row AIC mt-2">
                  <Col span={3}>
                    <img src={item.profile_pic || DefaultImage} className={styles.topImg} />
                  </Col>
                  <Col span={18} className={styles.topLabel}>
                    {item.username}
                  </Col>
                </div>
              ))}
          </div> */}
        </Col>
      </Row>
      {/* /////////////////////////////////////END THIRD SECTION */}

    </div>
  );
}

export default Dashboard;
