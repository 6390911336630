import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import DefaultImage from "../../../assets/images/user.png";

import styles from "./left.module.scss";


function LeftMessage({
    data = {},
    isImage = false,
    showName = false,
    groupMessage,
}) {
    const [Limit, setLimit] = useState(400);
    const [messageLine, setmessageLine] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const onTextLayout = (e) => {
        setmessageLine(e.nativeEvent.lines.length);
    }

    useEffect(() => {
        if (data.text?.length > 400)
            setShowMore(true)
        else
            setShowMore(false)
    }, [])

    return (
        <div>
            <div className={styles.itemLeft}>
                {isImage && groupMessage &&
                    <img
                        src={data.creator?.profile_pic || DefaultImage}
                        style={{ marginRight: 8, width: 30, height: 30, borderRadius: 60 }}
                    />
                }
                {data.is_deleted ? (
                    <div className={`${styles.deleteTextMessage} ${styles.commonTextMessage}`}>
                        <p className={styles.msgtxtDelete}>Message is deleted= {data.text.substring(0, Limit)}</p>
                    </div>
                ) : (
                    <div className={`${styles.otherTextMessage} ${styles.commonTextMessage} ${styles.changeLine} ${isImage && !groupMessage ? styles.groupSpace : {}}`}>
                        <div style={{ alignSelf: "flex-start" }}>
                            {showName && groupMessage &&
                                <p className={styles.msgUsername}>
                                    {data.creator?.username}
                                </p>
                            }
                            <p
                                className={styles.msgtxt}
                                onTextLayout={(e) => onTextLayout(e)}
                            >
                                {data.text.substring(0, Limit)}
                                {showMore && data.text.length > Limit ? "..." : null}
                            </p>
                        </div>

                        <div className={styles.timeNshowmore}>
                            <p className={styles.time}>
                                {moment(data.createdAt).format('hh:mm a')}
                            </p>

                            {showMore ?
                                <p className={styles.showMore} onClick={() => [
                                    setLimit(1000),
                                    setShowMore(!showMore),
                                ]}>
                                    Show more
                                </p>
                                : data.text.length > 400 ?
                                    <p className={styles.showMore} onClick={() => [
                                        setLimit(400),
                                        setShowMore(!showMore),
                                    ]}>
                                        Show less
                                    </p> : null}
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
}

// const styles = StyleSheet.create({
//     time: {
//         fontSize: 10,
//         fontFamily: family.semiboldRajdhani,
//         color: colors.secondary,
//         marginLeft: 8,
//     },
//     showMore: {
//         fontSize: 12,
//         fontFamily: family.semiboldRajdhani,
//         color: colors.primary,
//         marginLeft: 8,
//     },
//     itemLeft: {
//         paddingVertical: 4,
//         alignSelf: 'flex-start',
//         flexDirection: "row"
//     },
//     otherTextMessage: {
//         backgroundColor: '#f9f9f9',
//     },
//     deleteTextMessage: {
//         backgroundColor: '#e1e1e1',
//     },
//     changeLine: {
//         flexDirection: 'column',
//     },
//     groupSpace: {
//         marginLeft: 42
//     },
//     commonTextMessage: {
//         flexDirection: 'row',
//         alignItems: 'flex-end',
//         maxWidth: '85%',
//         borderRadius: 8,
//         paddingVertical: 8,
//         paddingHorizontal: 12,
//         shadowColor: '#000',
//         shadowOffset: { width: 0, height: 1 },
//         shadowOpacity: 0.12,
//         shadowRadius: 8,
//         elevation: 3,
//     },
//     msgtxtDelete: {
//         fontFamily: family.regularRajdhani,
//         fontSize: size.label,
//         color: '#999999',
//     },
//     msgUsername: {
//         fontFamily: family.regularRajdhani,
//         fontSize: size.label,
//         color: colors.primary,
//     },
//     msgtxt: {
//         fontFamily: family.regularRajdhani,
//         fontSize: size.label,
//         color: '#242424',
//     },
// });

export default LeftMessage;
