import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Lodash
import _noop from "lodash/noop";

// Components
import { CheckBoxTable } from "../../../../components/organisms";

// Services
import { transactionsService } from "../../../../services";

// Helpers
import { createColumns } from "./helpers";

// Redux Actions
import {
  setTransactions,
  setLoading,
} from "../../../../redux/slices/transactionsSlice";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Styles
import styles from "./transactions.module.scss";

function Transactions({
  onSelect,
  onRemove,
  onRemoveAll,
  paymentProcessing,
  partnerId,
  selectedTransactions,
}) {
  const dispatch = useDispatch();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState(
    DEFAULT_PAGINATION_DETAILS
  );
  const [searchText, setSearchText] = useState();
  const transactions = useSelector((state) => state?.transactions?.items);
  const loading = useSelector((state) => state?.transactions?.loading);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    dispatch(setLoading(true));
    transactionsService
      .fetchUnsettledTransactions({ pageNumber, pageSize, partnerId, searchText })
      .then((transactionDetails = EMPTY_OBJECT) => {
        const transactions = transactionDetails.docs;
        setTotalTransactions(transactionDetails?.totalDocs);
        dispatch(setTransactions(transactions));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [paginationDetails]);

  useEffect(() => {
    if (!paymentProcessing) {
      setPaginationDetails({ ...DEFAULT_PAGINATION_DETAILS });
    }
  }, [paymentProcessing]);

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleReloadTransactions = () => {
    setPaginationDetails({
      ...paginationDetails,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      ...DEFAULT_PAGINATION_DETAILS,
    });
  };

  const handleSelectTransaction = (transaction, selected) => {
    if (selected) {
      onSelect([transaction]);
    } else {
      onRemove(transaction);
    }
  };

  const handleSelectAllTransactions = (selected, selectedRows) => {
    if (selected) {
      onSelect(selectedRows);
    } else {
      onRemoveAll();
    }
  };

  const columns = createColumns();

  return (
    <div className={styles.container}>
      <div className={styles.tableFilters}></div>
      <div className={styles.tableContent}>
        <CheckBoxTable
          columns={columns}
          data={transactions}
          loading={loading}
          searchText={searchText}
          showSearch
          showReload
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalTransactions,
          }}
          onPaginationChange={handlePaginationChange}
          onSelect={handleSelectTransaction}
          onSelectAll={handleSelectAllTransactions}
          selectedRows={selectedTransactions}
          onReload={handleReloadTransactions}
          onSearch={handleSearch}
        />
      </div>
    </div>
  );
}

Transactions.propTypes = {
  paymentProcessing: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  selectedTransactions: PropTypes.array,
  partnerId: PropTypes.string,
};

Transactions.defaultProps = {
  paymentProcessing: false,
  onSelect: _noop,
  onRemove: _noop,
  onRemoveAll: _noop,
  selectedTransactions: null,
  partnerId: null,
};
export default Transactions;
