import React, { useState, useEffect } from "react";
import { Switch } from 'antd';

// Components
import TransactionDetails from "./components/transactionDetails";
import PageHeader from "../../components/molecules/pageHeader";
// import CreateTransactionForm from "./components/createTransactionForm";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import transactionService from "../../services/transactionsService";


// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./transaction.module.scss";

function Transaction() {
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [selectedTransactionDetails, setSelectedTransactionDetails] = useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });

  const [paymentStatus, setpaymentStatus] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [transactions, setTransaction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);

  // const [transactionDetailsVisible, setTransactionDetailsVisibility] = useState(false);

  const getTransactionData = (pageNumber, pageSize, sortField, order, payment_status = paymentStatus) => {
    transactionService
      .fetchTransactions({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
        payment_status: payment_status ? "succeeded" : "pending"
      })
      .then((response) => {
        const transaction = listResponseReader.results(response);
        const totalTransaction = listResponseReader.totalResults(response);
        setTotalTransaction(totalTransaction);
        setTransaction(transaction)
        setLoading(false);
      })
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getTransactionData(pageNumber, pageSize);
  }, [paginationDetails]);

  // const handleOpenEditTransactionForm = (transactionDetails) => {
  //   setEditMode(true);
  //   setCreateTransactionFormVisibility(true);
  //   setSelectedTransactionDetails(transactionDetails);
  // };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleShowTransactionDetails = (transactionDetails) => {
    setSelectedTransactionDetails(transactionDetails);
    // setTransactionDetailsVisibility(true);
  };

  // const handleDeleteTransaction = (transaction) => {
  //   console.log("Deleting transaction", transaction);
  // };

  const columns = createColumns({
    // onEdit: handleOpenEditTransactionForm,
    // onDelete: handleDeleteTransaction,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  function onChange(pagination, filters, sorter, extra) {
    getTransactionData(pagination.current, DEFAULT_PAGE_SIZE, "name", sorter.order);
  }

  const changeListToUser = () => {
    setLoading(true);
    setpaymentStatus(!paymentStatus)
    const { pageNumber, pageSize } = paginationDetails;
    getTransactionData(pageNumber, pageSize, sortField, order, !paymentStatus);
  };


  return (
    <>
      <div className="container">
        <PageHeader
          title="Transactions"
          statistics={[
            {
              title: "Total Transactions",
              value: totalTransaction,
            },
          ]}
        />
        <div className="card">
          <div className={styles.paymentStatus}>
            <div className={styles.SwitchText}>Show Succeeded Transaction</div>
            <Switch
              checked={paymentStatus}
              onClick={(bolean, event) => {
                changeListToUser(event);
              }}
            />
          </div>

          <Table
            data={transactions}
            columns={columns}
            searchProps={{
              placeholder: "Search Transactions",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalTransaction,
            }}
            onChange={onChange}
            onRowClick={handleShowTransactionDetails}
            loading={loading}
            onReload={handleReload}
          />
        </div>
      </div>
      {/* <CreateTransactionForm
        visible={createTransactionFormVisible}
        onClose={handleCloseCreateTransactionForm}
        transactionDetails={selectedTransactionDetails}
        isEditMode={isEditMode}
        onReload={handleReload}
      /> */}
      {/* <TransactionDetails
        transactionDetails={selectedTransactionDetails}
        visible={transactionDetailsVisible}
        onClose={handleCloseTransactionDetails}
      /> */}
    </>
  );
}

export default Transaction;
