import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Button } from 'antd';
import { toast } from "react-toastify";
import { Menu, Dropdown } from "antd";

// Components
import { confirm } from "../../components/atoms/modal";
import UserDetails from "./components/userDetails";
import Table from "../../components/organisms/table";
import PageHeader from "../../components/molecules/pageHeader";
import { ExclamationCircleOutlined, SettingOutlined } from "../../components/atoms/icon";

// Services
import userService from "../../services/userService";

// Redux
import { addUsers, removeUser } from "./redux/usersSlice";
import { addActiveData } from "../users/redux/usersSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Helpers
import { createColumns } from "./helpers/columns";

// Readers
import userReader from "../../readers/user";

// Styles
import styles from "./users.module.scss";

function Users() {
  const navigate = useNavigate();

  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedUserDetails, setSelectedUserDetails] = useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);

  const [userDetailsVisible, setUserDetailsVisibility] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users?.items);
  const activeData = useSelector((state) => state?.users?.activeData);

  const getUsersData = (pageNumber, pageSize, sortField, order) => {
    setLoading(true);
    userService
      .fetchUsers({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const users = listResponseReader.results(response);
        const totalUsers = listResponseReader.totalResults(response);
        setTotalUsers(totalUsers);
        dispatch(addUsers(users));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addUsers(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getUsersData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);

  useEffect(() => {
    if (!activeData) getActiveRecords()
  }, [])

  const getActiveRecords = () => {
    userService.activeInActiveCounts()
      .then((response) => {
        dispatch(addActiveData(response))
      })
  }

  const handleShowUserDetails = (userDetails) => {
    setSelectedUserDetails(userDetails);
    setUserDetailsVisibility(true);
  };

  const handleCloseUserDetails = () => {
    isEditMode && setEditMode(false);
    setUserDetailsVisibility(false);
    setSelectedUserDetails(EMPTY_OBJECT);
  };

  const handleEditUserDetails = (userDetails) => {
    setSelectedUserDetails(userDetails);
    setEditMode(true);
    setUserDetailsVisibility(true);
  };

  const handleDeleteFakeUser = () => {
    let date = moment().subtract(1, 'day').format()
    confirm({
      title: "Are you sure you want to delete all user?",
      icon: <ExclamationCircleOutlined />,
      content: `From ${moment(date).format("DD MMM YYYY, hh:mm a")}`,
      onOk() {
        userService
          .deleteFakeUser({ date })
          .then((response) => {
            if (response.success) {
              toast.success(response.data);
            }
          })
          .catch((error) => {
            console.log(error, 'error');
          })
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleDeleteUser = (user) => {
    confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      content: `${userReader.firstName(user)} / @${userReader.userName(user)}`,
      onOk() {
        userService
          .deleteUser(user._id)
          .then((response) => {
            if (response.success)
              dispatch(removeUser({ userId: user._id, users }));
          })
          .catch((error) => {
            console.log(error, 'error');
          })
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleonPageChange = (url) => {
    navigate(url);
  };

  const handleOnInActive = (userId, status) => {
    if (!status) {
      confirm({
        title: "Are you sure you want to deactivate this user?",
        icon: <ExclamationCircleOutlined />,
        content: `All sessions will be closed for this user`,
        onOk() { activeUserAPI() },
        onCancel() { },
      });
    }
    else activeUserAPI()
    function activeUserAPI() {
      let newUser = [...users]
      var newArr = _.map(newUser, function (user) {
        return user._id === userId ? { ...user, is_active: status, } : user;
      })

      userService
        .updateInActiveStatus({ userId, status })
        .then((response) => {
          dispatch(addUsers(newArr));
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const columns = createColumns({
    onEdit: handleEditUserDetails,
    onDelete: handleDeleteUser,
    onPageChange: handleonPageChange,
    onInActive: handleOnInActive,
  });

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
    getActiveRecords()
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)

    getUsersData(
      pagination.current,
      DEFAULT_PAGE_SIZE,
      field,
      order
    );
  }

  const menu = (
    <Menu className={styles.dropdownMenu}>
      <Menu.Item key="profileFreelancer">
        <Button
          danger
          type="primary"
          onClick={handleDeleteFakeUser}
          loading={loading}
        // disabled={disabled}
        >
          Delete Fake Users
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="container">
      <PageHeader
        title="Users"
        statistics={[
          {
            title: "Total Users",
            value: totalUsers,
          },
          {
            title: "Total Active Users",
            value: activeData?.userActive,
          },
          {
            title: "Total Inactive Users",
            value: activeData?.userInActive,
          },
        ]}
      />
      <div className="card">
        <div className={styles.adminPromtionOnly}>
          <Dropdown overlay={menu}>
            <SettingOutlined />
          </Dropdown>
        </div>
        <Table
          data={users}
          columns={columns}
          searchProps={{
            placeholder: "Search Users",
          }}
          onPaginationChange={handlePaginationChange}
          onSearch={handleSearch}
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalUsers,
          }}
          onRowClick={handleShowUserDetails}
          loading={loading}
          onReload={handleReload}
          onChange={onChange}
        />
      </div>
      <UserDetails
        userDetails={selectedUserDetails}
        updateUserDetails={(data) => setSelectedUserDetails(data)}
        visible={userDetailsVisible}
        onClose={handleCloseUserDetails}
      />
    </div>
  );
}

export default Users;
