import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

// Components
import { confirm } from "../../components/atoms/modal";
import UserDetails from "../users/components/userDetails";
import CreateAccountForm from "./components/createAccountForm";
import Table from "../../components/organisms/table";
import PageHeader from "../../components/molecules/pageHeader";
import { ExclamationCircleOutlined } from "../../components/atoms/icon";

// Services
import accountService from "../../services/businessAccountsService";
import userService from "../../services/userService";
import sportsService from "../../services/sportsService";

// Redux
import { addAccounts } from "./redux/businessAccountsSlice";
import { addActiveData } from "../users/redux/usersSlice";
import { addAllSports } from "../sports/redux/sportsSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";
import { Business_URL } from "../../constants/services";

// Helpers
import { createColumns } from "./helpers/columns";

// Readers
import userReader from "../../readers/user";

// Styles
import styles from "./businessAccounts.module.scss";

function BusinessAccounts() {
  const navigate = useNavigate();
  const sportsList = useSelector((state) => state?.sports?.all);

  const [totalAccounts, setTotalAccounts] = useState(0);
  const [selectedAccountDetails, setSelectedAccountDetails] =
    useState(EMPTY_OBJECT);

  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);
  const [createAccountFormVisible, setCreateAccountFormVisibility] = useState(false);
  const [accountDetailsVisible, setAccountDetailsVisibility] = useState(false);

  const dispatch = useDispatch();
  const accounts = useSelector((state) => state?.businessAccounts?.items);
  const activeData = useSelector((state) => state?.users?.activeData);

  useEffect(() => {
    if (!activeData) getActiveRecords()
  }, [])

  useEffect(() => {
    if (sportsList.length === 0)
      sportsService
        .fetchAllSports()
        .then((response) => {
          dispatch(addAllSports(response));
        })
  }, []);


  const getActiveRecords = () => {
    userService.activeInActiveCounts()
      .then((response) => {
        dispatch(addActiveData(response))
      })
  }

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getAccountData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);

  const getAccountData = (pageNumber, pageSize, sortField, order) => {
    setLoading(true);
    accountService
      .fetchBusinessAccount({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const accounts = listResponseReader.results(response);
        const totalAccounts = listResponseReader.totalResults(response);
        setTotalAccounts(totalAccounts);
        dispatch(addAccounts(accounts));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addAccounts(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleShowAccountDetails = (userDetails) => {
    setSelectedAccountDetails(userDetails);
    setAccountDetailsVisibility(true);
  };

  const handleCloseAccountDetails = () => {
    isEditMode && setEditMode(false);
    setAccountDetailsVisibility(false);
    setSelectedAccountDetails(EMPTY_OBJECT);
  };

  const handleDeleteAccount = (user) => {
    confirm({
      title: "Are you sure you want to delete this account?",
      icon: <ExclamationCircleOutlined />,
      content: `${userReader.firstName(user)} / @${userReader.userName(user)}`,
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleOpenCreateAccountForm = (accountDetails) => {
    setEditMode(false);
    setCreateAccountFormVisibility(true);
    setSelectedAccountDetails(accountDetails);
  };

  const handleOpenEditAccountForm = (accountDetails) => {
    setEditMode(true);
    setSelectedAccountDetails(accountDetails);
    setCreateAccountFormVisibility(true);
  };

  const handleOnInActive = (userId, status) => {
    if (!status) {
      confirm({
        title: "Are you sure you want to deactivate this user?",
        icon: <ExclamationCircleOutlined />,
        content: `All sessions will be closed for this user`,
        onOk() { activeUserAPI() },
        onCancel() { },
      });
    }
    else activeUserAPI()
    function activeUserAPI() {
      let newaccounts = [...accounts]
      var newArr = _.map(newaccounts, function (account) {
        return account._id === userId ? { ...account, is_active: status, } : account;
      })

      userService
        .updateInActiveStatus({ userId, status })
        .then((response) => {
          dispatch(addAccounts(newArr));
        })
        .catch((error) => {
          console.log(error);
        })
    }
  };

  const handleOnOpenAccount = (data) => {
    accountService
      .oepnBusinessAccount({ userId: data._id })
      .then((response) => {
        window.open(`${Business_URL}/login?token=${response}`, "_blank");
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const handleonPageChange = (url) => {
    navigate(url);
  };

  const columns = createColumns({
    onEdit: handleOpenEditAccountForm,
    onDelete: handleDeleteAccount,
    onInActive: handleOnInActive,
    onOpenAccount: handleOnOpenAccount,
    onPageChange: handleonPageChange,
  });

  const handleCloseAccountForm = () => {
    setCreateAccountFormVisibility(false);
  };

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
    getActiveRecords()
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)

    getAccountData(
      pagination.current,
      DEFAULT_PAGE_SIZE,
      field,
      order
    );
  }

  return (
    <div className="container">
      <PageHeader
        title="Business Accounts"
        statistics={[
          {
            title: "Total Accounts",
            value: totalAccounts,
          },
          {
            title: "Total Active Accounts",
            value: activeData?.bussinessActive,
          },
          {
            title: "Total Inactive Accounts",
            value: activeData?.bussinessInActive,
          },
        ]}
        actions={[
          {
            title: "+ New Account",
            handler: handleOpenCreateAccountForm,
          },
        ]}
      />
      <div className="card">
        <Table
          data={accounts}
          columns={columns}
          searchProps={{
            placeholder: "Search Accounts",
          }}
          onPaginationChange={handlePaginationChange}
          onSearch={handleSearch}
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalAccounts,
          }}
          onRowClick={handleShowAccountDetails}
          loading={loading}
          onReload={handleReload}
          onChange={onChange}
        />
      </div>
      <CreateAccountForm
        visible={createAccountFormVisible}
        onClose={handleCloseAccountForm}
        accountDetails={selectedAccountDetails}
        isEditMode={isEditMode}
        onReload={handleReload}
        sportsList={sportsList}
      />
      <UserDetails
        userDetails={selectedAccountDetails}
        visible={accountDetailsVisible}
        onClose={handleCloseAccountDetails}
      />
    </div>
  );
}

export default BusinessAccounts;
