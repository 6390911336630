import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { BASE_URL } from "../constants/services";

// Utils
import { getDataFromResponse } from "../utils";

const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchDetails = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/dashboard/admin`
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchSixMonthUsers = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/dashboard/sixmonthusers/`
  });
  return axios
    .post(url, payload, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchTopRecord = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/dashboard/topuse`,
    query: payload,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

export default {
  fetchDetails,
  fetchSixMonthUsers,
  fetchTopRecord,
};
