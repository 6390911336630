import React, { useState, useEffect } from "react";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Loader from "../../components/molecules/loader";
import Divider from "../../components/atoms/divider";
// import RightMessage from "./components/RightMessage";
import LeftMessage from "./components/LeftMessage";

import { Row, Col, Tag } from "antd";
import _map from "lodash/map";
import moment from "moment";

// Styles
import styles from "./chats.module.scss";

// Images
import DefaultImage from "../../assets/images/user.png";
import chatService from "../../services/chatsService";

function Chats() {
  const [Inboxs, setInboxs] = useState([]);
  const [inboxId, setinboxId] = useState(null);
  const [inboxMembers, setinboxMembers] = useState([]);
  const [Chats, setChats] = useState([]);
  const [ChatsLoading, setChatsLoading] = useState(false);

  useEffect(() => {
    chatService
      .fetchInboxs()
      .then((response) => {
        // console.log(response, 'response')
        // setinboxMembers(null)
        setInboxs(response);
      })
      .catch((error) => {
        console.log("Error fetching roles", error);
        setInboxs([]);
      });
  }, []);

  const getChat = (id, obj) => {
    setinboxId(id)
    setinboxMembers(obj)
    setChatsLoading(true)
    chatService
      .fetchChat(id)
      .then((response) => {
        // console.log(response, 'response')
        setChatsLoading(false)
        setChats(response);
      })
      .catch((error) => {
        console.log("Error fetching roles", error);
        setChatsLoading(false)
        setChats([]);
      });
  }

  const renderInbox = ({ last_msg_text, members, _id, room_name, teamId, event }) => {
    let group = false
    return (
      <div
        className={`${styles.inboxBox}
      ${inboxId === _id ? styles.selectedInboxBox : null}`}
        onClick={() => { getChat(_id, members) }}
      >
        {room_name ?
          <div className={styles.groupChat}>
            <strong>{room_name}</strong>
            <br />
            {teamId?.team_name ? <span>{teamId?.team_name}</span> : null}
            {event?.name ? <span>{event?.name}</span> : null}
            <br />
            <span>Members: <strong>{members.length}</strong></span>

          </div>
          : <div className={styles.userBox}>
            {_map(members, ((user) => (
              <div>
                <img src={user?.id?.profile_pic || DefaultImage} className={styles.userImage} />
                <div className={styles.username}>{user.id.username}</div>
              </div>
            )))}
          </div>
        }
        <Tag color="magenta">{last_msg_text.substring(0, 25)}</Tag>

      </div>
    );
  }

  const renderChats = (item, index) => {
    let myMessage = String(item.creator?._id) !== String("state?.userId");
    let preMsgDate = Chats[index - 1]?.createdAt
    let groupeMsgId = Chats[index - 1]?.creator?._id

    let showDate = true
    let groupMessage = true

    if (String(groupeMsgId) == String(item.creator?._id)) {
      groupMessage = false
    }

    if (moment(preMsgDate).format("DD-MMM-YYYY") == moment(item.createdAt).format("DD-MMM-YYYY")) {
      showDate = false
    }

    if (index == 0) {
      showDate = true
    }

    return (
      <>
        {showDate &&
          <div key={index + Math.floor(Math.random() * 1000)}>
            <p className={styles.showTime}>{moment(item.createdAt).format("DD MMM YYYY")}</p>
          </div>
        }

        {myMessage &&
          <LeftMessage
            key={index}
            isImage
            showName
            groupMessage={groupMessage}
            data={item}
          />
        }

        {/* <RightMessage
          key={index}
          data={item}
          seenStatus={false}
        // onPress={() => this.setState({
        //   deleteModal: true,
        //   msgDeleteId: item._id,
        //   msgDeleteIndex: index,
        // })}
        /> */}

      </>
    )
  }

  return (
    <div className="container">
      <PageHeader
        title="Chats"
        statistics={[
          {
            title: "Total Chats",
            value: inboxMembers.length,
          }
        ]}
      />
      <div className="card">
        <Row>
          <Col span={5}>
            <div className={styles.left}>
              {_map(Inboxs, renderInbox)}
            </div>
          </Col>
          <Col span={1} className={styles.divider}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>

          <Col span={16}>
            <div>
              {ChatsLoading
                ? <Loader />
                : <>
                  <div className={styles.userBox}>
                    {_map(inboxMembers, ((user) => (
                      <div>
                        <img src={user?.id?.profile_pic || DefaultImage} className={styles.userImage} />
                        <div className={styles.username}>{user.id.username}</div>
                      </div>
                    )))}
                  </div>
                  <Divider style={{ marginTop: -8, marginBottom: 8 }} />
                  <div className={styles.right}>
                    {Chats.map((item, index) => (
                      renderChats(item, index)
                    ))}
                  </div>

                </>

              }
            </div>


          </Col>
        </Row>

      </div>



    </div >
  );
}

export default Chats;
