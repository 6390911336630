import React, { useEffect, useState } from "react";

// Components
import Form from "../../../../components/atoms/form";
import Input from "../../../../components/atoms/input";
import Button from "../../../../components/atoms/button";
import { toast } from "../../../../components/atoms/toaster";

// Reader
import adminReader from "../../../../readers/admin";

// Styles
import styles from "./editProfileForm.module.scss";
import userService from "../../../../services/userService";

function EditProfileForm({ profileDetails }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const firstName = adminReader.firstName(profileDetails);
  const lastName = adminReader.lastName(profileDetails);
  const email = adminReader.email(profileDetails);
  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 16,
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    first_name: firstName || "",
    last_name: lastName || "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    setLoading(true);
    userService
      .updateProfile(values)
      .then((response) => {
        if (response.success) {
          toast.success("Update Successful!!");
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <Form
        {...layout}
        initialValues={defaultValues}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="First Name"
          name="first_name"
          rules={[{ required: true, message: "Please input first name!" }]}
        >
          <Input placeholder="Enter first name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          rules={[{ required: true, message: "Please input last name!" }]}
        >
          <Input placeholder="Enter last name" />
        </Form.Item>
        <Form.Item
          label="Email"
          disabled
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <Input placeholder={email || ""} />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
          <Button
            label={"Save"}
            type="primary"
            htmlType="submit"
            loading={loading}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

EditProfileForm.propTypes = {};

EditProfileForm.defaultProps = {};

export default EditProfileForm;
