import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { PageHeader } from "../../components/molecules";
import { PartnerSales, SalesSummary } from "./components";

// Helpers
import { createPageHeaderProps } from "./helpers";

// Styles
import styles from "./sales.module.scss";

function Sales() {
  const navigate = useNavigate();

  const handleNewPayment = () => {
    navigate("/payments/unsettled");
  };

  const pageHeaderProps = createPageHeaderProps({
    actionHandler: handleNewPayment,
  });

  return (
    <div className={styles.pageContainer}>
      <PageHeader {...pageHeaderProps} />
      <SalesSummary />
      <PartnerSales />
    </div>
  );
}

export default Sales;
