import React from "react";
import PropTypes from "prop-types";
import Spin from "../../atoms/spin";

// Styles
import styles from "./loader.module.scss";

const Loader = ({ size, className }) => {
  return (
    <div className={`${styles.spinContainer} ${className}`}>
      <Spin size={size} className={styles.spin} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
};

Loader.defaultProps = {
  size: "small",
  className: undefined,
};

export default Loader;
