import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";

const headerToken = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchUsers = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order !== "descend" ? "desc" : order !== "ascend" ? "asc" : "",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/fastload`,
    query: payload,
  });
  return axios
    .get(url, headerToken)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchAllUsers = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/all`,
  });
  return axios
    .get(url, headerToken)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchUsersProfile = (userId) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/${userId}`,
  });
  return axios
    .get(url, headerToken)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};
const fetchMyProfile = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/my_details`,
  });
  return axios
    .get(url, headerToken)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updateProfile = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/update_profile`,
  });
  return axios
    .put(url, payload, headerToken)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updatePassword = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/change_password`,
  });
  return axios
    .put(url, payload, headerToken)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updateInActiveStatus = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/user/inactive`,
  });
  return axios
    .post(url, payload, headerToken)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const activeInActiveCounts = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/users/active_inactive`,
  });
  return axios
    .get(url, headerToken)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const deleteUser = (id) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/delete/removeuser/${id}`,
  });

  return axios
    .delete(url, headerToken)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const deleteFakeUser = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/delete/fakeusers`,
  });
  return axios
    .post(url, payload, headerToken)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};


export default {
  fetchUsers,
  fetchAllUsers,
  fetchUsersProfile,
  fetchMyProfile,
  updateProfile,
  updatePassword,
  updateInActiveStatus,
  activeInActiveCounts,
  deleteUser,
  deleteFakeUser,
};
