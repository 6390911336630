// Lodash
import _find from "lodash/find";
import _reduce from "lodash/reduce";
import _isEmpty from "lodash/isEmpty";

// Readers
import permissionTypeReader from "../../../../../readers/permissionType";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants/base.constants";

const createPermissionPayload =
  (permissionTypes) => (payload, rights, type) => {
    const permissionType =
      _find(
        permissionTypes,
        (permission) => permissionTypeReader.type(permission) === type
      ) || {};
    if (_isEmpty(permissionType)) {
      return payload;
    }
    return [
      ...payload,
      {
        rights,
        permission: permissionTypeReader.id(permissionType),
      },
    ];
  };

const createRolesAndPermissionsPayload = (formValues = EMPTY_OBJECT, permissionTypes) => {
  const { role, permissions: permissionFormValues } = formValues;
  const permissionsPayload = _reduce(
    permissionFormValues,
    createPermissionPayload(permissionTypes),
    []
  );
  return {
    role,
    permissions: permissionsPayload,
  };
};

export { createRolesAndPermissionsPayload };
