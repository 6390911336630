import React from "react";
import { Radio } from "antd";

const RadioButton = (props) => {
    const { label, className, items = [], ...restProps } = props;
    return (
        <div className={`radioContainer ${className}`}>
            {label ? <div className="label">{label}</div> : null}
            <Radio.Group
                buttonStyle="solid"
                className="radioBtn"
                {...restProps}
            >
                {items.map(e => (
                    <Radio.Button value={e.value}>{e.name}</Radio.Button>
                ))}
            </Radio.Group>
        </div>
    );
};


export default RadioButton;
export { RadioButton };
