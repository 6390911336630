import React from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { AsyncSelect } from "../../../../components/molecules";

// Services
import { businessAccountsService } from "../../../../services";

// Readers
import { businessAccountReader } from "../../../../readers";

// Styles
import styles from "./businessUserSelect.module.scss";

function BusinessUserSelect() {
  const createBusinessAccountOption = (businessAccount) => ({
    label: `${businessAccountReader.firstName(businessAccount)} ${businessAccountReader.lastName(businessAccount)}`,
    value: businessAccountReader.id(businessAccount),
  });

  const handleCreateOptions = (businessAccounts) =>
    _map(businessAccounts, createBusinessAccountOption);

  return (
    <AsyncSelect
      asyncService={businessAccountsService.fetchBusinessAccount}
      createOptions={handleCreateOptions}
      className={styles.select}
    />
  );
}

BusinessUserSelect.propTypes = {
  onChange: PropTypes.func,
};

BusinessUserSelect.defaultProps = {
  onChange: _noop,
};

export default BusinessUserSelect;
