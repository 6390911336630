// Lodash
import _map from "lodash/map";

// Readers
import { transactionReader } from "../../../../../readers";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";

const getTransactionIds = (transactions = EMPTY_ARRAY) =>
  _map(transactions, transactionReader.id);

export { getTransactionIds };
