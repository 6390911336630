import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import Image from "../../../../components/atoms/image";
import Drawer from "../../../../components/atoms/drawer";
import Divider from "../../../../components/atoms/divider";
import { Title, Label } from "../../../../components/atoms/typography";
import Status, { STATUS_TYPES } from "../../../../components/molecules/status";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";

// Readers
import sportReader from "../../../../readers/sport";

// Styles
import styles from "./sportDetails.module.scss";

// Images
import DefaultSportImage from "../../../../assets/images/sport.png";

function SportDetails({ visible, onClose, sportDetails }) {
  const profileImageUrl = sportReader.profileImageUrl(sportDetails);
  const sportName = sportReader.name(sportDetails);
  const addedBy =
    sportReader.firstName(sportDetails) +
    " " +
    sportReader.lastName(sportDetails);

  const status = sportReader.isActive(sportDetails)
    ? STATUS_TYPES.ACTIVE
    : STATUS_TYPES.INACTIVE;
  return (
    <Drawer
      title="Sport Details"
      open={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>
        <div className={styles.profileImage}>
          <Image
            width={160}
            height={160}
            src={profileImageUrl || ""}
            fallback={DefaultSportImage}
          />
        </div>
        <div className={styles.profileName}>
          <Title>{sportName}</Title> <Status status={status} />
        </div>
        <Divider>Details</Divider>
        <div className={styles.profileDetails}>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Added By</Title>
            <Label>{addedBy}</Label>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

SportDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sportDetails: PropTypes.object,
};

SportDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  sportDetails: EMPTY_OBJECT,
};

export default SportDetails;
