import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  loading: true,
  summary: EMPTY_OBJECT,
};

const salesSlice = createSlice({
  name: "sales",
  initialState: INITIAL_STATE,
  reducers: {
    setSales: (state, action = EMPTY_OBJECT) => {
      const { payload: sales } = action;
      state.items = sales;
    },
    setLoading: (state, action = EMPTY_OBJECT) => {
      const { payload: loading } = action;
      state.loading = loading;
    },
    setSalesSummary: (state, action = EMPTY_OBJECT) => {
      const { payload: salesSummary } = action;
      state.summary = salesSummary;
    },
  },
});

const { setSales, setLoading, setSalesSummary } = salesSlice.actions;
const salesReducer = salesSlice.reducer;

export { setSales, setLoading, setSalesSummary, salesReducer };
export default salesSlice;
