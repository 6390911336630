import CryptoJS from "crypto-js";

const getID = (response = []) =>
  response.map(item => {
    if (item.includes("@"))
      return item.substring(0, item.indexOf("@"))
    else return item
  })

const vat_calu = (val, vat = 5) => {
  const amount = Number(val);
  const per = vat
  let result = (amount * per) / 100
  return Number(result).toFixed(2);
}

const roundUp = (val) => {
  return Math.ceil(val * 100) / 100
}

const decryptData = (val) => {
  let bytes = CryptoJS.AES.decrypt(val, "DuDi#AwesomeWoW");
  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(originalText)
}

export { getID, vat_calu, roundUp, decryptData };

