import moment from "moment";

// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined, FolderOpenOutlined } from "../../../components/atoms/icon";
import { Switch } from 'antd';

// Readers
import businessAccountReader from "../../../readers/businessAccount";

// Styles
import styles from "../businessAccounts.module.scss";
import { businessAccountsReducer } from "../redux/businessAccountsSlice";

const createColumns = ({ onEdit, onDelete, onInActive, onOpenAccount, onPageChange }) => {
  return [
    {
      title: "Name",
      sorter: true,
      dataIndex: 'firstName',
      render: (text, businessAccountDetails) => {
        return `${businessAccountReader.firstName(
          businessAccountDetails
        )} ${businessAccountDetails.lastName ? businessAccountDetails.lastName : ""}`;
      },
    },
    {
      title: "Email",
      sorter: true,
      dataIndex: 'email',
      render: (text, businessAccountDetails) => {
        return businessAccountReader.email(businessAccountDetails);
      },
    },
    {
      title: "Manage Account",
      render: (text, businessAccountDetails) => {
        return <Button
          type={BUTTON_TYPES.DEFAULT}
          onClick={(event) => {
            event.stopPropagation();
            onOpenAccount(businessAccountDetails);
          }}
          className={`${styles.icon}`}
        >
          <FolderOpenOutlined />
        </Button>

      },
    },
    {
      title: "Posts",
      render: (text, businessAccountDetails) => {
        const count = businessAccountReader.postsCount(businessAccountDetails)
        const id = businessAccountReader.id(businessAccountDetails)
        return (
          <Button
            disabled={count ? false : true}
            type={BUTTON_TYPES.DEFAULT}
            onClick={(event) => {
              event.stopPropagation();
              onPageChange(`/posts/${id}`);
            }}
          >
            {count}
          </Button>
        );
      },
    },
    {
      title: "Last Use",
      sorter: true,
      dataIndex: 'lastOpen',
      render: (text, businessAccountDetails) => {
        const lastOpen = businessAccountReader.lastOpen(businessAccountDetails)
        return lastOpen ? moment(lastOpen).fromNow() : null;
      },
    },
    {
      title: "Version",
      render: (text, businessAccountDetails) => {
        const appVersionAndroid = businessAccountReader.appVersionAndroid(businessAccountDetails)
        const appVersionIOS = businessAccountReader.appVersionIOS(businessAccountDetails)
        return <>
          {appVersionAndroid ?
            <div className={styles.appVersion}>Android : <strong>{appVersionAndroid}</strong></div>
            : null}
          {appVersionIOS ?
            <div className={styles.appVersion}>IOS : <strong>{appVersionIOS}</strong></div>
            : null
          }
        </>
      },
    },
    {
      title: "Status",
      render: (text, businessAccountDetails) => {
        const status = businessAccountReader.isActive(businessAccountDetails)
        const user_id = businessAccountReader.id(businessAccountDetails)
        return <Switch
          checked={status}
          onClick={(bolean, event) => {
            event.stopPropagation();
            onInActive(user_id, !status);
          }}
        />
      },
    },
    {
      title: "Actions",
      render: (text, businessAccountDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(businessAccountDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            <Button
              disabled
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onDelete(businessAccountDetails);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
