import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Lodash
import _get from "lodash/get";

// Components
import LoginForm from "./components/loginForm";
import { Title } from "../../components/atoms/typography";
import Logo from "../../assets/images/logo.png";
import { toast } from "../../components/atoms/toaster";

// Redux
import { addUser } from "./redux/loginSlice";

// Constants
import { EMPTY_OBJECT } from "../../constants/base.constants";

// Services
import authService from "../../services/authService";

// Styles
import styles from "./login.module.scss";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signingIn, setIsSigningIn] = useState(false);


  // useEffect(() => {
  //   const token = searchParams.get("token")
  //   // const saveToken = localStorage.getItem("token");
  //   if (token) {
  //     localStorage.setItem("token", token);
  //     userService
  //       .fetchMyProfile()
  //       .then((response) => {
  //         dispatch(addUser(response));
  //         localStorage.setItem("signedIn", "true");
  //         localStorage.setItem("userID", response?._id);
  //         window.location.replace(`${window.location.origin}`)
  //         // navigate("/sales");
  //       })
  //       .catch((error) => {
  //         console.log("Error fetching roles", error);
  //       });
  //   }
  //   else {
  //     localStorage.removeItem("signedIn");
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("userID");
  //     localStorage.removeItem("profile_pic");
  //   }

  //   // const query = new URLSearchParams(window.location.search);
  //   // const token = query.get('token')
  //   // console.log(token, 'xxxxxxx')
  // }, [])

  const handleSubmit = ({ userEmail, userPassword }) => {
    setIsSigningIn(true);
    authService
      .login({ userEmail, userPassword })
      .then((response) => {
        const token = _get(response, "token");
        const userDetails = _get(response, "admin", EMPTY_OBJECT);
        dispatch(addUser(userDetails));
        localStorage.setItem("signedIn", "true");
        localStorage.setItem("token", token);
        window.location.replace(`${window.location.origin}`)
        // navigate("/dashboard");
      })
      .catch(() => {
        setIsSigningIn(false);
        toast.error("Login Failed: Invalid credentials");
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <img className={styles.logo} src={Logo}></img>
          <Title className={styles.titleText}>Sign in to your account</Title>
        </div>
        <div className={styles.loginForm}>
          <LoginForm onSubmit={handleSubmit} loading={signingIn} />
        </div>
      </div>
    </div>
  );
}

export default Login;
