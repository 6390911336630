const PERMISSIONS_TYPES = {
  VIEW: {
    id: "read",
    name: "View",
  },
  EDIT: {
    id: "update",
    name: "Edit",
  },
  CREATE: {
    id: "create",
    name: "Create",
  },
  DELETE: {
    id: "delete",
    name: "Delete",
  },
};

export { PERMISSIONS_TYPES };
