import PropTypes from "prop-types";
import React, { useState } from "react";
import { DatePicker, TimePicker, Select, Space } from "antd";

const { Option } = Select;

function PickerWithType({ type, onChange, ...restProps }) {
  if (type === "time") return <TimePicker onChange={onChange} {...restProps} />;
  if (type === "date") return <DatePicker onChange={onChange} {...restProps} />;
  return <DatePicker picker={type} onChange={onChange} {...restProps} />;
}

function SwitchablePicker() {
  const [type, setType] = useState("time");
  return (
    <Space>
      <Select value={type} onChange={setType}>
        <Option value="time">Time</Option>
        <Option value="date">Date</Option>
        <Option value="week">Week</Option>
        <Option value="month">Month</Option>
        <Option value="quarter">Quarter</Option>
        <Option value="year">Year</Option>
      </Select>
    </Space>
  );
}

PickerWithType.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
};

PickerWithType.defaultProps = {};

export { PickerWithType, SwitchablePicker };
