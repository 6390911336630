import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Components
import { Menu, Dropdown, Tooltip } from "antd";
import { MessageOutlined, } from "@ant-design/icons";
import DefaultImage from "../../../assets/images/user.png";

// Icons
import Avatar from "../../atoms/avatar";

// Styles
import styles from "./navbar.module.scss";

function Navbar() {
  const user = useSelector((state) => state?.login?.user);
  const navigate = useNavigate();

  const handleSingOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  const menu = (
    <Menu className={styles.dropdownMenu}>
      <Menu.Item key="profileFreelancer">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item onClick={handleSingOut} key="signOut">
        Sign Out
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <div className={styles.boxcontainer}>
        <div className="row JCB AIC">

          <Tooltip title="Coming soon">
            <MessageOutlined />
          </Tooltip>

          <div className="row AIC">
            <div className={styles.userDetail}>
              <div className={styles.fullname}>{user.first_name} {user.last_name}</div>
              <div className={styles.username}>{user.email}</div>
            </div>

            <Dropdown overlay={menu}>
              <Avatar src={user?.profile_pic || DefaultImage} />
            </Dropdown>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Navbar;
