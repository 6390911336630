// Lodash
import _property from "lodash/property";

const user = _property("user");
const packages = _property("packages");
const bookings = _property("bookings");
const activities = _property("activities");
const grossSale = _property("cost_price");
const package_booking = _property("package_booking");
const activity_booking = _property("activity_booking");
const dudiCommission = _property("dudi_commission");
const netProfit = _property("net_profit");
const userId = _property("_id");

export default {
  user,
  packages,
  package_booking,
  bookings,
  grossSale,
  activities,
  activity_booking,
  dudiCommission,
  netProfit,
  userId
};
