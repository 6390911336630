import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  loading: true,
  selectedPayment: {
    loading: true,
    paymentDetails: EMPTY_OBJECT,
    transactions: {
      loading: true,
      items: EMPTY_ARRAY,
    },
  },
  selectedPaymentTransactions: {
    loading: true,
    items: EMPTY_ARRAY,
  },
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState: INITIAL_STATE,
  reducers: {
    setPayments: (state, action = EMPTY_OBJECT) => {
      const { payload: payments } = action;
      state.items = payments;
    },
    setLoading: (state, action = EMPTY_OBJECT) => {
      const { payload: loading } = action;
      state.loading = loading;
    },
    setSelectedPayment: (state, action = EMPTY_OBJECT) => {
      const { payload: paymentDetails } = action;
      state.selectedPayment = { paymentDetails, transactions: EMPTY_ARRAY };
    },
    setSelectedPaymentLoading: (state, action = EMPTY_OBJECT) => {
      const { payload: loading } = action;
      state.selectedPayment.loading = loading;
    },
    setSelectedPaymentTransactions: (state, action = EMPTY_OBJECT) => {
      const { payload: transactions } = action;
      state.selectedPaymentTransactions.items = transactions;
    },
    setSelectedPaymentTransactionsLoading: (state, action = EMPTY_OBJECT) => {
      const { payload: loading } = action;
      state.selectedPaymentTransactions.loading = loading;
    },
  },
});

const {
  setPayments,
  setLoading,
  setSelectedPayment,
  setSelectedPaymentLoading,
  setSelectedPaymentTransactions,
  setSelectedPaymentTransactionsLoading,
} = paymentsSlice.actions;
const paymentsReducer = paymentsSlice.reducer;

export {
  setPayments,
  setLoading,
  setSelectedPayment,
  setSelectedPaymentLoading,
  setSelectedPaymentTransactions,
  setSelectedPaymentTransactionsLoading,
  paymentsReducer,
};
export default paymentsSlice;
