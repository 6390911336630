import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";

const headerToken = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchBusinessAccount = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "descend" ? "desc" : order === "ascend" ? "asc" : "",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/bussiness`,
    query: payload,
  });
  return axios
    .get(url, headerToken)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const createBusinessAccount = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/bussiness`,
  });
  return axios
    .post(url, payload, headerToken)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response.data?.error, 'createBusinessAccount')
      toast.error(error.response.data?.error);
    });
};

const updateBusinessAccount = (id, payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/${id}`,
  });
  return axios
    .put(url, payload, headerToken)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const oepnBusinessAccount = ({ userId }) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/token/businesstool`,
  });
  return axios
    .post(url, { userId }, headerToken)
    .then((response) => {
      if (response.data.success)
        return response.data.data
      else toast.error(response.data.message);
    })
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

export default {
  fetchBusinessAccount,
  createBusinessAccount,
  updateBusinessAccount,
  oepnBusinessAccount,
};
