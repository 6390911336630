import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
  MAX_PAGE_SIZE,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";

const fetchRoles = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = MAX_PAGE_SIZE,
  searchField = "",
  sortField,
  order
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    searchField: searchField,
    sort_field: sortField,
    order_by: order === "descend" ? "desc" : order === "ascend" ? "asc" : "",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/roles`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const createRole = (payload) => {
  return axios
    .post(`${BASE_URL}/admin/roles`, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updateRole = (roleId, payload) => {
  return axios
    .put(`${BASE_URL}/admin/roles/${roleId}`, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchPermissions = () => {
  return axios
    .get(`${BASE_URL}/admin/permissions`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchRolesById = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  roleId = "",
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/admin/roles/${roleId}`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

export default {
  createRole,
  fetchRoles,
  fetchPermissions,
  fetchRolesById,
  updateRole,
};
