import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Switch, Divider } from "antd";

// Lodash
import _map from "lodash/map";
import _capitalize from "lodash/capitalize";

// Components
import PageHeader from "../../components/molecules/pageHeader";

// Styles
import styles from "./configurations.module.scss";

// Service
import subAdminsService from "../../services/subAdminsService";
import { Loader } from "../../components/molecules";


function Configurations() {
  const [loading, setLoading] = useState(true);
  const [facebook, setFacebook] = useState(false);
  const [google, setGoogle] = useState(false);
  const [apple, setApple] = useState(false);
  const [switchVersion, setSwitchVersion] = useState(false);

  useEffect(() => {
    getConfigation()
  }, []);


  // useEffect(() => {
  //   updateConfigation()
  // }, []);


  const getConfigation = () => {
    setLoading(true)
    subAdminsService
      .fetchConfigurations()
      .then((response) => {
        setLoading(false)
        setFacebook(response.facebook)
        setGoogle(response.google)
        setApple(response.apple)
        setSwitchVersion(response.switchVersion)
      })
  }
  const updateConfigation = (value) => {
    subAdminsService
      .UpdateConfigurations(value)
      .then((response) => {
        setFacebook(response.facebook)
        setGoogle(response.google)
        setApple(response.apple)
        setSwitchVersion(response.switchVersion)
      })
  }

  const changeConfigFacebook = (facebook) => {
    updateConfigation({
      facebook,
      google,
      apple,
      switchVersion,
    })
  }
  const changeConfigGoogle = (google) => {
    updateConfigation({
      facebook,
      google,
      apple,
      switchVersion,
    })
  }
  const changeConfigApple = (apple) => {
    updateConfigation({
      facebook,
      google,
      apple,
      switchVersion,
    })
  }
  const changeConfigSwitchVersion = (switchVersion) => {
    updateConfigation({
      facebook,
      google,
      apple,
      switchVersion,
    })
  }

  return (
    <>
      <div className="container">
        <PageHeader
          title="Configurations"
        />

        <div className="card">

          {loading ? <Loader /> : <Row gutter={20}>
            <Col span={5}>
              <div className={styles.SwitchText}>Show Facebook Login</div>
            </Col>
            <Col span={6}>
              <Switch
                checked={facebook}
                onClick={() => changeConfigFacebook(!facebook)}
              />
            </Col>
            <Divider />
            <Col span={5}>
              <div className={styles.SwitchText}>Show Google Login</div>
            </Col>
            <Col span={6}>
              <Switch
                checked={google}
                onClick={() => changeConfigGoogle(!google)}
              />
            </Col>
            <Divider />
            <Col span={5}>
              <div className={styles.SwitchText}>Show Apple Login</div>
            </Col>
            <Col span={6}>
              <Switch
                checked={apple}
                onClick={() => changeConfigApple(!apple)}
              />
            </Col>
            <Divider />
            <Col span={5}>
              <div className={styles.SwitchText}>Able to switch to dev</div>
            </Col>
            <Col span={6}>
              <Switch
                checked={switchVersion}
                onClick={() => changeConfigSwitchVersion(!switchVersion)}
              />
            </Col>
            <Divider />
          </Row>
          }


        </div>
      </div>
    </>
  );
}

export default Configurations;
