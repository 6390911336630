import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { Select } from "antd";

// Constants
import {
  EMPTY_ARRAY,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../constants";

// Readers
import { listResponseReader } from "../../../readers";

const { Option } = Select;

const renderOption = ({ label, value }) => (
  <Option key={value} value={value}>
    {label}
  </Option>
);

function AsyncSelect({
  asyncService,
  createOptions,
  defaultOptions,
  ...restProps
}) {
  const [options, setOptions] = useState(defaultOptions);
  const [searchText, setSearchText] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    asyncService({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      searchText: searchText,
    })
      .then((response) => {
        const results = listResponseReader.results(response);
        const newOptions = createOptions(results);
        setOptions(newOptions);
      })
      .catch(() => {
        setOptions(EMPTY_ARRAY);
      });
  }, [searchText]);

  const handleSearch = (newSearchText) => {
    setSearchText(newSearchText);
  };

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <Select
      showSearch
      value={value}
      defaultActiveFirstOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={"Record not found"}
      {...restProps}
    >
      {_map(options, renderOption)}
    </Select>
  );
}

AsyncSelect.propTypes = {
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  asyncService: PropTypes.func,
  createOptions: PropTypes.func,
  onChange: PropTypes.func,
  defaultOptions: PropTypes.array,
};

AsyncSelect.defaultProps = {
  // value: null,
  asyncService: _noop,
  createOptions: _noop,
  onChange: _noop,
  defaultOptions: EMPTY_ARRAY,
};

export default AsyncSelect;
