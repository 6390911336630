// Lodash
import _property from "lodash/property";

const businessUsers = _property("busineses_user_count");
const packages = _property("packages_count");
const activities = _property("event_count");
const packageBooking = _property("package_booking_count");
const eventBooking = _property("event_booking_count");
const grossSale = _property("gross_sale");
const dudiCommission = _property("dudi_commission");
const netProfit = _property("net_profit");

export default {
  businessUsers,
  packages,
  activities,
  packageBooking,
  eventBooking,
  grossSale,
  dudiCommission,
  netProfit,
};
