import PropTypes from "prop-types";
import { toast } from "react-toastify";
import randomWords from "random-words";
import React, { useState } from "react";

// Lodash
import _noop from "lodash/noop";

// Components
import { Modal, Input, TextArea, Label } from "../../../../components/atoms";

// Readers
import { partnerSalesReader } from "../../../../readers";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";
import { MODAL_TITLE, OK_BUTTON_TEXT } from "./constants";

// Styles
import styles from "./partnerPaymentSummary.module.scss";

function PaymentSummary({ sales, onOk, onCancel, visible, paymentProcessing }) {
  const randomGeneratedWords = randomWords({ exactly: 1, maxLength: 6 });
  const [randomWord, setRandomWord] = useState(randomGeneratedWords[0]);
  const [confirmationText, setConfirmationText] = useState();
  const [paymentNote, setPaymentNote] = useState();

  const handleConfirmationTextChange = (event) => {
    setConfirmationText(event.target.value);
  };

  const handleConfirmPayment = () => {
    if (confirmationText === randomWord) {
      onOk({ note: paymentNote });
    } else {
      toast.error("Confirmation text mismatch, Please try again");
      const newRandomWord = randomWords({ exactly: 1, maxLength: 6 });
      setRandomWord(newRandomWord);
    }
  };

  const handleNoteChange = (event) => {
    setPaymentNote(event.target.value);
  };

  return (
    <Modal
      title={MODAL_TITLE}
      okText={OK_BUTTON_TEXT}
      open={visible}
      onOk={handleConfirmPayment}
      onCancel={onCancel}
      centered
      okButtonProps={{
        disabled: confirmationText !== randomWord,
        loading: paymentProcessing,
      }}
    >
      <div className={styles.summary}>
        <div className={styles.paymentDetail}>
          <div className={styles.paymentDetailTitle}>Bookings</div>
          <div className={styles.paymentDetailContent}>
            {partnerSalesReader.package_booking(sales) + partnerSalesReader.activity_booking(sales)}
          </div>
        </div>
        <div className={styles.paymentDetail}>
          <div className={styles.paymentDetailTitle}>Gross Sale</div>
          <div className={styles.paymentDetailContent}>
            {partnerSalesReader.grossSale(sales)}
          </div>
        </div>
        <div className={styles.paymentDetail}>
          <div className={styles.paymentDetailTitle}>Dudi Commission</div>
          <div className={styles.paymentDetailContent}>
            {partnerSalesReader.dudiCommission(sales)}
          </div>
        </div>
        <div className={styles.paymentDetail}>
          <div className={styles.paymentDetailTitle}>Net Payable</div>
          <div className={styles.paymentDetailContent}>
            {partnerSalesReader.netProfit(sales)}
          </div>
        </div>
      </div>

      <TextArea
        allowClear
        onChange={handleNoteChange}
        value={paymentNote}
        className={styles.paymentNote}
        autoSize={{ minRows: 3 }}
        placeholder={"Please add a Note for this payment"}
      />
      <Label className={styles.confirmationLabel}>
        Match the below text to confirm the payment
      </Label>
      <div className={styles.confirmationDetails}>
        <div className={styles.randomWord}>{randomWord}</div>
        <Input
          value={confirmationText}
          onChange={handleConfirmationTextChange}
          className={styles.confirmationText}
        />
      </div>
    </Modal>
  );
}

PaymentSummary.propTypes = {
  paymentProcessing: PropTypes.bool,
  visible: PropTypes.bool,
  sales: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

PaymentSummary.defaultProps = {
  paymentProcessing: false,
  visible: false,
  transactions: EMPTY_OBJECT,
  onOk: _noop,
  onCancel: _noop,
};

export default PaymentSummary;
