// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";

// Readers
import venueReader from "../../../readers/venue";

// Styles
import styles from "../venues.module.scss";

const createColumns = ({ onEdit, onDelete }) => {
  return [
    {
      title: "Name",
      render: (text, venueDetails) => {
        return venueReader.name(venueDetails);
      },
    },
    {
      title: "Business User",
      render: (text, venueDetails) => {
        return `${venueReader.businessUser(venueDetails)?.user_id?.firstName} ${venueReader.businessUser(venueDetails)?.user_id?.lastName}`;
      },
    },
    {
      title: "Address",
      render: (text, venueDetails) => {
        return venueReader.address(venueDetails);
      },
    },
    {
      title: "Average Cost",
      render: (text, venueDetails) => {
        return venueReader.averageCost(venueDetails);
      },
    },
    {
      title: "Player Occupancy",
      render: (text, venueDetails) => {
        return venueReader.playersOccupy(venueDetails);
      },
    },
    {
      title: "Status",
      render: (text, venueDetails) => {
        const status = venueReader.isActive(venueDetails)
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} />;
      },
    },
    {
      title: "Actions",
      render: (text, venueDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(venueDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            <Button
              disabled
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onDelete(venueDetails);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
