import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const subAdminsSlice = createSlice({
  name: "subAdmins",
  initialState: INITIAL_STATE,
  reducers: {
    addSubAdmins: (state, action = {}) => {
      const { payload: subAdmins = [] } = action;
      state.items = subAdmins;
    },
  },
});

const { addSubAdmins } = subAdminsSlice.actions;
const subAdminsReducer = subAdminsSlice.reducer;

export { addSubAdmins, subAdminsReducer };
export default subAdminsSlice;
