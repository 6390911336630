import React from "react";
import PropTypes from "prop-types";
import { InputNumber as AntdInput, } from "antd";

// Constants
import SIZES from "./constants/input.sizes";
import TYPES from "./constants/input.types";

// Styles
import styles from "./input.module.scss";

const Input = (props) => {
  const { type, label, className, size, placeholder, inputStatus, ...restProps } = props;
  return (
    <div className={`${styles.inputContainer} ${className}`}>
      {label ? <div className="label">{label}</div> : null}
      <AntdInput
        {...restProps}
        type={type}
        size={size}
        placeholder={placeholder}
        className={`${styles.inputNumber} ${inputStatus === "error" ? styles.errorInput
          : inputStatus === "warning" ? styles.warningInput
            : inputStatus === "success" ? styles.successInput
              : null
          }`}
      />
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

Input.defaultProps = {
  type: TYPES.TEXT,
  size: SIZES.MEDIUM,
  placeholder: "",
  label: "",
};

export default Input;

