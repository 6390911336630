import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";

const createPayment = ({
  transactionIds,
  note,
  count,
  gross,
  commission,
  net,
  partnerId
}) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions/settle`,
  });

  const payload = {
    payment_ids: transactionIds,
    note,
    transactions_count: count,
    gross_sale: gross,
    dudi_commission: commission,
    net_payment: net,
    business_id: partnerId
  };

  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => response.data)
    .catch(() => {
      toast.error("Payment failed");
    });
};

const processPaymentByUser = ({
  partnerId,
  note,
  count,
  gross,
  commission,
  net,
}) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions/settle/${partnerId}`,
  });

  const payload = {
    note,
    transactions_count: count,
    gross_sale: gross,
    dudi_commission: commission,
    net_payment: net,
  };

  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => response.data)
    .catch(() => {
      toast.error("Payment failed");
    });
};

const fetchPayments = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
  };

  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/payments`,
    query: payload,
  });

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch payments");
    });
};

const fetchPaymentDetails = (paymentId) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/payments/${paymentId}`,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch payment details");
    });
};

const fetchPaymentTransactions = ({
  paymentId,
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/payments/${paymentId}/transactions`,
    payload,
  });

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch payment transactions");
    });
};

const revertPayment = (paymentId) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/payments/revert`,
  });
  const payload = {
    id: paymentId,
  };

  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => response.data)
    .catch(() => {
      toast.error("Failed to revert the payment");
    });
};

export default {
  createPayment,
  fetchPayments,
  fetchPaymentDetails,
  fetchPaymentTransactions,
  revertPayment,
  processPaymentByUser,
};
