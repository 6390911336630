import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import CompanyDetails from "./components/companyDetails";
import PageHeader from "../../components/molecules/pageHeader";
import CreateCompanyForm from "./components/createCompanyForm";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import companiesService from "../../services/companiesService";

// Redux
import { addCompanies } from "./redux/companiesSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./companies.module.scss";

function Companies() {
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [selectedCompanyDetails, setSelectedCompanyDetails] =
    useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });

  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [createCompanyFormVisible, setCreateCompanyFormVisibility] =
    useState(false);

  const [companyDetailsVisible, setCompanyDetailsVisibility] = useState(false);
  const dispatch = useDispatch();
  const companies = useSelector((state) => state?.companies?.items);
  const getCompaniesData = (pageNumber, pageSize, sortField, order) => {
    companiesService
      .fetchCompanies({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const companies = listResponseReader.results(response);
        const totalcompanies = listResponseReader.totalResults(response);
        setTotalCompanies(totalcompanies);
        dispatch(addCompanies(companies));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addCompanies(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getCompaniesData(pageNumber, pageSize);
  }, [paginationDetails]);

  const handleOpenCreateCompanyForm = (companyDetails) => {
    setEditMode(false);
    setCreateCompanyFormVisibility(true);
    setSelectedCompanyDetails(companyDetails);
  };

  const handleCloseCreateCompanyForm = () => {
    setCreateCompanyFormVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleCloseCompanyDetails = () => {
    isEditMode && setEditMode(false);
    setCompanyDetailsVisibility(false);
    setSelectedCompanyDetails(EMPTY_OBJECT);
  };

  const handleShowCompanyDetails = (companyDetails) => {
    setSelectedCompanyDetails(companyDetails);
    setCompanyDetailsVisibility(true);
  };

  const handleOpenEditCompanyForm = (companyDetails) => {
    setEditMode(true);
    setCreateCompanyFormVisibility(true);
    setSelectedCompanyDetails(companyDetails);
  };

  const handleDeleteCompany = (company) => {
    setDeleteLoading(true);
    companiesService
      .deleteCompany(company._id)
      .then((response) => {
        let removeData = companies.filter((val) => val._id !== response.data._id)
        dispatch(addCompanies(removeData));
        toast.success("Company is deleted");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const columns = createColumns({
    onEdit: handleOpenEditCompanyForm,
    onDelete: handleDeleteCompany,
    deleteLoading: deleteLoading,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  function onChange(pagination, filters, sorter, extra) {
    getCompaniesData(pagination.current, DEFAULT_PAGE_SIZE, "name", sorter.order);
  }

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Companies"
          statistics={[
            {
              title: "Total Companies",
              value: totalCompanies,
            },
          ]}
          actions={[
            {
              title: "+ New Company",
              handler: handleOpenCreateCompanyForm,
            },
          ]}
        />
        <div className={styles.pageContent}>
          <Table
            data={companies}
            columns={columns}
            searchProps={{
              placeholder: "Search Companies",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalCompanies,
            }}
            onChange={onChange}
            onRowClick={handleShowCompanyDetails}
            loading={loading}
            onReload={handleReload}
          />
        </div>
      </div>
      <CreateCompanyForm
        visible={createCompanyFormVisible}
        onClose={handleCloseCreateCompanyForm}
        companyDetails={selectedCompanyDetails}
        isEditMode={isEditMode}
        onReload={handleReload}
      />
      <CompanyDetails
        companyDetails={selectedCompanyDetails}
        visible={companyDetailsVisible}
        onClose={handleCloseCompanyDetails}
      />
    </>
  );
}

export default Companies;
