import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  activeData: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState: INITIAL_STATE,
  reducers: {
    addUsers: (state, action = {}) => {
      const { payload: users = [] } = action;
      state.items = users;
    },
    removeUser: (state, action = {}) => {
      const { userId, users } = action.payload;
      let newUser = users.filter(user => user._id !== userId)
      state.items = newUser;
    },
    addActiveData: (state, action = {}) => {
      const { payload: data = {} } = action;
      state.activeData = data;
    },
  },
});

const { addUsers, addActiveData, removeUser, } = usersSlice.actions;
const usersReducer = usersSlice.reducer;

export { addUsers, addActiveData, removeUser, usersReducer };
export default usersSlice;
