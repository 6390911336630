import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Table } from "../../../../components/organisms";

// Services
import { paymentsService } from "../../../../services";

// Helpers
import { createColumns } from "./helpers";

// Redux Actions
import {
  setSelectedPaymentTransactions,
  setSelectedPaymentTransactionsLoading,
} from "../../../../redux/slices/paymentsSlice";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Styles
import styles from "./paymentTransactions.module.scss";

function PaymentTransactions({ paymentId }) {
  const dispatch = useDispatch();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState(
    DEFAULT_PAGINATION_DETAILS
  );

  const transactionDetails = useSelector(
    (state) => state?.payments?.selectedPaymentTransactions
  );
  const { loading, items: transactions } = transactionDetails;

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    dispatch(setSelectedPaymentTransactionsLoading(true));
    paymentsService
      .fetchPaymentTransactions({ paymentId, pageNumber, pageSize })
      .then((transactionDetails = EMPTY_OBJECT) => {
        const transactions = transactionDetails.docs;
        setTotalTransactions(transactionDetails?.totalDocs);
        dispatch(setSelectedPaymentTransactions(transactions));
      })
      .finally(() => {
        dispatch(setSelectedPaymentTransactionsLoading(false));
      });
  }, [paginationDetails]);

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const columns = createColumns();

  return (
    <div className={styles.container}>
      <div className={styles.tableFilters}></div>
      <div className={styles.tableContent}>
        <Table
          columns={columns}
          data={transactions}
          loading={loading}
          showSearch={false}
          showReload={false}
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalTransactions,
          }}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

PaymentTransactions.propTypes = {
  paymentId: PropTypes.string.isRequired,
};

PaymentTransactions.defaultProps = {};

export default PaymentTransactions;
