import React, { useState, useEffect } from "react";
import moment from "moment";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { createColumns } from "./columns";
import Table from "../../../components/organisms/table";
import { Title, Label } from "../../../components/atoms/typography";
import { Modal, Row, Col, Button } from "antd";

// Services
import promotionService from "../../../services/promotionService";

// Styles
import styles from "./promotionUsedUsers.module.scss";

function PromotionUsedUsers({ visible, onClose, selectedPromotionId }) {

  const [Packages, setPackages] = useState("0");
  const [Events, setEvents] = useState("0");
  const [Slots, setSlots] = useState("0");
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true)
    setList([])
    if (selectedPromotionId)
      promotionService
        .checkUsedUser(selectedPromotionId)
        .then((response) => {
          let packag = []
          let activity = []
          let slot = []
          response.data.forEach(item => {
            if (item?.package_details) packag.push(item)
            if (item?.event_details) activity.push(item)
            if (item?.slot_details) slot.push(item)
          });
          setPackages(String(packag.length))
          setEvents(String(activity.length))
          setSlots(String(slot.length))


          setList(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
  }, [selectedPromotionId]);

  const columns = createColumns();

  return (
    <Modal
      title={"User list of use code"}
      style={{ top: 20 }}
      // centered
      className={styles.modal}
      destroyOnClose
      onCancel={onClose}
      width={1200}
      // onOk={viewOnly ? onClose : handleOk}
      open={visible}
      // okText={viewOnly ? "okay" : "Save"}
      // cancelText="取消"
      footer={[
        <Button type="secondry" onClick={onClose}>Cancel</Button>,
      ]}
    >
      <div className={styles.promotionContainer}>

        <Row className={styles.purchaseAnalytics}>
          <Col className={styles.promotionDetail}>
            <Title className={styles.detailTitle}>Total Bookings</Title>
            <Label className={styles.detailValue}>{list?.length}</Label>
          </Col>
          <Col className={styles.promotionDetail}>
            <Title className={styles.detailTitle}>Total Packages</Title>
            <Label className={styles.detailValue}>
              {Packages}
            </Label>
          </Col>
          <Col className={styles.promotionDetail}>
            <Title className={styles.detailTitle}>Total Activities</Title>
            <Label className={styles.detailValue}>
              {Events}
            </Label>
          </Col>

          <Col className={styles.promotionDetail}>
            <Title className={styles.detailTitle}>Total Calendar Acivities</Title>
            <Label className={styles.detailValue}>
              {Slots}
            </Label>
          </Col>
        </Row>

        <Table
          data={list}
          columns={columns}
          loading={loading}
          showSearch={false}
          showReload={false}
        />
      </div>
    </Modal>
  );
}

export default PromotionUsedUsers;
