import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Utils
import { getDataFromResponse } from "../utils";

// Helpers
import { createHeaders } from "../helpers";

// Constants
import { BASE_URL } from "../constants/services";

const fetchTransactions = ({ pageNumber, pageSize, searchText, payment_status = "succeeded" }) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    searchText,
    payment_status,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: createHeaders(),
    })
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      console.log(e.response.data, 'e')
      toast.error("Failed to fetch transactions");
    });
};

const fetchUnsettledTransactions = ({
  pageNumber,
  pageSize,
  partnerId,
  searchText,
}) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    settled: false,
    business_details: partnerId,
    search_text: searchText,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: createHeaders(),
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch transactions");
    });
};

export default {
  fetchTransactions,
  fetchUnsettledTransactions,
};
