import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Constants
import STATUS_TYPES from "./constants/statusTypes";
import { STATUS_TYPE_VS_LABEL } from "./constants/statusMappers";

// Styles
import styles from "./status.module.scss";

function Status({ status, iconOnly = false, customText = '' }) {
  const iconClassName = cx(styles.icon, {
    [styles.active]: status === STATUS_TYPES.ACTIVE || status === STATUS_TYPES.PAID,
    [styles.inactive]: status === STATUS_TYPES.INACTIVE,
    [styles.deleted]: status === STATUS_TYPES.DELETED || status === STATUS_TYPES.DUE,
  });
  if (customText)
    return (
      <div className={styles.container}>
        <div className={iconClassName}></div>
        {!iconOnly && (
          <div className={styles.label}>{customText}</div>
        )}
      </div>
    );
  else
    return (
      <div className={styles.container}>
        <div className={iconClassName}></div>
        {!iconOnly && (
          <div className={styles.label}>{STATUS_TYPE_VS_LABEL[status]}</div>
        )}
      </div>
    );
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
