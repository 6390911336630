import { createSlice } from "@reduxjs/toolkit";

// Lodash
import _findIndex from "lodash/findIndex";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  permissionTypes: EMPTY_ARRAY,
};

const roleSlice = createSlice({
  name: "roles",
  initialState: INITIAL_STATE,
  reducers: {
    addRole: (state, action = {}) => {
      const { payload: role } = action;
      state.items.push({
        ...role,
        id: role._id,
      });
    },
    setRoles: (state, action = {}) => {
      const { payload: roles } = action;
      state.items = roles;
    },
    setPermissionTypes: (state, action = {}) => {
      const { payload: permissionTypes } = action;
      state.permissionTypes = permissionTypes;
    },
    updatePermissions: (state, action = {}) => {
      const { payload = {} } = action;
      const { role, _id } = payload;
      const matchingRoleIndex = _findIndex(
        state.items,
        (item) => item.role === role
      );
      state.items[matchingRoleIndex] = { ...payload, id: _id };
    },
  },
});

const { addRole, setRoles, setPermissionTypes, updatePermissions } =
  roleSlice.actions;
const rolesReducer = roleSlice.reducer;

export {
  addRole,
  setRoles,
  setPermissionTypes,
  updatePermissions,
  rolesReducer,
};
export default roleSlice;
