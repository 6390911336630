import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import Image from "../../../../components/atoms/image";
import Drawer from "../../../../components/atoms/drawer";
import Divider from "../../../../components/atoms/divider";
import { Title, Label } from "../../../../components/atoms/typography";
import Status, { STATUS_TYPES } from "../../../../components/molecules/status";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";

// Readers
import subadminReader from "../../../../readers/subAdmin";

// Styles
import styles from "./subAdminDetails.module.scss";

// Images
import DefaultSubAdminImage from "../../../../assets/images/subadmin.png";

function SubAdminDetails({ visible, onClose, subAdminDetails }) {
  const firstName = subadminReader.firstName(subAdminDetails);
  const lastName = subadminReader.lastName(subAdminDetails);
  const email = subadminReader.email(subAdminDetails);
  const role = subadminReader.role(subAdminDetails);

  const status = subadminReader.isActive(subAdminDetails)
    ? STATUS_TYPES.ACTIVE
    : STATUS_TYPES.INACTIVE;
  return (
    <Drawer
      title="SubAdmin Details"
      open={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>
        <div className={styles.profileImage}>
          <Image
            width={160}
            height={160}
            src={""}
            fallback={DefaultSubAdminImage}
          />
        </div>
        <div className={styles.profileName}>
          <Title>{firstName + " " + lastName}</Title>
          <Status status={status} />
        </div>
        <Divider>Details</Divider>
        <div className={styles.profileDetails}>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Email</Title>
            <Label>{email}</Label>
          </div>
          <div className={styles.detail}>
            <Title className={styles.detailTitle}>Role</Title>
            <Label>{role}</Label>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

SubAdminDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  subAdminDetails: PropTypes.object,
};

SubAdminDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  subAdminDetails: EMPTY_OBJECT,
};

export default SubAdminDetails;
