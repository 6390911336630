import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { getDataFromResponse } from "../utils";


const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchPosts = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageLimit = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order,
  userid
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageLimit,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "descend" ? "desc" : order === "ascend" ? "asc" : "",
    userid
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/post/all`,
    query: payload,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const deletePost = (id) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/post/delete_post/${id}`,
  });
  return axios
    .delete(url, headers)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response?.data?.error);
    });
};



export default {
  fetchPosts,
  deletePost
};
