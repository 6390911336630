import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

// Components
import { confirm } from "../../components/atoms/modal";
import UserDetails from "../users/components/userDetails";
import CreateFreelancerForm from "./components/createFreelancerForm";
import Table from "../../components/organisms/table";
import PageHeader from "../../components/molecules/pageHeader";
import { ExclamationCircleOutlined } from "../../components/atoms/icon";

// Services
import accountService from "../../services/businessAccountsService";
import freelancerService from "../../services/freelancerService";
import sportsService from "../../services/sportsService";
import userService from "../../services/userService";


// Redux
import { addFreelancers } from "./redux/freelancersSlice";
import { addAllSports } from "../sports/redux/sportsSlice";
import { addActiveData } from "../users/redux/usersSlice";


// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";
import { Business_URL } from "../../constants/services";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Helpers
import { createColumns } from "./helpers/columns";

// Readers
import freelancerReader from "../../readers/freelancer";

// Styles
import styles from "./freelancers.module.scss";

function Freelancers() {
  const navigate = useNavigate();

  const sportsList = useSelector((state) => state?.sports?.all);
  const [totalFreelancers, setTotalFreelancers] = useState(0);
  const [selectedFreelancerDetails, setSelectedFreelancerDetails] =
    useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);

  const [createFreelancerFormVisible, setCreateFreelancerFormVisibility] =
    useState(false);
  const [freelancerDetailsVisible, setUserDetailsVisibility] = useState(false);
  const dispatch = useDispatch();
  const freelancers = useSelector((state) => state?.freelancers?.items);
  const activeData = useSelector((state) => state?.users?.activeData);

  const getFreelancersData = (pageNumber, pageSize, sortField, order) => {
    setLoading(true);
    freelancerService
      .fetchFreelancers({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const freelancers = listResponseReader.results(response);
        const totalFreelancers = listResponseReader.totalResults(response);
        setTotalFreelancers(totalFreelancers);
        dispatch(addFreelancers(freelancers));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addFreelancers(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getFreelancersData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);

  useEffect(() => {
    if (sportsList.length === 0)
      sportsService
        .fetchAllSports()
        .then((response) => {
          dispatch(addAllSports(response));
        })

    if (!activeData)
      getActiveRecords()
  }, []);


  const getActiveRecords = () => {
    userService.activeInActiveCounts()
      .then((response) => {
        dispatch(addActiveData(response));
      })
  }

  const handleShowUserDetails = (freelancerDetails) => {
    setSelectedFreelancerDetails(freelancerDetails);
    setUserDetailsVisibility(true);
  };

  const handleCloseFreelancerDetails = () => {
    isEditMode && setEditMode(false);
    setUserDetailsVisibility(false);
    setSelectedFreelancerDetails(EMPTY_OBJECT);
  };

  const handleOpenCreateFreelancerForm = (freelancerDetails) => {
    setEditMode(false);
    setCreateFreelancerFormVisibility(true);
    setSelectedFreelancerDetails(freelancerDetails);
  };

  const handleDeleteUser = (freelancer) => {
    confirm({
      title: "Are you sure you want to delete this freelancer?",
      icon: <ExclamationCircleOutlined />,
      content: `${freelancerReader.firstName(
        freelancer
      )} / @${freelancerReader.freelancerName(freelancer)}`,
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleOpenEditFreelancerForm = (freelancerDetails) => {
    setEditMode(true);
    setSelectedFreelancerDetails(freelancerDetails);
    setCreateFreelancerFormVisibility(true);
  };

  const handleOnInActive = (userId, status) => {
    if (!status) {
      confirm({
        title: "Are you sure you want to deactivate this user?",
        icon: <ExclamationCircleOutlined />,
        content: `All sessions will be closed for this user`,
        onOk() { activeUserAPI() },
        onCancel() { },
      });
    }
    else activeUserAPI()
    function activeUserAPI() {
      let newfreelancers = [...freelancers]
      var newArr = _.map(newfreelancers, function (freelancer) {
        return freelancer._id === userId ? { ...freelancer, is_active: status, } : freelancer;
      })

      userService
        .updateInActiveStatus({ userId, status })
        .then((response) => {
          dispatch(addFreelancers(newArr));
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const handleOnOpenAccount = (data) => {
    accountService
      .oepnBusinessAccount({ userId: data._id })
      .then((response) => {
        window.open(`${Business_URL}/login?token=${response}`, "_blank");
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const handleonPageChange = (url) => {
    navigate(url);
  };

  const columns = createColumns({
    onEdit: handleOpenEditFreelancerForm,
    onDelete: handleDeleteUser,
    onInActive: handleOnInActive,
    onOpenAccount: handleOnOpenAccount,
    onPageChange: handleonPageChange,
  });

  const handleCloseFreelancerForm = () => {
    setCreateFreelancerFormVisibility(false);
  };

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
    getActiveRecords()
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)

    getFreelancersData(
      pagination.current,
      DEFAULT_PAGE_SIZE,
      field,
      order
    );
  }

  return (
    <div className="container">
      <PageHeader
        title="Freelancers"
        statistics={[
          {
            title: "Total Freelancers",
            value: totalFreelancers,
          },
          {
            title: "Total Active Freelancers",
            value: activeData?.freelanceActive,
          },
          {
            title: "Total Inactive Freelancers",
            value: activeData?.freelanceInActive,
          },
        ]}
        actions={[
          {
            title: "+ New Freelancer",
            handler: handleOpenCreateFreelancerForm,
          },
        ]}
      />
      <div className="card">
        <Table
          data={freelancers}
          columns={columns}
          searchProps={{
            placeholder: "Search Freelancers",
          }}
          onPaginationChange={handlePaginationChange}
          onSearch={handleSearch}
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalFreelancers,
          }}
          onRowClick={handleShowUserDetails}
          loading={loading}
          onReload={handleReload}
          onChange={onChange}
        />
      </div>
      <CreateFreelancerForm
        visible={createFreelancerFormVisible}
        onClose={handleCloseFreelancerForm}
        freelancerDetails={selectedFreelancerDetails}
        isEditMode={isEditMode}
        onReload={handleReload}
        sportsList={sportsList}
      />
      <UserDetails
        userDetails={selectedFreelancerDetails}
        visible={freelancerDetailsVisible}
        onClose={handleCloseFreelancerDetails}
      />
    </div>
  );
}

export default Freelancers;
