import moment from "moment";

// Components
import { Payee } from "../../../../../components/molecules";

// Readers
import {
  transactionReader,
  paymentTransactionReader,
  packageReader,
} from "../../../../../readers";

const createColumns = () => {
  return [
    {
      title: "Transaction ID",
      render: (_, paymentTransaction) => {
        const transaction =
          paymentTransactionReader.transaction(paymentTransaction);
        return transactionReader.transactionId(transaction);
      },
    },
    {
      title: "Type",
      render: (_, transaction) => {
        const packageDetails = paymentTransactionReader.transaction(transaction);
        return packageDetails.booking_type;
      },
    },
    {
      title: "Name",
      render: (_, transaction) => {
        const packageDetails = paymentTransactionReader.packageDetails(transaction);
        const eventDetails = paymentTransactionReader.eventDetails(transaction);
        if (packageDetails) {
          return packageReader.packageName(packageDetails);
        } else {
          return eventDetails?.name
        }
      },
    },
    // {
    //   title: "Purchased By",
    //   render: (_, paymentTransaction) => {
    //     const transaction =
    //       paymentTransactionReader.transaction(paymentTransaction);
    //     const userDetails = transactionReader.userDetails(transaction);
    //     const { email, profile_pic, first_name, last_name, username } =
    //       userDetails;
    //     const name = `${username}`;
    //     return <Payee email={email} name={name} profilePicture={profile_pic} />;
    //   },
    // },
    {
      title: "Purchase Date",
      render: (_, paymentTransaction) => {
        const transaction =
          paymentTransactionReader.transaction(paymentTransaction);
        const transactionDate = transactionReader.createdAt(transaction);
        const dateMoment = new moment(transactionDate);
        return dateMoment.format("LLL");
      },
    },
    {
      title: "Sale Amount",
      render: (_, paymentTransaction) => {
        const transaction =
          paymentTransactionReader.transaction(paymentTransaction);
        return transactionReader.sellingPrice(transaction);
      },
    },
    {
      title: "Dudi Commission",
      render: (_, paymentTransaction) => {
        const transaction =
          paymentTransactionReader.transaction(paymentTransaction);
        return transactionReader.dudiCommission(transaction);
      },
    },
    {
      title: "Net Amount",
      render: (_, paymentTransaction) => {
        const transaction =
          paymentTransactionReader.transaction(paymentTransaction);
        return transactionReader.netAmount(transaction);
      },
    },
  ];
};

export { createColumns };
