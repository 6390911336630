import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from 'antd';

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { useForm } from "antd/lib/form/Form";
import Form from "../../../../components/atoms/form";
import Drawer from "../../../../components/atoms/drawer";
import Button from "../../../../components/atoms/button";
import { Input, InputNumber } from "../../../../components/atoms/input";
import AsyncSelect from "../../../../components/molecules/asyncSelect/AsyncSelect";
import { PickerWithType } from "../../../../components/atoms/datePicker/DatePicker";
import GooglePlacesAutoComplete from "../../../../components/molecules/googlePlacesAutoComplete";

// Services
import businessAccountService from "../../../../services/businessAccountsService";
import freelancerService from "../../../../services/freelancerService";

// Readers
import { businessAccountReader } from "../../../../readers";

// Styles
import styles from "./createVenueForm.module.scss";
import { EMPTY_OBJECT } from "../../../../constants";

const renderSportsOptions = (option) => {
  return (
    <Select.Option key={`${option._id}@${option.name}`} value={`${option._id}@${option.name}`}>
      {option.name}
    </Select.Option>
  );
};

const renderUserOptions = (option) => {
  return (
    <Select.Option key={option.value} value={option.value} >
      {option.label}
    </Select.Option>
  );
};


const handleCreateBusinessUserOptions = (businessUsers) =>
  _map(businessUsers, (businessUser) => ({
    label: `${businessAccountReader.firstName(businessUser)} ${businessAccountReader.lastName(businessUser)}`,
    value: businessAccountReader.businessUserId(businessUser),
    businessUser,
  }));

function CreateVenueForm({
  formValues,
  visible,
  onClose,
  title,
  sportsList,
  onFinish,
  usersList,
  loading,
  is_freelancer_venue
}) {
  const [form] = useForm();
  const [isFreelancer, setFreelancer] = useState(false);

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [form, formValues]);

  // useEffect(() => {
  //   setFreelancer(formValues.is_freelancer_venue);
  // }, [form, formValues]);

  const defaultOptions = handleCreateBusinessUserOptions(usersList);

  return (
    <Drawer title={title} open={visible} onClose={onClose} width={400}>
      <div className={styles.container}>
        <Form
          form={form}
          initialValues={formValues}
          scrollToFirstError
          onFinish={(values) => onFinish(values, form)}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          {/* <Form.Item
            name="business_id"
            label="Business User"
            rules={[
              {
                required: true,
                message: "Please select user!",
              },
            ]}
          >
            <AsyncSelect
              asyncService={businessAccountService.fetchBusinessAccount}
              className={styles.select}
              defaultOptions={defaultOptions}
              createOptions={handleCreateBusinessUserOptions}
            ></AsyncSelect>
          </Form.Item>  */}

          <Form.Item
            name="business_id"
            label="Business User"
            rules={[
              { required: true, message: "Please select user!" },
            ]}
          >
            <Select
              // mode="multiple"
              placeholder="Select venue user"
              className={styles.select}
              options={defaultOptions}
              value={formValues.bussiness_id}
              defaultValue={defaultOptions[0]}
              disabled={isFreelancer}
            >
              {_map(defaultOptions, renderUserOptions)}
            </Select>
          </Form.Item>
          {/* {displaySelection} */}

          <Form.Item label="Address" name={["location"]}>
            <GooglePlacesAutoComplete
              lat={formValues?.latitude}
              lng={formValues?.longitude}
              place={formValues?.address}
            ></GooglePlacesAutoComplete>
          </Form.Item>
          <Form.Item name="average_cost" label="Average Cost">
            <InputNumber
              placeholder="Enter average cost"
              style={{
                width: "100%",
              }}
              min={0}
              className={styles.inputNumber}
            />
          </Form.Item>
          <Form.Item name="opening_time" label="Opening Time">
            <PickerWithType type={"time"} use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item name="closing_time" label="Closing Time">
            <PickerWithType type={"time"} use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item name="players_occupy" label="Players Occupancy">
            <Input placeholder="Enter Player Occupancy" />
          </Form.Item>
          <Form.Item
            name="sports_played"
            label="Sports Played"
            rules={[
              { required: true, message: "Please select sports played!" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOptions)}
            </Select>
          </Form.Item>
          <Form.Item name="max_pitches" label="Max Number of Pitches" rules={[{ required: true, message: "Please input name!" }]}>
            <Input placeholder="Enter Max Pitches" type="number" min={0} />
          </Form.Item>
          <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Select status" className={styles.select}>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>InActive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              label={"Save"}
              type="primary"
              htmlType="submit"
              className={styles.saveButton}
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateVenueForm.propTypes = {
  formValues: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sportsList: PropTypes.array,
  onFinish: PropTypes.func,
};

CreateVenueForm.defaultProps = {
  formValues: EMPTY_OBJECT,
  visible: false,
  onClose: _noop,
};

export default CreateVenueForm;
