import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import VenueFormWrapper from "./components/venueFormWrapper";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";
import VenueDetails from "./components/venueDetails/VenueDetails";

// Services
import venuesService from "../../services/venuesService";
import sportsService from "../../services/sportsService";
import businessAccountService from "../../services/businessAccountsService";
import freelancerService from "../../services/freelancerService";


// Redux
import { addVenues } from "./redux/venuesSlice";
import { addAllSports } from "../sports/redux/sportsSlice";
import { addUsers } from "../users/redux/usersSlice";
import { addFreelancers } from "../freelancers/redux/freelancersSlice";


// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./venues.module.scss";

function Venues() {
  const [users, setUsers] = useState([])
  const [totalFreelancers, setTotalFreelancers] = useState(0);
  const [totalVenues, setTotalVenues] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [selectedVenueDetails, setSelectedVenueDetails] =
    useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [venueDetailsVisible, setVenueDetailsVisibility] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [createVenueFormVisible, setCreateVenueFormVisibility] =
    useState(false);
  const dispatch = useDispatch();
  const venues = useSelector((state) => state?.venues?.items);
  const sportsList = useSelector((state) => state?.sports?.all);
  const usersList = useSelector((state) => state?.users?.items);
  const freelancers = useSelector((state) => state?.freelancers?.items);
  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    setLoading(true);
    venuesService
      .fetchVenues({
        pageNumber,
        pageSize,
        searchText,
      })
      .then((response) => {
        const venues = listResponseReader.results(response);
        const totalVenues = listResponseReader.totalResults(response);
        setTotalVenues(totalVenues);
        dispatch(addVenues(venues));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addVenues(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationDetails]);

  useEffect(() => {
    if (sportsList.length === 0)
      sportsService
        .fetchAllSports()
        .then((response) => {
          dispatch(addAllSports(response));
        })
  }, []);

  useEffect(() => {
    businessAccountService
      .fetchBusinessAccount({
        pageNumber: 1,
        pageSize: 1000,
      })
      .then((response) => {
        const userList = listResponseReader.results(response);
        dispatch(addUsers(userList));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addUsers([]));
      });
  }, []);

  //getting freelancers
  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    freelancerService
      .fetchFreelancers({
        pageNumber,
        pageSize,
        searchText,
        sortField: 'email',
        order: 'asc',
      })
      .then((response) => {
        const freelancers = listResponseReader.results(response);
        const totalFreelancers = listResponseReader.totalResults(response);
        setTotalFreelancers(totalFreelancers);
        dispatch(addFreelancers(freelancers));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addFreelancers(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  const handleOpenCreateVenueForm = () => {
    setUsers(usersList);
    setEditMode(false);
    setSelectedVenueDetails(EMPTY_OBJECT);
    setCreateVenueFormVisibility(true);
  };

  const handleOpenEditVenueForm = (venueDetail) => {
    if (venueDetail && venueDetail.is_freelancer_venue) {
      const { business_id } = venueDetail;
      if (business_id && business_id.user_id) {
        const venueFreelancer = freelancers.filter(freelancer => freelancer.id === business_id.user_id._id);
        if (venueFreelancer && venueFreelancer.length > 0) {
          setUsers(venueFreelancer);
        }
      }
    }
    setEditMode(true);
    setSelectedVenueDetails(venueDetail);
    setCreateVenueFormVisibility(true);
  };

  const handleCloseCreateVenueForm = () => {
    setUsers(usersList);
    setCreateVenueFormVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleDeleteVenue = (venue) => {
    console.log("Deleting sport", venue);
  };

  const columns = createColumns({
    onEdit: handleOpenEditVenueForm,
    onDelete: handleDeleteVenue,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  const handleShowVenueDetails = (subAdminDetails) => {
    setSelectedVenueDetails(subAdminDetails);
    setVenueDetailsVisibility(true);
  };

  const handleCloseVenueDetails = () => {
    editMode && setEditMode(false);
    setVenueDetailsVisibility(false);
    setSelectedVenueDetails(EMPTY_OBJECT);
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Venues"
          statistics={[
            {
              title: "Total Venues",
              value: totalVenues,
            },
          ]}
          actions={[
            {
              title: "+ New Venue",
              handler: handleOpenCreateVenueForm,
            },
          ]}
        />
        <div className={styles.pageContent}>
          <Table
            data={venues}
            columns={columns}
            searchProps={{
              placeholder: "Search Venues",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalVenues,
            }}
            loading={loading}
            onReload={handleReload}
            onRowClick={handleShowVenueDetails}
          />
        </div>
      </div>
      <VenueFormWrapper
        editMode={editMode}
        visible={createVenueFormVisible}
        onClose={handleCloseCreateVenueForm}
        venueDetails={selectedVenueDetails}
        sportsList={sportsList}
        usersList={usersList}
        freelancers={users}
        onReload={handleReload}
      />
      <VenueDetails
        subAdminDetails={selectedVenueDetails}
        visible={venueDetailsVisible}
        onClose={handleCloseVenueDetails}
        venueDetails={selectedVenueDetails}
      />
    </>
  );
}

export default Venues;
