// Lodash
import _property from "lodash/property";

const createdAt = _property("createdAt");
const firstName = _property("first_name");
const lastName = _property("last_name");
const id = _property("id");
const isActive = _property("is_active");
const email = _property("email");
const updatedAt = _property("updatedAt");
const role = _property("role.role");
const roleId = _property("role._id");
const profileImageUrl = _property("profile_pic");

export default {
  createdAt,
  firstName,
  lastName,
  id,
  isActive,
  email,
  updatedAt,
  role,
  roleId,
  profileImageUrl,
};
