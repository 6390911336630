import moment from "moment";

// Components
import { Payee } from "../../../components/molecules";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from "../../../components/atoms/icon";
import DefaultUserImage from "../../../assets/images/user.png";
import Image from "../../../components/atoms/image";
import { Switch } from 'antd';

// Readers
import userReader from "../../../readers/user";

// Styles
import styles from "../users.module.scss";

const createColumns = ({ onEdit, onDelete, onInActive, onPageChange }) => {

  return [
    {
      title: "User Detail",
      dataIndex: 'firstName',
      sorter: true,
      render: (text, userDetails) => {
        const firstName = userDetails.firstName;
        const lastName = userDetails?.lastName || "";
        const email = userDetails.email;
        const profilePictureUrl = userDetails.profile_pic;
        return (
          <Payee email={email} name={firstName + " " + lastName} profilePicture={profilePictureUrl} />
        );
      },
    },
    {
      title: "Username",
      dataIndex: 'username',
      sorter: true,
      render: (text, userDetails) => {
        return userReader.userName(userDetails);
      },
    },
    {
      title: "Posts",
      dataIndex: 'postsCount',
      sorter: true,
      render: (text, userDetails) => {
        const count = userReader.postsCount(userDetails)
        const id = userReader.id(userDetails)
        return (
          <Button
            disabled={count ? false : true}
            type={BUTTON_TYPES.DEFAULT}
            onClick={(event) => {
              event.stopPropagation();
              onPageChange(`/posts/${id}`);
            }}
          >
            {count ? count : 0}
          </Button>
        );
      },
    },
    {
      title: "Events Create",
      dataIndex: 'eventsCount',
      sorter: true,
      render: (text, userDetails) => {
        const count = userReader.eventsCount(userDetails)
        const id = userReader.id(userDetails)
        return (
          <Button
            disabled={count ? false : true}
            type={BUTTON_TYPES.DEFAULT}
            onClick={(event) => {
              event.stopPropagation();
              // onPageChange(`/events/${id}?type='created'`);
            }}
          >
            {count ? count : 0}
          </Button>
        );
      },
    },
    {
      title: "Events Joined",
      dataIndex: 'eventsJoinCount',
      sorter: true,
      render: (text, userDetails) => {
        const count = userReader.eventsJoinCount(userDetails)
        const id = userReader.id(userDetails)
        return (
          <Button
            disabled={count ? false : true}
            type={BUTTON_TYPES.DEFAULT}
            onClick={(event) => {
              event.stopPropagation();
              // onPageChange(`/events/${id}?type='joined'`);
            }}
          >
            {count ? count : 0}
          </Button>
        );
      },
    },
    {
      title: "Last Use",
      sorter: true,
      dataIndex: 'lastOpen',
      render: (text, userDetails) => {
        const lastOpen = userReader.lastOpen(userDetails)
        return lastOpen ? moment(lastOpen).fromNow() : null;
      },
    },
    {
      title: "Status",
      render: (text, userDetails) => {
        const status = userReader.isActive(userDetails)
        const user_id = userReader.id(userDetails)
        const isDelete = userReader.isDelete(userDetails)
        return <Switch
          disabled={isDelete}
          checked={status}
          onClick={(bolean, event) => {
            event.stopPropagation();
            onInActive(user_id, !status);
          }}
        />
      },
    },
    {
      title: "Verified",
      dataIndex: 'email_verified',
      sorter: true,
      render: (text, userDetails) => {
        const emailVerified = userReader.emailVerified(userDetails)
        if (emailVerified)
          return <CheckOutlined style={{ fontSize: '16px', color: 'green' }} />
        else
          return <CloseOutlined style={{ fontSize: '16px', color: 'red' }} />
      },
    },
    {
      title: "Actions",
      render: (text, userDetails) => {
        const emailVerified = userReader.emailVerified(userDetails)
        return (
          <>
            <Button
              disabled
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(userDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            {!emailVerified &&
              <Button
                type={BUTTON_TYPES.LINK}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(userDetails);
                }}
                className={`${styles.icon} ${styles.iconDanger}`}
              >
                <DeleteOutlined />
              </Button>}
          </>
        );
      },
    },
  ];
};

export { createColumns };
