import PropTypes from "prop-types";

// Lodash
import _isEmpty from "lodash/isEmpty";

// Components
import { Avatar as AntdAvatar } from "antd";

import defaultAvatar from "../../../assets/images/user.png";

const Avatar = (props) => {
  const { src, children, ...restProps } = props;

  if (!_isEmpty(src)) {
    return (
      <AntdAvatar src={src} size={34} {...restProps}>
        {children}
      </AntdAvatar>
    );
  }

  return (
    <AntdAvatar src={defaultAvatar} size={34} {...restProps}>
      {children}
    </AntdAvatar>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
};

Avatar.defaultProps = {
  src: "",
};

export default Avatar;
